export const Required = () => {
  return (
    <span
      style={{
        background: 'red',
        color: 'rgb(255, 255, 255)',
        fontSize: '12px',
        fontWeight: 'normal',
        padding: '2px 5px',
        borderRadius: '5px',
        margin: '0 0 0 10px',
        position: 'relative',
        top: '-2px',
      }}
    >
      必須
    </span>
  );
};
