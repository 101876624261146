import React, { useState } from 'react';
// コンポーネント
import { SubTitle } from 'components/uiParts/SubTitle';
import { CreateStockForm } from 'components/uiParts/Form/CreateStockForm';
import { Breadcrumb } from 'components/uiParts/Breadcrumb';
// 定数ファイル
import { STOCK_TABLE_COLUMNS } from 'utils/constantsTable';
// MUI
import Button from '@mui/material/Button';

export const StockCreatePage = () => {
  const breadcrumbList = [
    { name: 'ダッシュボード', href: '/' },
    { name: '在庫一覧', href: '/stock/list' },
    { name: '在庫登録' },
  ];
  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SubTitle title='在庫登録' />
        <Button href={location.origin + '/stock/list'} variant='contained' color='inherit'>
          戻る
        </Button>
      </div>
      <CreateStockForm
        api='Stock'
        columns={STOCK_TABLE_COLUMNS}
        input={{
          film_products_master_id: '',
          length: 0,
          length_ph: 0,
          length_lo: 0,
          status: '原反(新品)',
          stocking_date: '',
          cost_price: 0,
          remarks: '',
        }}
        redirectUrl='/stock/list'
      />
    </>
  );
};
