import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// MUI
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
// コンポーネント
import { SubTitle } from 'components/uiParts/SubTitle';
import { BorderTop } from 'components/uiParts/BorderTop';
import { Required } from 'components/uiParts/Required';
// カスタムフック
import { useCreateClientForm } from 'hooks/useCreateClientForm';
import { useGetSalesCategory } from 'hooks/useGetSalesCategory';
import { useGetProcessingPriceCategory } from 'hooks/useGetProcessingPriceCategory';
import {
  useGetCustomerCategory1,
  useGetCustomerCategory2,
  useGetCustomerCategory3,
} from 'hooks/useGetCustomerCategory';

export const CreateClientForm = (props) => {
  const { columns } = props;

  // カスタムフック
  const {
    formInputValue,
    addLoadingForm,
    isFormValid,
    handleClickCreateForm,
    handleChangeCreateForm,
  } = useCreateClientForm(columns);

  const { salesCategoryList } = useGetSalesCategory();
  const { pPCategory } = useGetProcessingPriceCategory();
  const { customerCategoryList1 } = useGetCustomerCategory1();
  const { customerCategoryList2 } = useGetCustomerCategory2();
  const { customerCategoryList3 } = useGetCustomerCategory3();

  const [typing,setTyping] = useState(false);

  return (
    <>
      <div style={{ margin: '30px 0 0' }}>
        <SubTitle title='・お客様情報' hideBorder={true} />
        {columns.map(
          (column, index) =>
            column.create && (
              <div key={index} style={{ margin: '20px 0 0' }}>
                <div style={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
                  {column.headerName}
                  {column.reauired && <Required />}
                </div>
                {column.formType === 'select' ? (
                  <>
                    {(column.field === 'title_of_honor' || column.field === 'usage_category') &&
                      column.valueOptions &&
                      column.valueOptions.length > 0 && (
                        <TextField
                          name={column.field}
                          onChange={(e) =>
                            handleChangeCreateForm(
                              e,
                              column.formType === 'number' ? 'number' : 'text'
                            )
                          }
                          select
                          defaultValue={column.defaultValueOption ? column.defaultValueOption : ''}
                          size='small'
                          sx={{ margin: '10px 0 0', width: 400 }}
                          required
                        >
                          <MenuItem hidden>選択してください</MenuItem>
                          {column.valueOptions.map((data, index) => (
                            <MenuItem key={index} value={data}>
                              {data}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    {column.field === 'film_sales_price_categories_master_id' && (
                      <TextField
                        name={column.field}
                        onChange={(e) =>
                          handleChangeCreateForm(
                            e,
                            column.formType === 'number' ? 'number' : 'text'
                          )
                        }
                        select
                        defaultValue={1}
                        size='small'
                        sx={{ margin: '10px 0 0', width: 400 }}
                        required
                      >
                        <MenuItem value='defalut' hidden>
                          選択してください
                        </MenuItem>
                        {salesCategoryList.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    {column.field === 'film_processing_price_categories_master_id' && (
                      <TextField
                        name={column.field}
                        onChange={(e) =>
                          handleChangeCreateForm(
                            e,
                            column.formType === 'number' ? 'number' : 'text'
                          )
                        }
                        select
                        defaultValue={1}
                        size='small'
                        sx={{ margin: '10px 0 0', width: 400 }}
                      >
                        <MenuItem value='defalut' hidden>
                          選択してください
                        </MenuItem>
                        {pPCategory.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    {column.field === 'film_customer_categories_1_master_id' && (
                      <TextField
                        name={column.field}
                        onChange={(e) =>
                          handleChangeCreateForm(
                            e,
                            column.formType === 'number' ? 'number' : 'text'
                          )
                        }
                        select
                        defaultValue='defalut'
                        size='small'
                        sx={{ margin: '10px 0 0', width: 400 }}
                        required
                      >
                        <MenuItem value='defalut' hidden>
                          選択してください
                        </MenuItem>
                        {customerCategoryList1.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    {column.field === 'film_customer_categories_2_master_id' && (
                      <TextField
                        name={column.field}
                        onChange={(e) =>
                          handleChangeCreateForm(
                            e,
                            column.formType === 'number' ? 'number' : 'text'
                          )
                        }
                        select
                        defaultValue='defalut'
                        size='small'
                        sx={{ margin: '10px 0 0', width: 400 }}
                        required
                      >
                        <MenuItem value='defalut' hidden>
                          選択してください
                        </MenuItem>
                        {customerCategoryList2.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    {column.field === 'film_customer_categories_3_master_id' && (
                      <TextField
                        name={column.field}
                        onChange={(e) =>
                          handleChangeCreateForm(
                            e,
                            column.formType === 'number' ? 'number' : 'text'
                          )
                        }
                        select
                        defaultValue='defalut'
                        size='small'
                        sx={{ margin: '10px 0 0', width: 400 }}
                        required
                      >
                        <MenuItem value='defalut' hidden>
                          選択してください
                        </MenuItem>
                        {customerCategoryList3.map((data, index) => (
                          <MenuItem key={index} value={data.id}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </>
                ) : (
                  <TextField
                    name={column.field}
                    onCompositionStart={() => setTyping(true)}
                    onCompositionEnd={(e) => {
                      setTyping(false)
                      handleChangeCreateForm(e, column.formType === 'number' ? 'number' : 'text', false)
                    }}
                    onChange={(e) =>
                      handleChangeCreateForm(e, column.formType === 'number' ? 'number' : 'text', typing)
                    }
                    value={formInputValue[column.field] ? formInputValue[column.field] : ''}
                    sx={{ margin: '10px 0 0', width: 400 }}
                    size='small'
                  />
                )}
              </div>
            )
        )}
        <BorderTop />
        <div style={{ textAlign: 'right' }}>
          <LoadingButton
            disabled={isFormValid}
            onClick={handleClickCreateForm}
            variant='contained'
            loading={addLoadingForm}
          >
            顧客を登録する
          </LoadingButton>
        </div>
      </div>
    </>
  );
};

CreateClientForm.propTypes = {
  columns: PropTypes.array.isRequired, // カラム
};
