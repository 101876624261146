import React, { useState } from 'react';
import { DataTable } from 'components/uiParts/DataGrid/DataTable';
import { ORDER_TABLE_COLUMNS } from 'utils/constantsTable';
import { Breadcrumb } from 'components/uiParts/Breadcrumb';

export const OrderListPage = () => {
  const [api, setApi] = useState('OrderExcludeShippedForUser');

  /**
   * 出荷済みを含むにチェックを入れたらAPIを切り替える
   */
  const handleClickShipped = () => {
    let apiName = 'OrderForUser';
    if (api === 'OrderForUser') {
      apiName = 'OrderExcludeShippedForUser';
    }
    setApi(apiName);
  };

  const breadcrumbList = [
    { name: 'ダッシュボード', href: '/' },
    { name: '受注一覧', href: '/order/list' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <DataTable
        title='受注一覧'
        columns={ORDER_TABLE_COLUMNS}
        table='Order'
        deleteTableList={['Order', 'SubOrder']}
        deleteTablesGetProperty='film_orders_master_id'
        api={api}
        handleClickShipped={handleClickShipped}
        isCreateBtn
        isDetailBtn
        isDeleteTables
        isDeleteBtn
        createUrl='/order/create'
        detailUrl='/order/detail'
        defaultFilterColumn='ClientName'
        defaultFilterOperator='contains'
      />
    </>
  );
};
