import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
// MUI
import { Button, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DoneIcon from '@mui/icons-material/Done';
import DownloadIcon from '@mui/icons-material/Download';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// コンポーネント
import { GridCellExpand } from 'components/uiParts/DataGrid/GridCellExpand';
import { SubTitle } from 'components/uiParts/SubTitle';
import { DeleteForm } from 'components/uiParts/Form/DeleteForm';
import { CustomDataGrid } from 'components/uiParts/DataGrid/CustomDataGrid';
import { SelectStockModal } from 'components/uiParts/Modal/SelectStockModal';
import { ShipSlipOutFormModal } from 'components/uiParts/Modal/ShipSlipOutFormModal';
import { ProcessingSheetModal } from 'components/uiParts/Modal/ProcessingSheetModal';
// カスタムフック
import { useDataTable } from 'hooks/useDataTable';
import { useDeleteForm } from 'hooks/useForm';
import { useCommonFunction } from 'hooks/useCommonFunction';
import { useShipSlipOutput } from 'hooks/useShipSlipOutput';
import { useProcessingSheet } from 'hooks/useProcessingSheet';

// URLを取得
const url = new URL(window.location.href);

/**
 * セルの中身が多い場合に、ホバーすると表示されるテキストコンポーネントを呼ぶ関数
 * @see https://github.com/mui/mui-x/issues/417#issuecomment-782772202
 * @see https://codesandbox.io/s/bjupl?file=/demo.js:2836-2842
 * @return  セルの中身を表示
 */
function renderCellExpand(params) {
  if (params.value === undefined || params.value === null) return;
  return <GridCellExpand params={params} width={params.colDef.computedWidth} />;
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string.isRequired,
};

/**
 * 特定のカラムでテーブルの絞り込み
 * @param {Array} datas APIで取得したデータ
 * @param {String} serachColumn 絞り込みたいカラム名（特定のデータを絞り込みたい時に使用する）
 * @param {String} selectedColumn APIで取得したデータ（特定のデータを絞り込みたい時に使用する）
 * @returns グラフで使用する配列
 */
export const selectedTableData = (datas, serachColumn, selectedColumn) => {
  const datas2 = [];
  for (let i = 0; i < datas.length; i++) {
    if (datas[i][serachColumn] === selectedColumn) {
      datas2.push(datas[i]);
    }
  }
  return datas2;
};

export const DataTable = (props) => {
  const {
    title,
    columns,
    table,
    deleteTableList,
    deleteTablesGetProperty,
    api,
    params,
    checkboxSelection,
    isCreateBtn,
    isDetailBtn,
    isDeleteBtn,
    isDeleteTables,
    isDecisionBtn,
    isQRBtn,
    createUrl,
    detailUrl,
    testRows,
    isStockBtn,
    autoHeight,
    vh = '80vh',
    handleClickChangeUCStatus,
    handleClickDecision,
    columnVisibilityList,
    handleClickShipped,
    // handleClickAllStock,
    isButton = true,
    isAllStockBtn,
    subOrderData,
    isReacquireData,
    getRows,
    isFilteringStockRows,
    selectedSubOrderLength,
    isShipList,
    isMIOutputBtn,
    isPOutputBtn,
    handleClickStartProcessingBtn,
    handleClickProcessingSheet,
    openProcessingSheet,
    setOpenProcessingSheet,
    printProcessingSheet,
    isSelectStocksBtn,
    handleClickOpenSelectStock,
    handleClickOpenSelectStocks,
    setSelectedRows,
    isPageReload,
    selectedStockIds,
    defaultColumnVisibilityList,
    setSubOrderGRows,
    localStorageKeyName,
    handleClickPrintShipSheet,
    isClickOpenSelectStocks,
    defaultFilterColumn,
    defaultFilterOperator,
  } = props;

  // カスタムフック
  const {
    rows,
    newRows,
    searchText,
    searchSecondText,
    searchThirdText,
    selectedRowIds,
    isLoading,
    initialRows,
    selectedRows,
    setNewRows,
    requestSearch,
    requestSecondSearch,
    requestThirdSearch,
    handleClickSelectedRows,
    reacquireData,
  } = useDataTable(api, params, isReacquireData);

  const {
    openDeleteForm,
    deleteIds,
    addLoadingDeleteForm,
    isFetchLoading,
    handleClickOpenDeleteForm,
    handleClickOpenDeleteTablesForm,
    handleCloseDeleteForm,
    deleteRows,
    deleteTables,
    loadinDelete,
  } = useDeleteForm(api, params);

  const {
    openShipSlipOutModal,
    setOpenShipSlipOutModal,
    pdfDataList,
    setPdfDataList,
    handleClickShipSlipOutput,
  } = useShipSlipOutput(selectedRowIds, newRows);

  const { processingPDFDataList, setProcessingPDFDataList } = useProcessingSheet(
    selectedRowIds,
    newRows
  );

  const { filteringOrderList } = useCommonFunction();

  const [dataColumns, setDataColumns] = useState([]);
  const isCheckedAllStock = useRef(false);
  const isFirstTime = useRef(false);
  const [csvData, setCsvData] = useState([]);
  const [isSelectStocksBtnDisabled, setIsSelectStocksBtnDisabled] = useState(true);
  const isEditingCell = useRef(false);

  // columnに、rederCellExpandを追加する事で、セルの折り返し内容をホバーした時に表示させる
  useEffect(() => {
    columns.map((column) => {
      if (column['renderCell'] === undefined) {
        column['renderCell'] = renderCellExpand;
      }
    });
  }, [columns]);

  useEffect(() => {
    const dataColumns = [...columns];

    // 詳細ボタン追加
    isDetailBtn &&
      dataColumns.unshift(
        // 詳細ボタン
        {
          field: 'detailBtn',
          headerName: '詳細',
          sortable: false,
          width: 90,
          disableClickEventBubbling: true,
          // eslint-disable-next-line react/display-name
          renderCell: (params) => (
            <IconButton
              style={{ margin: 'auto' }}
              size='small'
              onClick={() => {}}
              variant='primary'
            >
              <Link to={{ pathname: detailUrl + '?id=' + params.id }}>
                <MoreHorizIcon />
              </Link>
            </IconButton>
          ),
          create: false,
          update: false,
        }
      );

    // 削除ボタン追加
    isDeleteBtn &&
      dataColumns.push(
        // 削除ボタン
        {
          field: 'deleteBtn',
          headerName: '削除', // 削除
          sortable: false,
          width: 90,
          disableClickEventBubbling: true,
          // eslint-disable-next-line react/display-name
          renderCell: (params) => (
            <>
              {isDeleteTables ? ( // 複数テーブル(現状は2つまで可)削除用
                <IconButton
                  onClick={() =>
                    handleClickOpenDeleteTablesForm(
                      params.id,
                      deleteTableList,
                      deleteTablesGetProperty
                    )
                  }
                  style={{ margin: 'auto' }}
                  aria-label='delete'
                  color='primary'
                  disabled={params.row.NotDeleteBtn}
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => handleClickOpenDeleteForm(params.id)}
                  style={{ margin: 'auto' }}
                  aria-label='delete'
                  color='primary'
                  disabled={params.row.NotDeleteBtn}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </>
          ),
          create: false,
          update: false,
        }
      );

    // 決定ボタン追加 一番最初に追加
    isDecisionBtn &&
      dataColumns.unshift({
        field: 'decisionBtn',
        headerName: '決定',
        sortable: false,
        width: 90,
        disableClickEventBubbling: true,
        // eslint-disable-next-line react/display-name
        renderCell: (params) => (
          <IconButton
            onClick={() => handleClickDecision(params)}
            style={{ margin: 'auto' }}
            color='primary'
            disabled={
              (selectedStockIds && selectedStockIds.includes(params.id)) || isEditingCell.current
            }
          >
            <DoneIcon />
          </IconButton>
        ),
        create: false,
        update: false,
      });

    // 在庫選択追加 一番最初に追加
    isStockBtn &&
      dataColumns.unshift({
        field: 'stockBtn',
        headerName: '在庫選択', // 削除
        sortable: false,
        width: 90,
        disableClickEventBubbling: true,
        // eslint-disable-next-line react/display-name
        renderCell: (params) => (
          <IconButton
            onClick={() => handleClickOpenSelectStock(params.row.id, params)}
            style={{ margin: 'auto' }}
            aria-label='delete'
            color='primary'
          >
            <MoreHorizIcon />
          </IconButton>
        ),
      });

    setDataColumns(dataColumns);
  }, []);

  /**
   * 絞り込み(フィルタリング)
   * 厚み(thickness): 完全一致のみ
   * 幅(Width): 注文以上の数値
   * 「全在庫」チェックボックスを押すと、フィルタリング解除
   * @param {Boolean} isFiltering フィルタリングを行うか
   * @param {Number} thickness 厚み
   * @param {Number} width 幅
   * @param {Number} length 長さ 何もなければ0とする
   */
  const filteringStockRows = (isFiltering, thickness, width, length = 0) => {
    if (isFiltering) {
      const copyNewRows = JSON.parse(JSON.stringify(newRows));
      const filteringRows = [];
      let pushIndex = 0;
      for (let i = 0; i < copyNewRows.length; i++) {
        if (
          copyNewRows[i].thickness === thickness &&
          copyNewRows[i].width >= width &&
          copyNewRows[i].length_lo >= length
        ) {
          if (copyNewRows[i].ProductName === subOrderData.row.processing_product_name) {
            pushIndex++;
            filteringRows.splice(pushIndex, 0, copyNewRows[i]);
          } else {
            filteringRows.push(copyNewRows[i]);
          }
        }
      }
      setNewRows(filteringRows);
    } else {
      setNewRows(initialRows);
    }
  };

  /**
   * 全在庫チェックボックスを押すしたときの処理
   */
  const handleClickAllStock = () => {
    if (isCheckedAllStock.current) {
      filteringStockRows(true, subOrderData.row.order_thickness, subOrderData.row.order_width);
      isCheckedAllStock.current = false;
    } else {
      filteringStockRows(false);
      isCheckedAllStock.current = true;
    }
  };

  /**
   * 在庫のフィルタリングを最初だけ行う
   */
  useEffect(() => {
    if (!isFilteringStockRows) return;
    if (!newRows.length) return;
    if (!Object.keys(subOrderData).length) return;
    if (isFirstTime.current) return;

    // 一括在庫選択の場合は、注文長さ以上に絞り込むため長さを第四引数に入れる
    if (isClickOpenSelectStocks) {
      if (selectedSubOrderLength !== undefined) {
        filteringStockRows(
          true,
          subOrderData.row.order_thickness,
          subOrderData.row.order_width,
          selectedSubOrderLength
        );
      } else {
        filteringStockRows(true, subOrderData.row.order_thickness, subOrderData.row.order_width);
      }
    } else {
      filteringStockRows(true, subOrderData.row.order_thickness, subOrderData.row.order_width);
    }

    isFirstTime.current = true;
  }, [newRows, subOrderData]);

  /**
   * データを取得する
   */
  useEffect(() => {
    if (!getRows) return;
    if (!newRows.length) return;
    getRows(newRows);
  }, [newRows, getRows]);

  // CSVダウンロードする配列を作成する
  // カラム：URL, 在庫ID:商品名 厚み 幅、ヘッダーは無し
  useEffect(() => {
    if (!selectedRows.length) return;
    if (!isQRBtn) return;
    const csvStockData = [];
    for (let i = 0; i < selectedRows.length; i++) {
      // 在庫ID:商品名 厚み 幅 の文字列を作成する
      let stockText = selectedRows[i].id + ':';
      stockText +=
        selectedRows[i].ProductName !== undefined ? selectedRows[i].ProductName + ' ' : '';
      stockText += selectedRows[i].thickness !== undefined ? selectedRows[i].thickness + ' ' : '';
      stockText += selectedRows[i].width !== undefined ? selectedRows[i].width : '';

      csvStockData.push([url.origin + '/stock/detail?id=' + selectedRows[i].id, stockText]);
    }
    setCsvData(csvStockData);
  }, [selectedRows]);

  // ダッシュボード：出荷一覧用にフィルタリングする
  useEffect(() => {
    if (!isShipList) return;
    if (!rows.length) return;
    const copyRows = JSON.parse(JSON.stringify(rows)); // コピー
    const splitRows = {};
    let sortRow = [];
    let sortGrRow = {};

    // 注文のSubOrderGIdを使い、受注IDごとにオブジェクト内で分け、更に各注文ごとに分ける {受注ID: {1: [rows]}, 受注ID: {2: [rows]}}
    for (let i = 0; i < copyRows.length; i++) {
      if (copyRows[i].film_orders_master_id !== undefined && copyRows[i].sub_order_group_id !== undefined) {
        let subOrderGIdAry = copyRows[i].sub_order_group_id.split('_');

        if (splitRows[subOrderGIdAry[0]] === undefined) {
          // 受注のプロパティがなければ追加
          splitRows[subOrderGIdAry[0]] = { [subOrderGIdAry[1]]: [copyRows[i]] };
        } else if (splitRows[subOrderGIdAry[0]][subOrderGIdAry[1]] === undefined) {
          // 注文回数のプロパティがなければ追加
          splitRows[subOrderGIdAry[0]][subOrderGIdAry[1]] = [copyRows[i]];
        } else {
          // 注文回数のプロパティがあれば既存の配列とマージする
          splitRows[subOrderGIdAry[0]][subOrderGIdAry[1]] = [
            ...splitRows[subOrderGIdAry[0]][subOrderGIdAry[1]],
            ...[copyRows[i]],
          ];
        }
      }
    }

    // 加工済だけの注文にしぼり、filteringOrderListを使って注文をまとめる
    for (const key in splitRows) {
      for (const key2 in splitRows[key]) {
        // 加工済以外のステータスがあるか探す
        const idx = splitRows[key][key2].findIndex((d) => d.status !== '加工済'); // 該当データのindexを取得
        // 加工済み以外のステータスがあればループを飛ばす
        if (idx >= 0) {
          continue;
        }

        sortRow = [...sortRow, ...filteringOrderList(splitRows[key][key2])[0]];
        sortGrRow = { ...sortGrRow, ...filteringOrderList(splitRows[key][key2])[1] };
      }
    }

    setSubOrderGRows(sortGrRow); // 注文更新用に束ねている注文をまとめる
    setNewRows(sortRow); // データを置き換える
  }, [rows]);

  // 選択中の行のデータをまとめる
  useEffect(() => {
    if (!setSelectedRows) return;
    if (!selectedRowIds.length) return;

    const list = [];
    for (let i = 0; i < selectedRowIds.length; i++) {
      const copyNewValueIdx = newRows.findIndex((d) => d.id === selectedRowIds[i]); // 該当データのindexを取得
      list.push(newRows[copyNewValueIdx]);
    }

    setSelectedRows(list);
  }, [selectedRowIds, newRows]);

  // 再取得する
  useEffect(() => {
    if (!isPageReload) return;
    reacquireData();
  }, [isPageReload]);

  // 一括在庫選択ボタンの状態管理
  useEffect(() => {
    if (!isSelectStocksBtn) return;
    let flg = true;
    if (selectedRows.length) {
      // 選択中のデータに該当データのindexを取得'在庫割当済'があるか探す
      const idx = selectedRows.findIndex((d) => d.status === '在庫割当済');

      if (idx >= 0) {
        // '在庫割当済'があればtrueにしてボタンを無効にする
        flg = true;
      } else {
        // '在庫割当済'がなければfalseにしてボタンを有効にする
        flg = false;
      }
    }
    setIsSelectStocksBtnDisabled(flg);
  }, [selectedRows]);

  /** セル編集開始 */
  const cellEditStart = () => {
    isEditingCell.current = true;
  };

  /** セル編集終わり */
  const cellFocusOut = (v) => {
    isEditingCell.current = false;
  };

  return (
    <>
      <div style={{ display: 'flex', width: '100%', height: vh }}>
        <div style={{ flexGrow: 1 }}>
          {title && <SubTitle title={title} />}
          {isButton && (
            <>
              <Stack
                direction='row'
                alignItems='center'
                spacing={2}
                component='div'
                sx={{ padding: '15px 0', textAlign: 'center' }}
              >
                {isCreateBtn && (
                  <Link style={{ textDecoration: 'none' }} to={{ pathname: createUrl }}>
                    <Button
                      onClick={() => {}}
                      variant='contained'
                      color='primary'
                      startIcon={<AddIcon />}
                    >
                      登録
                    </Button>
                  </Link>
                )}
                {isQRBtn && (
                  <Button
                    disabled={selectedRowIds && selectedRowIds.length === 0}
                    onClick={() => {}}
                    variant='contained'
                    color='primary'
                    startIcon={<DownloadIcon />}
                  >
                    {/* https://www.npmjs.com/package/react-csv */}
                    <CSVLink
                      filename={'stockList.csv'}
                      style={{
                        padding: '0',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                      }}
                      data={csvData}
                    ></CSVLink>
                    QRコード発行
                  </Button>
                )}
                {/* {isDeleteBtn && (
                  <Button
                    disabled={selectedRowIds && selectedRowIds.length === 0}
                    onClick={() => handleClickOpenDeleteForm(selectedRowIds)}
                    variant='contained'
                    color='warning'
                    startIcon={<DeleteIcon />}
                  >
                    まとめて削除
                  </Button>
                )} */}
                {handleClickChangeUCStatus && (
                  <FormControlLabel
                    value='end'
                    control={<Checkbox onClick={handleClickChangeUCStatus} size='small' />}
                    label='禁止を含む'
                    labelPlacement='end'
                  />
                )}
                {handleClickShipped && (
                  <FormControlLabel
                    value='end'
                    control={<Checkbox onClick={handleClickShipped} size='small' />}
                    label='出荷済を含む'
                    labelPlacement='end'
                  />
                )}
                {isAllStockBtn && (
                  <FormControlLabel
                    value='end'
                    control={
                      <Checkbox
                        onClick={() =>
                          handleClickAllStock(
                            subOrderData.row.order_thickness,
                            subOrderData.row.order_width
                          )
                        }
                        size='small'
                      />
                    }
                    label='全在庫'
                    labelPlacement='end'
                  />
                )}
                {isMIOutputBtn && (
                  <Button
                    onClick={handleClickShipSlipOutput}
                    variant='contained'
                    color='primary'
                    startIcon={<FileDownloadIcon />}
                    disabled={selectedRowIds.length > 0 ? false : true}
                  >
                    出荷伝票
                  </Button>
                )}
                {isPOutputBtn && (
                  <Button
                    onClick={handleClickProcessingSheet}
                    variant='contained'
                    color='primary'
                    startIcon={<FileDownloadIcon />}
                    disabled={selectedRowIds.length > 0 ? false : true}
                  >
                    加工指示出力
                  </Button>
                )}
                {isSelectStocksBtn && (
                  <Button
                    onClick={() => handleClickOpenSelectStocks()}
                    variant='contained'
                    color='primary'
                    startIcon={<FileDownloadIcon />}
                    disabled={selectedRowIds.length === 0 || isSelectStocksBtnDisabled}
                  >
                    一括在庫選択
                  </Button>
                )}
              </Stack>
            </>
          )}
          <CustomDataGrid
            rows={testRows ? testRows : newRows}
            columns={dataColumns}
            searchText={searchText}
            searchSecondText={searchSecondText}
            searchThirdText={searchThirdText}
            requestSearch={requestSearch}
            requestSecondSearch={requestSecondSearch}
            requestThirdSearch={requestThirdSearch}
            title={title}
            checkboxSelection={checkboxSelection}
            onSelectionModelChange={handleClickSelectedRows}
            isLoading={isLoading}
            autoHeight={autoHeight}
            columnVisibilityList={columnVisibilityList}
            defaultColumnVisibilityList={defaultColumnVisibilityList}
            localStorageKeyName={localStorageKeyName}
            cellEditStart={cellEditStart}
            cellFocusOut={cellFocusOut}
            defaultFilterColumn={defaultFilterColumn}
            defaultFilterOperator={defaultFilterOperator}
          />
          <DeleteForm
            table={table}
            openDeleteForm={openDeleteForm}
            addLoadingForm={addLoadingDeleteForm}
            isFetchLoading={isFetchLoading}
            deleteIds={deleteIds}
            deleteTableList={deleteTableList}
            isDeleteTables={isDeleteTables}
            deleteRows={deleteRows}
            deleteTables={deleteTables}
            handleCloseDeleteForm={handleCloseDeleteForm}
            loadinDelete={loadinDelete}
          />
          {/* 出荷伝票のモーダル */}
          <ShipSlipOutFormModal
            open={openShipSlipOutModal}
            setOpen={setOpenShipSlipOutModal}
            pdfDataList={pdfDataList}
            handleClickPrintShipSheet={handleClickPrintShipSheet}
          />
          {/* 加工指示書のモーダル */}
          {isPOutputBtn && (
            <ProcessingSheetModal
              open={openProcessingSheet}
              setOpen={setOpenProcessingSheet}
              pdfDatas={processingPDFDataList}
              printProcessingSheet={printProcessingSheet}
              handleClickStartProcessingBtn={handleClickStartProcessingBtn}
            />
          )}
        </div>
      </div>
    </>
  );
};

DataTable.propTypes = {
  checkboxSelection: PropTypes.bool, // チェックボックスを使うかどうか
  // isHide: PropTypes.bool, // テーブルを非表示にする true or false
  title: PropTypes.string.isRequired, // テーブルのタイトル
  columns: PropTypes.array.isRequired, // テーブルのカラム
  table: PropTypes.string, // テーブル名
  deleteTablesGetProperty: PropTypes.string, // 複数テーブル(現状は2つまで可)getで使用するプロパティ
  deleteTableList: PropTypes.array, // テーブル名
  api: PropTypes.string, // Get○○
  params: PropTypes.array, // 引数
  isCreateBtn: PropTypes.bool, // 登録ボタンを表示する時に使う
  isDetailBtn: PropTypes.bool, // 詳細ボタンを表示する時に使う
  isDeleteTables: PropTypes.bool, // 複数テーブル(現状は2つまで可)削除するときに使う
  isDeleteBtn: PropTypes.bool, // 削除ボタンを表示する時に使う
  isQRBtn: PropTypes.bool, // QRコードボタンを表示する時に使う
  isDecisionBtn: PropTypes.bool, // 決定ボタンを表示する時に使う
  isAllStockBtn: PropTypes.bool, // 全在庫ボタンを表示する時に使う
  handleClickDecision: PropTypes.func, // 決定ボタンのクリックイベント
  createUrl: PropTypes.string, // 登録画面のURL
  detailUrl: PropTypes.string, // 詳細画面のURL
  testRows: PropTypes.array, // テスト用の固定データ
  isStockBtn: PropTypes.bool, // 在庫選択ボタン
  autoHeight: PropTypes.bool,
  vh: PropTypes.string,
  handleClickChangeUCStatus: PropTypes.func, // 顧客ページの使用区分をクリックしたときの関数
  handleClickShipped: PropTypes.func, // 出荷済を含むをチェックしたときの関数
  columnVisibilityList: PropTypes.object,
  isButton: PropTypes.bool,
  subOrderData: PropTypes.object, // 注文情報
  isReacquireData: PropTypes.bool, // 再読み込み用
  getRows: PropTypes.func, // データを取得する
  isFilteringStockRows: PropTypes.bool, // 在庫一覧をフィルタリングするか
  selectedSubOrderLength: PropTypes.number, // 複数選択した注文の長さの合計値
  isShipList: PropTypes.bool, // 出荷一覧
  isMIOutputBtn: PropTypes.bool, // 出荷ボタン
  isPOutputBtn: PropTypes.bool, // 加工指示書出力ボタン
  handleClickStartProcessingBtn: PropTypes.func,
  handleClickProcessingSheet: PropTypes.func,
  openProcessingSheet: PropTypes.bool,
  setOpenProcessingSheet: PropTypes.func,
  printProcessingSheet: PropTypes.func,
  isSelectStocksBtn: PropTypes.bool,
  handleClickOpenSelectStock: PropTypes.func,
  handleClickOpenSelectStocks: PropTypes.func,
  setSelectedRows: PropTypes.func,
  setSubOrderGRows: PropTypes.func,
  isPageReload: PropTypes.bool,
  selectedStockIds: PropTypes.array,
  defaultColumnVisibilityList: PropTypes.object, // デフォルトのカラムの非表示に使う
  localStorageKeyName: PropTypes.string, // ローカルストレージのカラム設定を判別用(同じページにデータグリッドが二つある時に使う)
  handleClickPrintShipSheet: PropTypes.func,
  isClickOpenSelectStocks: PropTypes.bool,
  defaultFilterColumn: PropTypes.string,
  defaultFilterOperator: PropTypes.string,
};
