import React, { useCallback, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
// カスタムフック
import { useFetchDataWithInit } from 'hooks/useFetchData';
import { useSnackBar } from 'hooks/useSnackBar';
import { useGetDetailId } from 'hooks/useGetDetailId';
import { useCommonFunction } from 'hooks/useCommonFunction';
import { useAddOrUpdateData } from 'hooks/useAddOrUpdateData';

const STOCK_HISTORY_INPUT = {
  film_stocks_master_id: '',
  film_products_master_id: null,
  film_sub_orders_master_id: null,
  film_orders_master_id: null,
  history_date: '',
  length_us: null,
  length_ph: null,
  length_lo: null,
  reason: '',
};

/**
 * 登録フォームを使う際のカスタムフック
 * @param {String} getApi 登録API名
 */
export const useCreateStockForm = (
  api,
  columns,
  input,
  redirectUrl,
  selectedProduct,
  selectedOrderDate,
  createCount
) => {
  const [isCreateFormValid, setIsCreateFormValid] = useState(true);
  const [formInputValue, setFormInputValue] = useState(input);
  const [addLoadingForm, setAddLoadingForm] = useState(false);
  const [isCreated, setIsCreated] = useState(false);

  // カスタムフック
  const { onlyNumbersAndPoint, useGetToday } = useCommonFunction();
  const { toggleSnack } = useSnackBar();
  const { responseObj, responseObj2, addOrUpdateData } = useAddOrUpdateData();

  /**
   * True Falseの文字列をBooleanに変換
   */
  const toBoolean = (data) => {
    return String(data).toLowerCase() === 'true';
  };

  /** 登録時のchangeハンドラー */
  const handleChangeCreateForm = (e, type) => {
    if (e.target.value === undefined) return;
    let value = e.target.value;
    switch (type) {
      case 'number':
        value = onlyNumbersAndPoint(value);
        break;
      case 'bool':
        value = toBoolean(value);
        break;
      default:
        break;
    }

    let obj = { ...formInputValue, [e.target.name]: value };

    // 長さが変更されたら残長(物理)と残長(論理)にも同じ値を入れる
    if (e.target.name === 'length') {
      let cost = selectedProduct.cost_price_m * value;
      obj = {
        ...formInputValue,
        [e.target.name]: Number(value),
        length_lo: Number(value),
        length_ph: Number(value),
        cost_price: Number(cost),
      };
    }

    setFormInputValue(obj);
  };

  // 商品が選択されたら
  // ・商品IDを入れる
  // ・長さを残長(物理)と残長(論理)に入れる
  useEffect(() => {
    if (!Object.keys(selectedProduct).length) return;
    setFormInputValue({
      ...formInputValue,
      film_products_master_id: selectedProduct.id,
      length: selectedProduct.length,
      length_ph: selectedProduct.length,
      length_lo: selectedProduct.length,
      cost_price: selectedProduct.cost_price,
    });
    setIsCreateFormValid(false);
  }, [selectedProduct]);

  // 仕入れ日を選択したら仕入れ日を仕入れ日に入れる
  useEffect(() => {
    if (selectedOrderDate === '') return;
    setFormInputValue({
      ...formInputValue,
      stocking_date: selectedOrderDate,
    });

    if (isCreateFormValid) {
      if (selectedOrderDate !== 'NaN-aN-aN') {
        setIsCreateFormValid(false);
      }
    } else {
      if (selectedOrderDate === 'NaN-aN-aN') {
        setIsCreateFormValid(true);
      }
    }
    if (!Object.keys(selectedProduct).length) {
      setIsCreateFormValid(true);
    }
  }, [selectedOrderDate]);

  /** 登録する */
  const handleClickCreateForm = async (table) => {
    setAddLoadingForm(true); // ローディング表示
    setIsCreated(false);

    const copyInput = { ...formInputValue };

    let isRequiredItem = true;
    for (let key in columns) {
      if (!columns[key].create || columns[key].create === undefined) continue;

      // 必須項目が入力されているかチェックする
      if (columns[key].reauired) {
        if (
          copyInput[columns[key].field] === null ||
          copyInput[columns[key].field] === undefined ||
          copyInput[columns[key].field] === ''
        ) {
          isRequiredItem = false;
        }
      }

      // 登録する前に型を設定する
      if (
        copyInput[columns[key].field] !== undefined &&
        columns[key].formType &&
        columns[key].formType === 'number'
      ) {
        copyInput[columns[key].field] = Number(copyInput[columns[key].field]);
      }
    }

    // 必須項目の入力がない場合はエラー表示
    if (!isRequiredItem) {
      setAddLoadingForm(false); // ローディング非表示
      toggleSnack(true, 'error', '必須項目をご入力ください');
      return;
    }

    // 追加する個数分オブジェクトを複製する
    const inputArray = [];
    if (createCount <= 0) {
      setAddLoadingForm(false); // ローディング非表示
      toggleSnack(true, 'error', '追加する個数を1以上でご入力ください');
      return;
    }
    for (let i = 0; i < createCount; i++) {
      inputArray.push(copyInput);
    }

    // 在庫を登録する
    const url = `${process.env.REACT_APP_API_URL}/FILMa_AddStockAndStockHistory?code=${process.env.REACT_APP_API_CODE}`;

    let response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(inputArray),
    })
      .then((response) => {
        setAddLoadingForm(false); // ローディング非表示
        return response.json();
      })
      .catch((error) => {
        setAddLoadingForm(false); // ローディング非表示
        return false;
      });

    let status = 'success';
    let message = '登録が完了しました';
    if (response === false || response.code) {
      // 失敗時
      status = 'error';
      message = '登録に失敗しました' + ' FILMa_AddStockAndStockHistory';
    } else {
      // 成功時
      setIsCreateFormValid(true);
      // リロード
      window.location.replace(location.origin + redirectUrl); // 一覧へリダイレクト
    }

    // エラーコードがあれば足す
    if (response.code) {
      message = message + '[' + response.code + ']';
    }

    toggleSnack(true, status, message);
  };

  return {
    isCreateFormValid, // 登録フォームのボタンを押せるか
    formInputValue, // 登録フォームのinput
    addLoadingForm, // ローディング
    isCreated, // 登録が行われたか
    setFormInputValue, // 登録フォームのinputを作る
    handleChangeCreateForm, // 登録フォームのチェンジメソッド
    handleClickCreateForm, // 登録を行うメソッド
  };
};

/**
 * 更新フォームを使う際のカスタムフック
 * @param {String} getApi 更新後にGETする際のAPI名
 * @param {String} getParams 更新後にGETする際のinput
 */
export const useUpdateStockForm = (api, columns, redirectUrl) => {
  const [formInputValue, setFormInputValue] = useState([]);
  const [sHFormInputValue, setSHFormInputValue] = useState([STOCK_HISTORY_INPUT]);
  const [isChangeLengthBtn, setIsChangeLengthBtn] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const [isFormValid, setIsFormValid] = useState(true);
  const [addLoadingUpdateForm, setAddLoadingUpdateForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [openChangeLengthModal, setOpenChangeLengthModal] = useState(false);
  const [isReacquireData, setIsReacquireData] = useState(false);
  const [sHRows, setSHRows] = useState([]);
  const [helperText, setHelperText] = useState('');
  const [openDisposalConfModal, setOpenDisposalConfModal] = useState(false);

  // カスタムフック
  const { onlyNumbersAndPoint, useGetToday } = useCommonFunction();
  const { toggleSnack } = useSnackBar();
  const { isAddOrUpdateLoading, responseObj, responseObj2, addOrUpdateData } = useAddOrUpdateData();

  // 詳細読み込み用URL
  const getUrl = `${process.env.REACT_APP_API_URL}/FILMa_Get${api}?code=${process.env.REACT_APP_API_CODE}`;
  // カスタムフック
  const { id } = useGetDetailId();
  const { data, error, isLoading, reacquireData } = useFetchDataWithInit(getUrl, [{ id: id }]);

  useEffect(() => {
    if (!data) return;
    let status = 'success';
    let message = 'ページの読み込みに失敗しました';
    if (!data.code) {
      // 成功時
      setFormInputValue(data.result);

      let copyLists = JSON.parse(JSON.stringify(data.result)); // 初期データを保持したいのでディープコピー
      setInitialData(copyLists);
    } else {
      // 失敗時
      status = 'error';
      toggleSnack(true, status, message);
    }
  }, [data]);

  // エラーの場合
  useEffect(() => {
    if (!error) return;
    toggleSnack(true, 'error', 'ページの読み込みに失敗しました');
  }, [error]);

  /**
   * True Falseの文字列をBooleanに変換
   */
  const toBoolean = (data) => {
    return String(data).toLowerCase() === 'true';
  };

  /** 更新入力時のchangeハンドラー */
  const handleChangeUpdateForm = (e, type) => {
    if (e.target.value === undefined) return;
    let value = e.target.value;
    const copyInput = [...formInputValue];
    // typeに応じて型を指定する
    switch (type) {
      case 'number':
        value = onlyNumbersAndPoint(e.target.value);
        break;
      case 'bool':
        value = toBoolean(e.target.value);
        break;
      default:
        break;
    }

    copyInput[0][e.target.name] = value;
    setFormInputValue(copyInput);
    setIsFormValid(false);
  };

  /** 更新入力時(仕入れ日)のchangeハンドラー */
  const handleChangeDateUpdateForm = (stocking_date) => {
    if (formInputValue[0].stocking_date === undefined) return;
    if (formInputValue[0].stocking_date === stocking_date) return;
    formInputValue[0].stocking_date = stocking_date;
    setIsFormValid(false);
  }

  /** 更新する */
  const handleClickUpdateRows = async (btn) => {
    setAddLoadingUpdateForm(true);
    const copyInput = JSON.parse(JSON.stringify(formInputValue));

    // 廃棄ボタンが押されたら残長(物理)、残長(論理)の値を0にしてステータスを廃棄にする
    if (btn === 'disposal') {
      copyInput[0].length_ph = 0;
      copyInput[0].length_lo = 0;
      copyInput[0].status = '廃棄';

      // 在庫履歴用のinputを作成
      const sHInput = [
        {
          film_stocks_master_id: copyInput[0].id,
          film_products_master_id: copyInput[0].film_products_master_id,
          film_sub_orders_master_id: null,
          film_orders_master_id: null,
          history_date: useGetToday(),
          length_ph: 0,
          length_lo: 0,
          reason: '廃棄', // 変更事由を廃棄にする
        },
      ];

      const url = `${process.env.REACT_APP_API_URL}/FILMa_UpdateStockDestruction?code=${process.env.REACT_APP_API_CODE}`;

      let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(sHInput),
      })
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          return false;
        });

      let status = 'success';
      let message = '登録が完了しました';
      if (response === false || response.code) {
        // 失敗時
        status = 'error';
        message = '登録に失敗しました' + ' FILMa_UpdateStockDestruction';
      } else {
        // 成功時
        window.location.replace(location.href); // リロード
      }

      // エラーコードがあれば足す
      if (response.code) {
        message = message + '[' + response.code + ']';
      }

      toggleSnack(true, status, message);
      // 在庫履歴を登録する
      // await addOrUpdateData('Update', 'StockDestruction', sHInput);
    } else {
      // 更新する前に型を設定する
      for (let i = 0; i < columns.length; i++) {
        // 更新しないプロパティを削除
        if (!columns[i].update) {
          delete copyInput[0][columns[i].field];
        }

        if (
          copyInput[0][columns[i].field] !== undefined &&
          columns[i].formType &&
          columns[i].formType === 'number'
        ) {
          copyInput[0][columns[i].field] = Number(copyInput[0][columns[i].field]);
        }
      }

      const url = `${process.env.REACT_APP_API_URL}/FILMa_UpdateStock?code=${process.env.REACT_APP_API_CODE}`;

      let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(copyInput),
      })
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          return false;
        });

      let status = 'success';
      let message = '登録が完了しました';
      if (response === false || response.code) {
        // 失敗時
        status = 'error';
        message = '登録に失敗しました' + ' FILMa_UpdateStock';
      } else {
        // 成功時
        window.location.replace(location.href); // リロード
      }

      // エラーコードがあれば足す
      if (response.code) {
        message = message + '[' + response.code + ']';
      }

      toggleSnack(true, status, message);

      // 在庫を更新する
      // await addOrUpdateData('Update', 'Stock', copyInput);
    }
  };

  /**
   * 残長変更モーダルを閉じる
   */
  const handleCloseChangeLengthModal = () => {
    setOpenChangeLengthModal(false);
    handleChangeLengthForm({ target: { value: 0, name: 'length_us' } });
    handleChangeLengthForm({ target: { value: '', name: 'reason' } });
    setErrors({});
    setHelperText('');
  };

  /** 残長物理フォームのchangeハンドラー */
  const handleChangeLengthForm = (e) => {
    if (e.target.value === undefined) return;
    let copyFormInputValue = JSON.parse(JSON.stringify(formInputValue)); // inputをコピー
    let copySHFormInputValue = JSON.parse(JSON.stringify(sHFormInputValue)); // inputをコピー

    let value = e.target.value;

    if (e.target.type === 'number') {
      value = Number(value);
    }

    // 使用長が変更されたら残長(物理)と残長(論理)から引く
    if (e.target.name === 'length_us') {
      copyFormInputValue[0].length_ph = initialData[0].length_ph - value;
      copyFormInputValue[0].length_lo = initialData[0].length_lo - value;
      setIsChangeLengthBtn(false);

      // 残長物理が長さ以下の時は原反(残反)
      if (copyFormInputValue[0].length_ph <= copyFormInputValue[0].length) {
        copyFormInputValue[0].status = '原反(残反)';
      }
      // 残長物理が長さ以上の時は原反(新品)
      if (copyFormInputValue[0].length_ph >= copyFormInputValue[0].length) {
        copyFormInputValue[0].status = '原反(新品)';
      }

      // 残長物理が長さ以上の時は原反(新品)
      if (copyFormInputValue[0].length_ph <= 0) {
        copyFormInputValue[0].status = '廃棄';
      }

      // 残長(物理)を変更時に、残長(論理)が0未満になった場合はエラーメッセージを表示する
      if (copyFormInputValue[0].length_lo < 0) {
        setErrors({ [e.target.name]: true });
        setHelperText(
          '注文ID:' +
            copyFormInputValue[0].SubOrderIds.join() +
            'に在庫割り当てされているため、残長(物理)の変更が行えません。変更履歴から注文IDを確認の上、在庫割り当て内容の変更をお願いします。'
        );
      } else {
        setErrors({ [e.target.name]: false });
        setHelperText('');
      }
    }

    copySHFormInputValue[0][e.target.name] = value;

    setFormInputValue(copyFormInputValue);
    setSHFormInputValue(copySHFormInputValue);
  };

  /** 残長物理変更時の在庫登録 */
  const changeLength = async () => {
    setAddLoadingUpdateForm(true);

    const copyInput = JSON.parse(JSON.stringify(sHFormInputValue)); // コピー

    // 在庫IDを追加
    if (formInputValue.length > 0 && formInputValue[0].id !== undefined) {
      copyInput[0].film_stocks_master_id = formInputValue[0].id;
    }
    // 商品IDを追加
    if (formInputValue.length > 0 && formInputValue[0].film_products_master_id !== undefined) {
      copyInput[0].film_products_master_id = formInputValue[0].film_products_master_id;
    }
    // 残長(論理)を追加
    if (formInputValue.length > 0 && formInputValue[0].length_lo !== undefined) {
      copyInput[0].length_lo = formInputValue[0].length_lo;
    }
    // 残長(物理)を追加
    if (formInputValue.length > 0 && formInputValue[0].length_ph !== undefined) {
      copyInput[0].length_ph = formInputValue[0].length_ph;
    }
    // 変更日時を追加
    copyInput[0].history_date = useGetToday();

    const url = `${process.env.REACT_APP_API_URL}/FILMa_UpdateStockAndAddStockHistory?code=${process.env.REACT_APP_API_CODE}`;

    let response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(copyInput),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return false;
      });

    let status = 'success';
    let message = '登録が完了しました';
    if (response === false || response.code) {
      // 失敗時
      status = 'error';
      message = '登録に失敗しました' + ' FILMa_UpdateStockAndAddStockHistory';
    } else {
      // 成功時
      window.location.replace(location.href); // リロード
    }

    // エラーコードがあれば足す
    if (response.code) {
      message = message + '[' + response.code + ']';
    }

    toggleSnack(true, status, message);
  };

  // 在庫更新時のレスポンスが返ってきたあとの処理
  useEffect(() => {
    if (!Object.keys(responseObj).length) return;
    let status = 'success';
    let message = '更新が完了しました';

    if (responseObj.code) {
      // 失敗時
      status = 'error';
      message = '更新に失敗しました' + ' FILMa_UpdateStock';

      // エラーコードがあれば足す
      if (responseObj.code) {
        message = message + '[' + responseObj.code + ']';
      }
    } else {
      // 成功時
      setFormInputValue([]);
      setIsFormValid(true);
      reacquireData(); // 詳細を再読み込みする
      setIsReacquireData(true); // 履歴を再読み込みする
      setOpenDisposalConfModal(false);
    }

    // 失敗時 error
    if (responseObj.error) {
      status = 'error';
      message = '更新に失敗しました' + ' FILMa_UpdateStock';
    }

    toggleSnack(true, status, message);
    setAddLoadingUpdateForm(false);
  }, [responseObj]);

  // 在庫履歴登録時のレスポンスが返ってきたあとの処理
  useEffect(() => {
    if (!Object.keys(responseObj2).length) return;
    let status = 'success';
    let message = '更新が完了しました';

    if (responseObj2.code) {
      // 失敗時
      status = 'error';
      message = '更新に失敗しました' + ' FILMa_AddStockHistory';

      // エラーコードがあれば足す
      if (responseObj2.code) {
        message = message + '[' + responseObj2.code + ']';
      }
    } else {
      // 成功時
      setFormInputValue([]);
      setIsChangeLengthBtn(true);
      setOpenChangeLengthModal(false); // 残長変更モーダル閉じる
      handleClickUpdateRows(); // 詳細画面の残長(物理)を更新
    }

    // 失敗時 error
    if (responseObj2.error) {
      status = 'error';
      message = '更新に失敗しました' + ' FILMa_AddStockHistory';
    }

    setAddLoadingUpdateForm(false);
    toggleSnack(true, status, message);
  }, [responseObj2]);

  // 廃棄ボタンを押下後、モーダル開く
  const handleClickDisposal = () => {
    setOpenDisposalConfModal(true);
  };

  return {
    id,
    isLoading,
    formInputValue,
    isFormValid, // 更新フォームのボタンを押せるか
    errors,
    addLoadingUpdateForm, // ローディング
    sHFormInputValue,
    isChangeLengthBtn,
    isReacquireData,
    openChangeLengthModal, // 残長変更モーダルを開く
    setOpenChangeLengthModal, // 残長変更モーダルを開く
    handleChangeUpdateForm, // 更新フォームのチェンジメソッド
    handleClickUpdateRows, // 更新を行うメソッド
    handleChangeLengthForm, // 残長物理フォームのチェンジメソッド
    changeLength, // 残長物理が変更されたときのメソッド
    setSHRows, // 在庫履歴のデータを取得する
    helperText,
    handleCloseChangeLengthModal,
    openDisposalConfModal,
    setOpenDisposalConfModal,
    handleClickDisposal,
    isAddOrUpdateLoading,
    handleChangeDateUpdateForm,
  };
};
