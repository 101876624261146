import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from 'pages/Layout';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
// page
import { LoginPage } from 'pages/LoginPage';
import { NotFound } from 'pages/NotFound';
import { NotAuthPage } from 'pages/NotAuthPage';
import { DashboardPage } from 'pages/DashboardPage';
import { OrderListPage } from 'pages/OrderListPage';
import { OrderCreatePage } from 'pages/OrderCreatePage';
import { OrderDetailPage } from 'pages/OrderDetailPage';
import { SubOrderListPage } from 'pages/SubOrderListPage';
import { ProcessingListPage } from 'pages/ProcessingListPage';
import { ShipListPage } from 'pages/ShipListPage';
import { ClientListPage } from 'pages/ClientListPage';
import { ClientCreatePage } from 'pages/ClientCreatePage';
import { ClientDetailPage } from 'pages/ClientDetailPage';
import { ProductListPage } from 'pages/ProductListPage';
import { ProductCreatePage } from 'pages/ProductCreatePage';
import { ProductDetailPage } from 'pages/ProductDetailPage';
import { StockListPage } from 'pages/StockListPage';
import { StockCreatePage } from 'pages/StockCreatePage';
import { StockDetailPage } from 'pages/StockDetailPage';
import { CustomerCategory1ListPage } from 'pages/CustomerCategory1ListPage';
import { CustomerCategory1CreatePage } from 'pages/CustomerCategory1CreatePage';
import { CustomerCategory1DetailPage } from 'pages/CustomerCategory1DetailPage';
import { CustomerCategory2ListPage } from 'pages/CustomerCategory2ListPage';
import { CustomerCategory2CreatePage } from 'pages/CustomerCategory2CreatePage';
import { CustomerCategory2DetailPage } from 'pages/CustomerCategory2DetailPage';
import { CustomerCategory3ListPage } from 'pages/CustomerCategory3ListPage';
import { CustomerCategory3CreatePage } from 'pages/CustomerCategory3CreatePage';
import { CustomerCategory3DetailPage } from 'pages/CustomerCategory3DetailPage';
import { OtherSettingDetailPage } from 'pages/OtherSettingDetailPage';

// Recoil
import { RecoilRoot } from 'recoil';
// コンポーネント
import { SimpleSnackbar } from 'components/uiParts/Snackbar/SimpleSnackbar';
// カスタムフック
import { useCurrentUser } from 'hooks/useCurrentUser';

export const RouterConfig = () => {
  const { isWorkerEnvironment, isQrEnvironment } = useCurrentUser();

  return (
    <>
      <BrowserRouter>
        <RecoilRoot>
          <SimpleSnackbar />
          <Routes>
            {/* ログインページ */}
            <Route
              path='/login'
              element={
                <>
                  <AuthenticatedTemplate>
                    {/* 開発者用アカウントはdevへ */}
                    {/* 農場管理者画面へのアクセス権があればadminへ */}
                    <Navigate to={'/'} />
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <LoginPage />
                  </UnauthenticatedTemplate>
                </>
              }
            />
            {/* 個別での権限処理が必要(受注詳細) */}
            <Route
              path='/order/detail'
              element={
                <>
                  <AuthenticatedTemplate>
                    {!isWorkerEnvironment ? (
                      <>
                        <Layout />
                      </>
                    ) : isWorkerEnvironment && isQrEnvironment ? (
                      <>
                        <Layout />
                      </>
                    ) : (
                      <NotFound />
                    )}
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <LoginPage />
                  </UnauthenticatedTemplate>
                </>
              }
            >
              <Route path='' element={<OrderDetailPage />}></Route>
            </Route>
            {/* 個別での権限処理が必要(在庫詳細) */}
            <Route
              path='/stock/detail'
              element={
                <>
                  <AuthenticatedTemplate>
                    <>
                      <Layout />
                    </>
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <LoginPage />
                  </UnauthenticatedTemplate>
                </>
              }
            >
              <Route path='' element={<StockDetailPage />}></Route>
            </Route>
            {/* ログイン確認と権限確認 */}
            <Route
              path=''
              element={
                <>
                  <AuthenticatedTemplate>
                    {!isWorkerEnvironment ? <Layout /> : <NotAuthPage />}
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <LoginPage />
                  </UnauthenticatedTemplate>
                </>
              }
            >
              {/* 受注関係 */}
              <Route path='/order'>
                <Route path='list' element={<OrderListPage />} />
                <Route path='create' element={<OrderCreatePage />} />
              </Route>
              {/* 加工関係 */}
              <Route path='/processing'>
                <Route path='list' element={<ProcessingListPage />} />
              </Route>
              {/* 出荷関係 */}
              <Route path='/ship'>
                <Route path='list' element={<ShipListPage />} />
              </Route>
              {/* 注文関係 */}
              <Route path='/subOrder'>
                <Route path='list' element={<SubOrderListPage />} />
              </Route>
              {/* 顧客関係 */}
              <Route path='/client'>
                <Route path='list' element={<ClientListPage />} />
                <Route path='create' element={<ClientCreatePage />} />
                <Route path='detail' element={<ClientDetailPage />} />
              </Route>
              {/* 商品関係 */}
              <Route path='/product'>
                <Route path='list' element={<ProductListPage />} />
                <Route path='create' element={<ProductCreatePage />} />
                <Route path='detail' element={<ProductDetailPage />} />
              </Route>
              {/* 在庫関係 */}
              <Route path='/stock'>
                <Route path='list' element={<StockListPage />} />
                <Route path='create' element={<StockCreatePage />} />
                {/* <Route path='detail' element={<StockDetailPage />} /> */}
              </Route>
              {/* 得意先区分1関係 */}
              <Route path='/customerCategory1'>
                <Route path='list' element={<CustomerCategory1ListPage />} />
                <Route path='create' element={<CustomerCategory1CreatePage />} />
                <Route path='detail' element={<CustomerCategory1DetailPage />} />
              </Route>
              {/* 得意先区分2関係 */}
              <Route path='/customerCategory2'>
                <Route path='list' element={<CustomerCategory2ListPage />} />
                <Route path='create' element={<CustomerCategory2CreatePage />} />
                <Route path='detail' element={<CustomerCategory2DetailPage />} />
              </Route>
              {/* 得意先区分3関係 */}
              <Route path='/customerCategory3'>
                <Route path='list' element={<CustomerCategory3ListPage />} />
                <Route path='create' element={<CustomerCategory3CreatePage />} />
                <Route path='detail' element={<CustomerCategory3DetailPage />} />
              </Route>
              {/* その他設定関係 */}
              <Route path='/otherSetting'>
                <Route path='detail' element={<OtherSettingDetailPage />} />
              </Route>
              {/* ダッシュボード */}
              <Route path='/'>
                <Route path='' element={<DashboardPage />} />
              </Route>
            </Route>
            {/* 不正なURLを飛ばすNotFoundに飛ばす */}
            <Route
              path='*'
              element={
                <>
                  <AuthenticatedTemplate>
                    <NotFound />
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <NotFound />
                  </UnauthenticatedTemplate>
                </>
              }
            />
          </Routes>
        </RecoilRoot>
      </BrowserRouter>
    </>
  );
};
