import React, { useState } from 'react';
import PropTypes from 'prop-types';
// MUI
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
// コンポーネント
import { DataTable } from 'components/uiParts/DataGrid/DataTable';
// 定数
import { STOCK_TABLE_COLUMNS_FOR_MODAL } from 'utils/constantsTable';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export const SelectStockModal = (props) => {
  const {
    open,
    handleClickSelectStock,
    handleClose,
    subOrderData,
    isAllStockBtn,
    selectedSubOrderLength,
    countCheckbox,
    isClickSelectStocksBtn,
    selectedStockIds,
    isClickOpenSelectStocks,
  } = props;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <IconButton
            sx={{ position: 'absolute', right: '5px', top: '5px' }}
            color='primary'
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <>
            {Object.keys(subOrderData).length > 0 && subOrderData.row !== undefined && (
              <span>
                商品名:
                {subOrderData.row.processing_product_name !== undefined ? subOrderData.row.processing_product_name : ''}
                / 厚み:
                {subOrderData.row.order_thickness !== undefined ? subOrderData.row.order_thickness : ''} /
                幅:
                {subOrderData.row.order_width !== undefined ? subOrderData.row.order_width : ''} / 長さ:
                {subOrderData.row.order_length !== undefined ? subOrderData.row.order_length : ''}
                {isClickSelectStocksBtn && countCheckbox !== undefined
                  ? ' / 選択枚数:' + countCheckbox
                  : ''}
                {isClickSelectStocksBtn && selectedSubOrderLength !== undefined
                  ? ' / 合計の長さ:' + selectedSubOrderLength
                  : ''}
              </span>
            )}
            <div style={{ overflow: 'auto' }}>
              <DataTable
                title=''
                isDecisionBtn={true}
                handleClickDecision={handleClickSelectStock}
                isAllStockBtn={isAllStockBtn}
                columns={STOCK_TABLE_COLUMNS_FOR_MODAL}
                api='SortedStockForUser'
                params={[
                  { status: '原反(残反)' },
                  { status: '仕掛品' },
                  { status: '原反(新品)' },
                  { status: 'その他' },
                ]}
                subOrderData={subOrderData}
                isFilteringStockRows
                selectedSubOrderLength={selectedSubOrderLength}
                selectedStockIds={selectedStockIds}
                isClickOpenSelectStocks={isClickOpenSelectStocks}
                defaultFilterColumn='length'
                defaultFilterOperator='='
              />
            </div>
          </>
        </Box>
      </Modal>
    </div>
  );
};

SelectStockModal.propTypes = {
  open: PropTypes.bool.isRequired, // モーダル開く
  handleClose: PropTypes.func.isRequired, // モーダル開く
  handleClickSelectStock: PropTypes.func.isRequired, // モーダル開く
  subOrderData: PropTypes.object,
  isAllStockBtn: PropTypes.bool,
  selectedSubOrderLength: PropTypes.number,
  countCheckbox: PropTypes.number,
  isClickSelectStocksBtn: PropTypes.bool,
  selectedStockIds: PropTypes.array,
  isClickOpenSelectStocks: PropTypes.bool,
};
