import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// MUI
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
// コンポーネント
import { SubTitle } from 'components/uiParts/SubTitle';
import { BorderTop } from 'components/uiParts/BorderTop';
import { Required } from 'components/uiParts/Required';
// カスタムフック
import { useClientDetail } from 'hooks/useClientDetail';
import { useGetSalesCategory } from 'hooks/useGetSalesCategory';
import { useGetProcessingPriceCategory } from 'hooks/useGetProcessingPriceCategory';
import {
  useGetCustomerCategory1,
  useGetCustomerCategory2,
  useGetCustomerCategory3,
} from 'hooks/useGetCustomerCategory';
// 定数ファイル
import { CLIENT_TABLE_COLUMNS } from 'utils/constantsTable';

export const UpdateClientForm = (props) => {
  const { api, params, columns } = props;

  // カスタムフック
  const {
    formInputValue,
    addLoadingForm,
    isFormValid,
    handleChangeUpdateForm,
    handleClickUpdateForm,
  } = useClientDetail(api, params, columns);

  const { salesCategoryList } = useGetSalesCategory();
  const { pPCategory } = useGetProcessingPriceCategory();
  const { customerCategoryList1 } = useGetCustomerCategory1();
  const { customerCategoryList2 } = useGetCustomerCategory2();
  const { customerCategoryList3 } = useGetCustomerCategory3();

  return (
    <>
      <div style={{ margin: '30px 0 0' }}>
        <SubTitle title='・お客様情報' hideBorder={true} />
        {formInputValue &&
          formInputValue.length > 0 &&
          columns.map(
            (column, index) =>
              column.update && (
                <div key={index} style={{ margin: '20px 0 0' }}>
                  <div style={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
                    {column.headerName}
                    {column.reauired && <Required />}
                  </div>
                  {column.formType === 'select' ? (
                    <>
                      {(column.field === 'title_of_honor' || column.field === 'usage_category') &&
                        column.valueOptions &&
                        column.valueOptions.length > 0 && (
                          <TextField
                            name={column.field}
                            onChange={(e) =>
                              handleChangeUpdateForm(
                                e,
                                column.formType === 'number' ? 'number' : 'text'
                              )
                            }
                            select
                            value={
                              formInputValue[0][column.field]
                                ? formInputValue[0][column.field]
                                : 'defalut'
                            }
                            size='small'
                            sx={{ margin: '10px 0 0', width: 400 }}
                            required
                          >
                            <MenuItem value='defalut' hidden>
                              選択してください
                            </MenuItem>
                            {column.valueOptions.map((data, index) => (
                              <MenuItem key={index} value={data}>
                                {data}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      {column.field === 'film_sales_price_categories_master_id' && (
                        <TextField
                          name={column.field}
                          onChange={(e) =>
                            handleChangeUpdateForm(
                              e,
                              column.formType === 'number' ? 'number' : 'text'
                            )
                          }
                          select
                          value={
                            formInputValue[0][column.field]
                              ? formInputValue[0][column.field]
                              : 'defalut'
                          }
                          size='small'
                          sx={{ margin: '10px 0 0', width: 400 }}
                          required
                        >
                          <MenuItem value='defalut' hidden>
                            選択してください
                          </MenuItem>
                          {salesCategoryList.map((data, index) => (
                            <MenuItem key={index} value={data.id}>
                              {data.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      {column.field === 'film_processing_price_categories_master_id' && (
                        <TextField
                          name={column.field}
                          onChange={(e) =>
                            handleChangeUpdateForm(
                              e,
                              column.formType === 'number' ? 'number' : 'text'
                            )
                          }
                          select
                          value={
                            formInputValue[0][column.field]
                              ? formInputValue[0][column.field]
                              : 'defalut'
                          }
                          size='small'
                          sx={{ margin: '10px 0 0', width: 400 }}
                          required
                        >
                          <MenuItem value='defalut' hidden>
                            選択してください
                          </MenuItem>
                          {pPCategory.map((data, index) => (
                            <MenuItem key={index} value={data.id}>
                              {data.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      {column.field === 'film_customer_categories_1_master_id' && (
                        <TextField
                          name={column.field}
                          onChange={(e) =>
                            handleChangeUpdateForm(
                              e,
                              column.formType === 'number' ? 'number' : 'text'
                            )
                          }
                          select
                          value={
                            formInputValue[0][column.field]
                              ? formInputValue[0][column.field]
                              : 'defalut'
                          }
                          size='small'
                          sx={{ margin: '10px 0 0', width: 400 }}
                          required
                        >
                          <MenuItem value='defalut' hidden>
                            選択してください
                          </MenuItem>
                          {customerCategoryList1.map((data, index) => (
                            <MenuItem key={index} value={data.id}>
                              {data.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      {column.field === 'film_customer_categories_2_master_id' && (
                        <TextField
                          name={column.field}
                          onChange={(e) =>
                            handleChangeUpdateForm(
                              e,
                              column.formType === 'number' ? 'number' : 'text'
                            )
                          }
                          select
                          value={
                            formInputValue[0][column.field]
                              ? formInputValue[0][column.field]
                              : 'defalut'
                          }
                          size='small'
                          sx={{ margin: '10px 0 0', width: 400 }}
                          required
                        >
                          <MenuItem value='defalut' hidden>
                            選択してください
                          </MenuItem>
                          {customerCategoryList2.map((data, index) => (
                            <MenuItem key={index} value={data.id}>
                              {data.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      {column.field === 'film_customer_categories_3_master_id' && (
                        <TextField
                          name={column.field}
                          onChange={(e) =>
                            handleChangeUpdateForm(
                              e,
                              column.formType === 'number' ? 'number' : 'text'
                            )
                          }
                          select
                          value={
                            formInputValue[0][column.field]
                              ? formInputValue[0][column.field]
                              : 'defalut'
                          }
                          size='small'
                          sx={{ margin: '10px 0 0', width: 400 }}
                          required
                        >
                          <MenuItem value='defalut' hidden>
                            選択してください
                          </MenuItem>
                          {customerCategoryList3.map((data, index) => (
                            <MenuItem key={index} value={data.id}>
                              {data.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </>
                  ) : (
                    <TextField
                      disabled={column.disabled}
                      name={column.field}
                      onChange={(e) =>
                        handleChangeUpdateForm(e, column.formType === 'number' ? 'number' : 'text')
                      }
                      value={formInputValue[0][column.field] ? formInputValue[0][column.field] : ''}
                      sx={{ margin: '10px 0 0', width: 400 }}
                      size='small'
                    />
                  )}
                </div>
              )
          )}
        <BorderTop />
        <div style={{ textAlign: 'right' }}>
          <LoadingButton
            disabled={isFormValid}
            onClick={handleClickUpdateForm}
            variant='contained'
            loading={addLoadingForm}
          >
            顧客を更新する
          </LoadingButton>
        </div>
      </div>
    </>
  );
};

UpdateClientForm.propTypes = {
  api: PropTypes.string.isRequired, // Get○○
  params: PropTypes.array.isRequired, // getする際のinput
  columns: PropTypes.array.isRequired, // カラム
};
