import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'utils/authConfig';

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 * リダイレクトを使用したサインイン
 * @see https://docs.microsoft.com/ja-jp/azure/active-directory/develop/tutorial-v2-react#sign-in-using-redirects
 */
export const LoginPage = () => {
  const { instance } = useMsal();

  const loginFunction = (instance) => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  }

  useEffect(() => {
    if (!instance) return;
    loginFunction(instance);
  }, [instance]);

  return(<></>);
};
