import React from 'react';
import { PRODUCT_TABLE_COLUMNS } from 'utils/constantsTable';
// コンポーネント
import { SubTitle } from 'components/uiParts/SubTitle';
import { UpdateProductForm } from 'components/uiParts/Form/UpdateProductForm';
import { Breadcrumb } from 'components/uiParts/Breadcrumb';
// カスタムフック
import { useGetDetailId } from 'hooks/useGetDetailId';
// MUI
import Button from '@mui/material/Button';

export const ProductDetailPage = () => {
  // カスタムフック
  const { id } = useGetDetailId();

  const breadcrumbList = [
    { name: 'ダッシュボード', href: '/' },
    { name: '商品一覧', href: '/product/list' },
    { name: '商品詳細' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SubTitle title='商品詳細' />
        <Button href={location.origin + '/product/list'} variant='contained' color='inherit'>
          戻る
        </Button>
      </div>
      <UpdateProductForm api='Product' params={[{ id: id }]} columns={PRODUCT_TABLE_COLUMNS} />
    </>
  );
};
