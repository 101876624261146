import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// mui
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { SignOutButton } from 'components/uiParts/SignOutButton';
// hook
import { useDrawerMenu } from 'hooks/useDrawerMenu';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const drawerWidth = 200;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

export const DrawerMenu = (props) => {
  const { title, children } = props;

  const { open, handleDrawerOpen, handleDrawerClose } = useDrawerMenu();

  const theme = useTheme();

  return (
    <>
      <Container id='text' maxWidth='false'>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position='fixed' open={open}>
            <Toolbar>
              {!open && (
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={handleDrawerOpen}
                  edge='start'
                  sx={{ mr: 2, ...(open && { display: 'none' }) }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Typography variant='h6' noWrap component='div'>
                {title}
              </Typography>
              <Link
                style={{ marginLeft: '10px', color: 'white', fontSize: '14px' }}
                to={{
                  pathname: '/order/create',
                }}
              >
                受注登録
              </Link>
              <Link
                style={{ marginLeft: '15px', color: 'white', fontSize: '14px' }}
                to={{
                  pathname: '/processing/list',
                }}
              >
                加工指示書・在庫選択
              </Link>
              <Link
                style={{ marginLeft: '10px', color: 'white', fontSize: '14px' }}
                to={{
                  pathname: '/ship/list',
                }}
              >
                出荷伝票
              </Link>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant='persistent'
            anchor='left'
            open={open}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <Link style={{ color: '#333', textDecoration: 'none' }} to={{ pathname: '/' }}>
              <ListItem button style={{ padding: '10px' }}>
                <ListItemText
                  primary='ダッシュボード'
                  secondary={
                    <>
                      {'加工指示書・在庫選択'}
                      <br />
                      {'出荷伝票'}
                    </>
                  }
                />
              </ListItem>
            </Link>
            <Accordion sx={{ margin: '0 !important' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                sx={{ minHeight: '0 !important' }}
              >
                <Typography>受注</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Link
                  style={{ color: '#333', textDecoration: 'none' }}
                  to={{ pathname: '/order/create' }}
                >
                  <ListItem button style={{ padding: '10px' }}>
                    <ListItemText primary='受注登録' />
                  </ListItem>
                </Link>
                <Link
                  style={{ color: '#333', textDecoration: 'none' }}
                  to={{ pathname: '/order/list' }}
                >
                  <ListItem button style={{ padding: '10px' }}>
                    <ListItemText
                      primary='受注一覧'
                      secondary={
                        <>
                          {'受注詳細・注文詳細'}
                          <br />
                          {'加工指示書・在庫選択'}
                          <br />
                          {'出荷伝票'}
                        </>
                      }
                    />
                  </ListItem>
                </Link>
                <Link
                  style={{ color: '#333', textDecoration: 'none' }}
                  to={{ pathname: '/subOrder/list' }}
                >
                  <ListItem button style={{ padding: '10px' }}>
                    <ListItemText primary='注文一覧' />
                  </ListItem>
                </Link>
                <Link
                  style={{ color: '#333', textDecoration: 'none' }}
                  to={{ pathname: '/processing/list' }}
                >
                  <ListItem button style={{ padding: '10px' }}>
                    <ListItemText primary='加工一覧' secondary={<>{'加工指示書・在庫選択'}</>} />
                  </ListItem>
                </Link>
                <Link
                  style={{ color: '#333', textDecoration: 'none' }}
                  to={{ pathname: '/ship/list' }}
                >
                  <ListItem button style={{ padding: '10px' }}>
                    <ListItemText primary='出荷一覧' secondary={<>{'出荷伝票'}</>} />
                  </ListItem>
                </Link>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <Accordion sx={{ margin: '0 !important' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel3a-content'
                id='panel3a-header'
              >
                <Typography>在庫</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Link
                  style={{ color: '#333', textDecoration: 'none' }}
                  to={{ pathname: '/stock/List' }}
                >
                  <ListItem button style={{ padding: '10px' }}>
                    <ListItemText
                      primary='在庫一覧'
                      secondary={
                        <>
                          {'在庫詳細'}
                          <br />
                          {'QRコード発行'}
                        </>
                      }
                    />
                  </ListItem>
                </Link>
                <Link
                  style={{ color: '#333', textDecoration: 'none' }}
                  to={{ pathname: '/stock/create' }}
                >
                  <ListItem button style={{ padding: '10px' }}>
                    <ListItemText primary='在庫登録' />
                  </ListItem>
                </Link>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <Accordion sx={{ margin: '0 !important' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel4a-content'
                id='panel4a-header'
              >
                <Typography>顧客マスター</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Link
                  style={{ color: '#333', textDecoration: 'none' }}
                  to={{ pathname: '/client/list' }}
                >
                  <ListItem button style={{ padding: '10px' }}>
                    <ListItemText primary='顧客一覧' />
                  </ListItem>
                </Link>
                <Link
                  style={{ color: '#333', textDecoration: 'none' }}
                  to={{ pathname: '/client/create' }}
                >
                  <ListItem button style={{ padding: '10px' }}>
                    <ListItemText primary='顧客登録' />
                  </ListItem>
                </Link>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <Accordion sx={{ margin: '0 !important' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel5a-content'
                id='panel5a-header'
              >
                <Typography>商品マスター</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Link
                  style={{ color: '#333', textDecoration: 'none' }}
                  to={{ pathname: '/product/list' }}
                >
                  <ListItem button style={{ padding: '10px' }}>
                    <ListItemText primary='商品一覧' />
                  </ListItem>
                </Link>
                <Link
                  style={{ color: '#333', textDecoration: 'none' }}
                  to={{ pathname: '/product/create' }}
                >
                  <ListItem button style={{ padding: '10px' }}>
                    <ListItemText primary='商品登録' />
                  </ListItem>
                </Link>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <Accordion sx={{ margin: '0 !important' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel5a-content'
                id='panel5a-header'
              >
                <Typography>各種設定</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* ページとして用意したが、データが4つで固定になりそうなのでドロワーのリンクをコメントアウトする
                <Link
                  style={{ color: '#333', textDecoration: 'none' }}
                  to={{ pathname: '/group/list' }}
                >
                  <ListItem button style={{ padding: '10px' }}>
                    <ListItemText primary='販売価格区分' />
                  </ListItem>
                </Link> */}
                <Link
                  style={{ color: '#333', textDecoration: 'none' }}
                  to={{ pathname: '/customerCategory1/list' }}
                >
                  <ListItem button style={{ padding: '10px' }}>
                    <ListItemText primary='得意先区分1' />
                  </ListItem>
                </Link>
                <Link
                  style={{ color: '#333', textDecoration: 'none' }}
                  to={{ pathname: '/customerCategory2/list' }}
                >
                  <ListItem button style={{ padding: '10px' }}>
                    <ListItemText primary='得意先区分2' />
                  </ListItem>
                </Link>
                <Link
                  style={{ color: '#333', textDecoration: 'none' }}
                  to={{ pathname: '/customerCategory3/list' }}
                >
                  <ListItem button style={{ padding: '10px' }}>
                    <ListItemText primary='得意先区分3' />
                  </ListItem>
                </Link>
                <Link
                  style={{ color: '#333', textDecoration: 'none' }}
                  to={{ pathname: '/otherSetting/detail?id=1' }}
                >
                  <ListItem button style={{ padding: '10px' }}>
                    <ListItemText primary='その他設定' />
                  </ListItem>
                </Link>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <List style={{ padding: '5px 10px' }}>
              <SignOutButton />
            </List>
          </Drawer>
          <Main open={open}>
            <DrawerHeader />
            {children}
          </Main>
        </Box>
      </Container>
    </>
  );
};

DrawerMenu.propTypes = {
  title: PropTypes.string.isRequired,
  // type: PropTypes.string.isRequired, // dev or user or admin
  // groupName: PropTypes.string, // ドロワーメニューのタイトル ユーザーの名前
  // offDrawerMenu: PropTypes.bool, // ドロワーメニューの開閉をしたく無い場合はTrueで渡す
  children: PropTypes.element,
};
