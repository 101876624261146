import { atom } from 'recoil';

export const snackBarState = atom({
  key: 'snackBarState',
  default: [
    {
      isOpen: false, //boolean型で表示、非表示を切り替える
      type: '', //success, infoなどの背景色を変えるもの sucess(緑),info(青),error（赤）,waring(橙)
      message: '', //中に入れるメッセージ内容
    },
  ],
});
