import React, { useState, useEffect } from 'react';

/**
 * 加工指示を出力するカスタムフック
 */
export const useProcessingSheet = (selectedRowIds, newRows) => {
  const [processingPDFDataList, setProcessingPDFDataList] = useState([]);

  // 加工指示出力用のPDFデータを作成
  useEffect(() => {
    if (!selectedRowIds.length) return;
    const list = [];
    for (let i = 0; i < selectedRowIds.length; i++) {
      const copyNewValueIdx = newRows.findIndex((d) => d.id === selectedRowIds[i]); // 該当データのindexを取得
      if (copyNewValueIdx >= 0) {
        list.push(newRows[copyNewValueIdx]);
      }
    }

    setProcessingPDFDataList(list);
  }, [selectedRowIds, newRows]);

  return {
    processingPDFDataList,
    setProcessingPDFDataList,
  };
};
