import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Box, Popper, Paper, Typography } from '@mui/material';

function isOverflown(element) {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

/**
 * セルの中身が多い場合に、ホバーすると表示されるテキスト
 * @see https://github.com/mui/mui-x/issues/417#issuecomment-782772202
 * @see https://codesandbox.io/s/bjupl?file=/demo.js:2836-2842
 * @return  セルの中身を表示
 */
export const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, params } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <>
      {params.colDef.type === 'number' ? (
        <Box
          ref={wrapper}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sx={{
            alignItems: 'center',
            lineHeight: '24px',
            width: 1,
            height: 1,
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            ref={cellDiv}
            sx={{
              height: 1,
              display: 'block',
              position: 'absolute',
              top: 0,
            }}
          />
          <Box
            ref={cellValue}
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {params.field === 'StockIds' ? (
              <>
                {params.value.map((x, index) => (
                  <Link key={index} to={{ pathname: '/stock/detail?id=' + x }}>
                    {x}
                    {params.value.length - 1 !== index && ','}
                  </Link>
                ))}
              </>
            ) : params.field === 'OutsourcingIds' ? (
              <>
                {params.value.map((x, index) => (
                  <a key={index}>
                    {x}
                    {params.value.length - 1 !== index && ','}
                  </a>
                ))}
              </>
            ) : params.colDef.isToLocaleString ? (
              <>{params.value.toLocaleString()}</>
            ) : (
              params.value
            )}
            {/* {params.colDef.isToLocaleString ? <>{params.value.toLocaleString()}</> : params.value} */}
          </Box>
          {showPopper && (
            <Popper
              open={showFullCell && anchorEl !== null}
              anchorEl={anchorEl}
              style={{ width, zIndex: 1300 }}
            >
              <Paper elevation={1} style={{ minHeight: wrapper.current.offsetHeight - 3 }}>
                <Typography variant='body2' style={{ padding: 8, wordBreak: 'break-all' }}>
                  {params.field === 'StockIds' ? (
                    <>
                      {params.value.map((x, index) => (
                        <Link key={index} to={{ pathname: '/stock/detail?id=' + x }}>
                          {x}
                          {params.value.length - 1 !== index && ','}
                        </Link>
                      ))}
                    </>
                  ) : params.field === 'OutsourcingIds' ? (
                    <>
                      {params.value.map((x, index) => (
                        <a key={index}>
                          {x}
                          {params.value.length - 1 !== index && ','}
                        </a>
                      ))}
                    </>
                  ) : params.colDef.isToLocaleString ? (
                    <>{params.value.toLocaleString()}</>
                  ) : (
                    params.value
                  )}
                </Typography>
              </Paper>
            </Popper>
          )}
        </Box>
      ) : (
        <Box
          ref={wrapper}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sx={{
            alignItems: 'center',
            lineHeight: '24px',
            width: 1,
            height: 1,
            position: 'relative',
            display: 'flex',
          }}
        >
          <Box
            ref={cellDiv}
            sx={{
              height: 1,
              display: 'block',
              position: 'absolute',
              top: 0,
            }}
          />
          <Box
            ref={cellValue}
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {params.value}
          </Box>
          {showPopper && (
            <Popper
              open={showFullCell && anchorEl !== null}
              anchorEl={anchorEl}
              style={{ width, zIndex: 1300 }}
            >
              <Paper elevation={1} style={{ minHeight: wrapper.current.offsetHeight - 3 }}>
                <Typography variant='body2' style={{ padding: 8, wordBreak: 'break-all' }}>
                  {params.value}
                </Typography>
              </Paper>
            </Popper>
          )}
        </Box>
      )}
    </>
  );
});

GridCellExpand.propTypes = {
  params: PropTypes.any.isRequired,
  width: PropTypes.number.isRequired,
};
