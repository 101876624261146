// MUI
import Box from '@mui/material/Box';

const commonStyles = {
  borderTop: 1,
  borderColor: 'grey.300',
  margin: '25px 0',
};

export const BorderTop = () => {
  return (
    <Box>
      <Box sx={{ ...commonStyles }} />
    </Box>
  );
};
