// 注文の更新で使用する
export const SUB_ORDER_UPDATE_INPUT = {
  id: '',
  film_orders_master_id: '',
  sub_order_group_id: '',
  order_thickness: null,
  order_width: null,
  order_length: null,
  order_process: '',
  order_number: null,
  order_date: null,
  delivery_date: null,
  unit_price: 0,
  cost_price: 0,
  processing_price_value: 0,
  sub_total: 0,
  amount_money: null,
  order_house_name: '',
  order_remark: '',
  sub_order_remark: '',
  status: '',
  processing_client_name: '',
  film_products_master_id: '',
  processing_product_name: '',
  process_date: null,
  ship_date: null,
  consumption_tax: null,
  unit: '',
  weight: null,
  total_weight: null,
  outsourcing: '',
};
