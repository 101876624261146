import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import styles from 'utils/css/Print.module.css';
// MUI
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import LoadingButton from '@mui/lab/LoadingButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
// コンポーネント
import { DeliverySlipPDF } from 'components/uiParts/Pdf/DeliverySlipPDF';
import { ReceiptGoodsPDF } from 'components/uiParts/Pdf/ReceiptGoodsPDF';
import { CustomDatePicker } from 'components/uiParts/CustomDatePicker';
// 定数
import { STOCK_TABLE_COLUMNS_FOR_MODAL } from 'utils/constantsTable';
// カスタムフック
import { useCommonFunction } from 'hooks/useCommonFunction';
import { useFetchDataWithInit, useFetchData } from 'hooks/useFetchData';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const page = {
  size: 'A4 portrait',
  margin: '0mm',
};

export const ShipSlipOutFormModal = (props) => {
  const { open, setOpen, pdfDataList, handleClickPrintShipSheet } = props;
  const componentRef = useRef();
  const componentRef2 = useRef();
  const componentRef3 = useRef();

  // カスタムフック
  const { useGetToday } = useCommonFunction();

  const [selectedOrderDate, setSelectedOrderDate] = useState(useGetToday());
  const [formInputValue, setFormInputValue] = useState({
    Date: selectedOrderDate,
    Memo: '',
    ReceiptGoods: true,
    DeliverySlip: true,
    DeliverySlipPrice: false,
  });
  const formLists = [
    {
      label: '日付',
      name: 'Date',
      type: 'text',
      reauired: true,
      defaultValue: selectedOrderDate,
      multiline: false,
      rows: 1,
      create: true,
    },
    {
      label: 'メモ(納品書)',
      name: 'Memo',
      type: 'text',
      reauired: true,
      defaultValue: '',
      multiline: true,
      rows: 4,
      create: true,
    },
    {
      label: 'メモ(納品書(控))',
      name: 'MemoReserve',
      type: 'text',
      reauired: true,
      defaultValue: '',
      multiline: true,
      rows: 4,
      create: true,
    },
  ];
  const [settingDatas, setSettingDatas] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  // 設定情報を取得する
  const url = `${process.env.REACT_APP_API_URL}/FILMa_GetOtherSetting?code=${process.env.REACT_APP_API_CODE}`;
  const { data, error, isLoading } = useFetchDataWithInit(url, [{ id: 1 }]);
  useEffect(() => {
    if (!data) return;

    if (!data.code) {
      // 成功時
      setSettingDatas(data.result);
    } else {
      // 失敗時
      toggleSnack(true, 'error', '設定情報の取得に失敗しました');
    }
  }, [data]);

  // エラーの場合
  useEffect(() => {
    if (!error) return;
    toggleSnack(true, 'error', '設定情報IDの取得に失敗しました');
  }, [error]);

  //　お客様情報の取得
  const { isFetchLoading, fetchDatas, fetchFunc } = useFetchData();
  const [clientDatas, setClientDatas] = useState([]);

  useEffect(async () => {
    if (pdfDataList.length === 0) return;
    if (!Array.isArray(pdfDataList[0])) return;
    let clientFetc = await fetchFunc('Client', [{ id: pdfDataList[0][0].film_clients_master_id }]);
    if (!clientFetc) return;
    if (clientFetc.length === 0) return;

    setClientDatas(clientFetc);
  }, [pdfDataList]);

  const handleChangeForm = (e) => {
    let value = e.target.value;
    // 物品受領と納品書
    if (
      e.target.name === 'ReceiptGoods' ||
      e.target.name === 'DeliverySlip' ||
      e.target.name === 'DeliverySlipPrice'
    ) {
      value = e.target.checked;
    }
    setFormInputValue({ ...formInputValue, [e.target.name]: value });
  };

  // 日付が変更されたら
  useEffect(() => {
    if (!selectedOrderDate) return;
    setFormInputValue({ ...formInputValue, Date: selectedOrderDate });
  }, [selectedOrderDate]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <IconButton
            sx={{ position: 'absolute', right: '5px', top: '5px' }}
            color='primary'
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <>
            <div style={{ overflow: 'auto' }}>
              <div style={{ height: '50vh' }}>
                <Table sx={{ margin: '20px 0 0' }} size='small' aria-label='a dense table'>
                  <TableBody>
                    {formLists.map(
                      (data, index) =>
                        data.create && (
                          <TableRow key={index}>
                            <TableCell sx={{ width: '20%' }}>{data.label}</TableCell>
                            {data.name === 'Date' ? (
                              <TableCell name={data.name} type={data.type} sx={{ width: '80%' }}>
                                <CustomDatePicker
                                  date={selectedOrderDate}
                                  setDate={setSelectedOrderDate}
                                />
                              </TableCell>
                            ) : (
                              <TableCell name={data.name} type={data.type} sx={{ width: '80%' }}>
                                <TextField
                                  name={data.name}
                                  type={data.type}
                                  defaultValue={data.defaultValue}
                                  multiline={data.multiline}
                                  rows={data.rows}
                                  sx={{ width: '100%' }}
                                  size='small'
                                  onChange={(e) => handleChangeForm(e)}
                                />
                              </TableCell>
                            )}
                          </TableRow>
                        )
                    )}
                    <TableRow>
                      <TableCell>出力</TableCell>
                      <TableCell>
                        <FormControlLabel
                          value='end'
                          control={
                            <Checkbox
                              onClick={(e) => handleChangeForm(e)}
                              name='ReceiptGoods'
                              size='small'
                              checked={formInputValue.ReceiptGoods}
                            />
                          }
                          label='物品受領'
                          labelPlacement='end'
                        />
                        <FormControlLabel
                          value='end'
                          control={
                            <Checkbox
                              onChange={(e) => handleChangeForm(e)}
                              name='DeliverySlip'
                              size='small'
                              checked={formInputValue.DeliverySlip}
                            />
                          }
                          label='納品書('
                          labelPlacement='end'
                        />
                        <FormControlLabel
                          value='end'
                          disabled={!formInputValue.DeliverySlip}
                          control={
                            <Checkbox
                              onChange={(e) => handleChangeForm(e)}
                              name='DeliverySlipPrice'
                              size='small'
                              checked={formInputValue.DeliverySlipPrice}
                            />
                          }
                          label='金額あり)'
                          labelPlacement='end'
                          sx={{ marginLeft: '-20px' }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <div style={{ margin: '20px 0 0', textAlign: 'right' }}>
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        disabled={
                          formInputValue.ReceiptGoods || formInputValue.DeliverySlip ? false : true
                        }
                        id='print-button'
                        color='success'
                        variant='contained'
                      >
                        印刷
                      </Button>
                    )}
                    content={() => componentRef.current}
                    onBeforeGetContent={() => {
                      let clickPdfData = [];
                      pdfDataList.forEach((value) => {
                        clickPdfData = clickPdfData.concat(value);
                      })
                      handleClickPrintShipSheet(clickPdfData, 4, 5);
                    }} // ステータスを出荷済に更新する
                  />
                </div>
                <div style={{ display: 'none' }}>
                  <div ref={componentRef} className={styles}>
                    {/* 納品書(金額なし) */}
                    {formInputValue.DeliverySlip === true &&
                      formInputValue.DeliverySlipPrice === false && (
                        <article className={styles.page}>
                          <DeliverySlipPDF
                            // ref={componentRef}
                            title='納品書'
                            themeColor='#419450'
                            pdfData={pdfDataList}
                            formInputValue={formInputValue}
                            settingDatas={settingDatas}
                            isPrice={false}
                            clientDatas={clientDatas}
                          />
                        </article>
                      )}
                    {/* 納品書(金額あり) */}
                    {formInputValue.DeliverySlip === true &&
                      formInputValue.DeliverySlipPrice === true && (
                        <article className={styles.page}>
                          <DeliverySlipPDF
                            // ref={componentRef}
                            title='納品書'
                            themeColor='#419450'
                            pdfData={pdfDataList}
                            formInputValue={formInputValue}
                            settingDatas={settingDatas}
                            isPrice={true}
                            clientDatas={clientDatas}
                          />
                        </article>
                      )}
                    {/* 納品書(控え) */}
                    {formInputValue.DeliverySlip === true && (
                      <article className={styles.page}>
                        <DeliverySlipPDF
                          ref={componentRef2}
                          title='納品書(控)'
                          themeColor='#3b69a1'
                          pdfData={pdfDataList}
                          formInputValue={formInputValue}
                          settingDatas={settingDatas}
                          isPrice={true}
                          clientDatas={clientDatas}
                        />
                      </article>
                    )}
                    {/* 物品受領書 */}
                    {formInputValue.ReceiptGoods === true && (
                      <ReceiptGoodsPDF
                        ref={componentRef3}
                        title='物品受領書'
                        themeColor='#c73834'
                        pdfData={pdfDataList}
                        formInputValue={formInputValue}
                        settingDatas={settingDatas}
                        clientDatas={clientDatas}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        </Box>
      </Modal>
    </div>
  );
};

ShipSlipOutFormModal.propTypes = {
  open: PropTypes.bool.isRequired, // モーダル開く
  setOpen: PropTypes.func.isRequired, // モーダル開く
  pdfDataList: PropTypes.array.isRequired, // PDFで表示するデータ
  handleClickPrintShipSheet: PropTypes.func,
};
