import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// MUI
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
// コンポーネント
import { SubTitle } from 'components/uiParts/SubTitle';
import { BorderTop } from 'components/uiParts/BorderTop';
import { Loading } from 'components/uiParts/Loading';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '80vh',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#EBEBEB',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const StockListModal = (props) => {
  const {
    open,
    subOrderData,
    reloadPage,
    isOpenFromQRCode,
    formInputValue,
    formInputNewValue,
    addLoadingUpdateForm,
    totalLength,
    orderLength,
    setOpenSelectStockModal,
    handleChangeUpdateForm,
    handleClickUpdateRows,
    isPageReload,
    isLoading,
    handleClickDeleteStock,
    isStockListModalFormValid,
    handleClose,
    isStockSelectLoading,
    handleClickStartProcessingBtn,
  } = props;

  useEffect(() => {
    if (!isPageReload) return;
    reloadPage();
  }, [isPageReload]);

  useEffect(() => {
    if (!isOpenFromQRCode) return;
  }, [isOpenFromQRCode]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          {isOpenFromQRCode ? (
            <></>
          ) : (
            <IconButton
              sx={{ position: 'absolute', right: '5px', top: '5px' }}
              color='primary'
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          )}
          <div>
            <Box sx={{ width: '100%' }}>
              {!isOpenFromQRCode && (
                <>
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button
                        onClick={() => setOpenSelectStockModal(true)}
                        sx={{ margin: '10px 0' }}
                        variant='contained'
                        disabled={(typeof subOrderData.row !== 'undefined' ? (typeof subOrderData.row.StatusCode !== 'undefined' ? (Object.keys(subOrderData).length > 0 && subOrderData.row.StatusCode >= 4) : false ) : false) || isStockSelectLoading}
                      >
                        在庫を選択する
                      </Button>
                      <span style={{ margin: '10px 10px 0 0' }}>
                        {totalLength} / {orderLength}
                      </span>
                    </div>
                    {Object.keys(subOrderData).length > 0 && (
                      <div>
                        商品名:
                        {subOrderData.row.processing_product_name !== undefined
                          ? subOrderData.row.processing_product_name
                          : ''}
                        / 厚み:
                        {subOrderData.row.order_thickness !== undefined
                          ? subOrderData.row.order_thickness
                          : ''}
                        / 幅:
                        {subOrderData.row.order_width !== undefined ? subOrderData.row.order_width : ''} /
                        長さ:
                        {subOrderData.row.order_length !== undefined ? subOrderData.row.order_length : ''}
                      </div>
                    )}
                  </div>
                  <BorderTop />
                </>
              )}
              <div
                style={
                  isOpenFromQRCode
                    ? { overflow: 'auto', height: '55vh' }
                    : { overflow: 'auto', height: '35vh' }
                }
              >
                {isOpenFromQRCode && Object.keys(subOrderData).length > 0 && (
                  <span style={{ margin: '0 0 0 0' }}>
                    商品名:
                    {subOrderData.processing_product_name !== undefined ? subOrderData.processing_product_name : ''}/
                    厚み:
                    {subOrderData.order_thickness !== undefined ? subOrderData.order_thickness : ''}/ 幅:
                    {subOrderData.order_width !== undefined ? subOrderData.order_width : ''} / 長さ:
                    {subOrderData.order_length !== undefined ? subOrderData.order_length : ''}
                  </span>
                )}
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                  {formInputValue.length > 0 &&
                    formInputValue.map((value, index) => (
                      <Grid key={index} item xs={12} sm={6} md={4}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            position: 'relative',
                            bottom: '-5px',
                          }}
                        >
                          <SubTitle hideBorder title={'使用在庫' + (index + 1)} />
                          {!isOpenFromQRCode && (
                            <IconButton
                              onClick={() => handleClickDeleteStock(value.id)}
                              style={{ position: 'relative', top: '-8px' }}
                              aria-label='delete'
                              color='primary'
                              disabled={(typeof subOrderData.row !== 'undefined' ? (typeof subOrderData.row.StatusCode !== 'undefined' ? (Object.keys(subOrderData).length > 0 && subOrderData.row.StatusCode >= 4) : false ) : false)}
                            >
                              <CloseIcon />
                            </IconButton>
                          )}
                        </div>
                        <Item>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  sx={{ width: '90px', padding: '5px 0', border: 'none' }}
                                  component='th'
                                  scope='row'
                                >
                                  在庫ID
                                </TableCell>
                                <TableCell sx={{ padding: '5px 0', border: 'none' }} align='right'>
                                  {value.film_stocks_master_id !== undefined && value.film_stocks_master_id}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={{ padding: '5px 0', border: 'none' }}
                                  component='th'
                                  scope='row'
                                >
                                  商品名
                                </TableCell>
                                <TableCell sx={{ padding: '5px 0', border: 'none' }} align='right'>
                                  {value.ProductName !== undefined && value.ProductName}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={{ padding: '5px 0', border: 'none' }}
                                  component='th'
                                  scope='row'
                                >
                                  厚み
                                </TableCell>
                                <TableCell sx={{ padding: '5px 0', border: 'none' }} align='right'>
                                  {value.thickness !== undefined && value.thickness}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={{ padding: '5px 0', border: 'none' }}
                                  component='th'
                                  scope='row'
                                >
                                  幅
                                </TableCell>
                                <TableCell sx={{ padding: '5px 0', border: 'none' }} align='right'>
                                  {value.width !== undefined && value.width}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={{ padding: '5px 0', border: 'none' }}
                                  component='th'
                                  scope='row'
                                >
                                  残長論理
                                </TableCell>
                                <TableCell sx={{ padding: '5px 0', border: 'none' }} align='right'>
                                  {value.length_lo !== undefined && value.length_lo}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={{ padding: '5px 0', border: 'none' }}
                                  component='th'
                                  scope='row'
                                >
                                  使用する長さ
                                </TableCell>
                                <TableCell sx={{ padding: '5px 0', border: 'none' }} align='right'>
                                  <TextField
                                    onChange={(e) => handleChangeUpdateForm(e, 'number', index)}
                                    name='length_us'
                                    sx={{ width: '100%', padding: '5px 0' }}
                                    size='small'
                                    value={value.length_us !== undefined && value.length_us}
                                    InputProps={{ inputProps: { style: { textAlign: 'right' } } }}
                                    disabled={(typeof subOrderData.row !== 'undefined' ? (typeof subOrderData.row.StatusCode !== 'undefined' ? (Object.keys(subOrderData).length > 0 && subOrderData.row.StatusCode >= 4) : false ) : false) || (isOpenFromQRCode ? subOrderData.StatusCode >= 4 : false)}
                                  />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Item>
                      </Grid>
                    ))}
                  {formInputNewValue.length > 0 &&
                    formInputNewValue.map((value, index) => (
                      <Grid key={index} item xs={12} sm={6} md={4}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            position: 'relative',
                            bottom: '-5px',
                          }}
                        >
                          <SubTitle
                            hideBorder
                            title={'使用在庫' + (formInputValue.length + index + 1)}
                          />
                          <IconButton
                            onClick={() => handleClickDeleteStock(value.id)}
                            style={{ position: 'relative', top: '-8px' }}
                            aria-label='delete'
                            color='primary'
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                        <Item>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  sx={{ width: '90px', padding: '5px 0', border: 'none' }}
                                  component='th'
                                  scope='row'
                                >
                                  在庫ID
                                </TableCell>
                                <TableCell sx={{ padding: '5px 0', border: 'none' }} align='right'>
                                  {value.film_stocks_master_id !== undefined && value.film_stocks_master_id}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={{ padding: '5px 0', border: 'none' }}
                                  component='th'
                                  scope='row'
                                >
                                  商品名
                                </TableCell>
                                <TableCell sx={{ padding: '5px 0', border: 'none' }} align='right'>
                                  {value.ProductName !== undefined && value.ProductName}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={{ padding: '5px 0', border: 'none' }}
                                  component='th'
                                  scope='row'
                                >
                                  厚み
                                </TableCell>
                                <TableCell sx={{ padding: '5px 0', border: 'none' }} align='right'>
                                  {value.thickness !== undefined && value.thickness}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={{ padding: '5px 0', border: 'none' }}
                                  component='th'
                                  scope='row'
                                >
                                  幅
                                </TableCell>
                                <TableCell sx={{ padding: '5px 0', border: 'none' }} align='right'>
                                  {value.width !== undefined && value.width}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={{ padding: '5px 0', border: 'none' }}
                                  component='th'
                                  scope='row'
                                >
                                  残長論理
                                </TableCell>
                                <TableCell sx={{ padding: '5px 0', border: 'none' }} align='right'>
                                  {value.length_lo !== undefined && value.length_lo}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  sx={{ padding: '5px 0', border: 'none' }}
                                  component='th'
                                  scope='row'
                                >
                                  使用する長さ
                                </TableCell>
                                <TableCell sx={{ padding: '5px 0', border: 'none' }} align='right'>
                                  <TextField
                                    onChange={(e) =>
                                      handleChangeUpdateForm(e, 'number', index, true)
                                    }
                                    name='length_us'
                                    sx={{ width: '100%', padding: '5px 0' }}
                                    size='small'
                                    value={value.length_us !== undefined && value.length_us}
                                    InputProps={{ inputProps: { style: { textAlign: 'right' } } }}
                                  />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Item>
                      </Grid>
                    ))}
                </Grid>
              </div>
              <BorderTop />

              <div style={{ textAlign: 'right' }}>
                {isOpenFromQRCode ? (
                  <>
                    {subOrderData.StatusCode <= 2 && (
                      <LoadingButton
                        onClick={() => handleClickStartProcessingBtn(subOrderData, 2, 3)}
                        variant='contained'
                        loading={addLoadingUpdateForm}
                      >
                        加工開始
                      </LoadingButton>
                    )}
                    {subOrderData.StatusCode > 2 && (
                      <LoadingButton
                        onClick={() => handleClickStartProcessingBtn(subOrderData, 3, 4)}
                        variant='contained'
                        loading={addLoadingUpdateForm}
                        disabled={subOrderData.StatusCode >= 4}
                      >
                        加工完了
                      </LoadingButton>
                    )}
                  </>
                ) : (
                  <LoadingButton
                    // disabled={totalLength < orderLength} // totalLengthがorderLength以上なら登録ボタン押下可
                    disabled={(typeof subOrderData.row !== 'undefined' ? (typeof subOrderData.row.StatusCode !== 'undefined' ? (Object.keys(subOrderData).length > 0 && subOrderData.row.StatusCode >= 4) : false ) : false) || isStockSelectLoading}
                    onClick={() => handleClickUpdateRows()}
                    variant='contained'
                    loading={addLoadingUpdateForm}
                  >
                    登録
                  </LoadingButton>
                )}
              </div>
            </Box>
            <Loading isLoading={isLoading} />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

StockListModal.propTypes = {
  open: PropTypes.bool.isRequired, // モーダル開く
  subOrderId: PropTypes.string,
  subOrderData: PropTypes.object,
  reloadPage: PropTypes.func,
  isOpenFromQRCode: PropTypes.bool, // QRコードからアクセスして開く時
  formInputValue: PropTypes.array.isRequired,
  formInputNewValue: PropTypes.array.isRequired,
  addLoadingUpdateForm: PropTypes.bool.isRequired,
  totalLength: PropTypes.number.isRequired,
  orderLength: PropTypes.number,
  setOpenSelectStockModal: PropTypes.func.isRequired,
  handleChangeUpdateForm: PropTypes.func.isRequired,
  handleClickUpdateRows: PropTypes.func.isRequired,
  isPageReload: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleClickDeleteStock: PropTypes.func.isRequired,
  isStockListModalFormValid: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isStockSelectLoading: PropTypes.bool.isRequired,
  handleClickStartProcessingBtn: PropTypes.func.isRequired,
};
