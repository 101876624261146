import React, { useState } from 'react';

const initialParams = [
  {
    status: '新規',
  },
  {
    status: '加工指示書出力済',
  },
  {
    status: '在庫割当済',
  },
  {
    status: '加工作業中',
  },
  {
    status: '加工済',
  },
]; // デフォルト

/**
 * 一覧で出荷済を含むを押したときに使う
 */
export const useShipped = () => {
  const [params, setParams] = useState(initialParams);

  const handleClickShipped = () => {
    let array = [];
    if (params.length === 0) {
      array = initialParams;
    }
    setParams(array);
  };

  return {
    params,
    handleClickShipped,
  };
};
