import React from 'react';
import { UpdateForm } from 'components/uiParts/Form/UpdateForm';
import { SubTitle } from 'components/uiParts/SubTitle';
import { Breadcrumb } from 'components/uiParts/Breadcrumb';
// 定数ファイル
import { CUSTOMER_CATEGORY_COLUMNS } from 'utils/constantsTable';
// MUI
import Button from '@mui/material/Button';

export const CustomerCategory1DetailPage = () => {
  const breadcrumbList = [
    { name: 'ダッシュボード', href: '/' },
    { name: '得意先区分1一覧', href: '/customerCategory1/list' },
    { name: '得意先区分1詳細' },
  ];
  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SubTitle title='得意先区分1詳細' />
        <Button
          href={location.origin + '/customerCategory1/list'}
          variant='contained'
          color='inherit'
        >
          戻る
        </Button>
      </div>
      <UpdateForm
        columns={CUSTOMER_CATEGORY_COLUMNS}
        api='CustomerCategory1'
        redirectUrl='/customerCategory1/list'
      />
    </>
  );
};
