import React from 'react';
// コンポーネント
import { DataTable } from 'components/uiParts/DataGrid/DataTable';
import { SelectStocksConfModal } from 'components/uiParts/Modal/SelectStocksConfModal';
import { SelectStockModal } from 'components/uiParts/Modal/SelectStockModal';
import { StockListModal } from 'components/uiParts/Modal/StockListModal';
// 定数
import { SHIP_LIST_TABLE_COLUMNS, PROCESSING_LIST_TABLE_COLUMNS } from 'utils/constantsTable';
// カスタムフック
import { useUpdateOrderForm } from 'hooks/useOrderForm';

export const DashboardPage = () => {
  const {
    handleClickStartProcessingBtn,
    openProcessingSheet,
    setOpenProcessingSheet,
    printProcessingSheet,
    handleClickProcessingSheet,
    openSelectStocksConfModal,
    setOpenSelectStocksConfModal,
    handleClickUpdateRows,
    selectedStockId,
    addLoadingForm,
    handleClickOpenSelectStock,
    handleClickOpenSelectStocks,
    openStockModal,
    isViewStockSelectList,
    subOrderData,
    reloadPage,
    isOpenFromQRCode,
    setIsViewStockSelectList,
    formInputValue,
    formInputNewValue,
    addLoadingUpdateForm,
    totalLength,
    orderLength,
    setOpenSelectStockModal,
    handleChangeUpdateForm,
    isCloseModal,
    setIsCloseModal,
    isPageReload,
    isLoading,
    handleClickDeleteStock,
    isStockListModalFormValid,
    handleCloseStockListModal,
    openSelectStockModal,
    handleClickSelectStock,
    handleCloseStockModal,
    isAllStockBtn,
    selectedSubOrderLength,
    setSelectedSubOrderRows,
    selectedSubOrderRows,
    isClickSelectStocksBtn,
    selectedStockIds,
    isStockSelectLoading,
    handleClickPrintShipSheet,
    setSubOrderGRows,
    isClickOpenSelectStocks,
  } = useUpdateOrderForm();

  return (
    <>
      <DataTable
        title='加工一覧'
        columns={PROCESSING_LIST_TABLE_COLUMNS}
        api='SortedSubOrderForUser'
        params={[{ status: '在庫割当済' }, { status: '新規' }, { status: '加工指示書出力済' }]}
        vh='40vh'
        checkboxSelection={true}
        isPOutputBtn
        handleClickProcessingSheet={handleClickProcessingSheet}
        openProcessingSheet={openProcessingSheet}
        setOpenProcessingSheet={setOpenProcessingSheet}
        printProcessingSheet={printProcessingSheet}
        isSelectStocksBtn
        handleClickOpenSelectStock={handleClickOpenSelectStock}
        handleClickOpenSelectStocks={handleClickOpenSelectStocks}
        setSelectedRows={setSelectedSubOrderRows}
        isPageReload={isPageReload}
        isStockBtn
        localStorageKeyName='Processing'
      />
      <div style={{ height: '130px' }}></div>
      <DataTable
        title='出荷一覧'
        columns={SHIP_LIST_TABLE_COLUMNS}
        api='SubOrderForUser'
        vh='40vh'
        isShipList
        checkboxSelection={true}
        isMIOutputBtn
        handleClickPrintShipSheet={handleClickPrintShipSheet}
        setSubOrderGRows={setSubOrderGRows}
      />
      {/* 在庫リストのモーダル */}
      <StockListModal
        open={openStockModal}
        isViewStockSelectList={isViewStockSelectList}
        subOrderData={subOrderData}
        reloadPage={reloadPage}
        isOpenFromQRCode={isOpenFromQRCode}
        setIsViewStockSelectList={setIsViewStockSelectList}
        formInputValue={formInputValue}
        formInputNewValue={formInputNewValue}
        addLoadingUpdateForm={addLoadingUpdateForm}
        totalLength={totalLength}
        orderLength={orderLength}
        setOpenSelectStockModal={setOpenSelectStockModal}
        handleChangeUpdateForm={handleChangeUpdateForm}
        handleClickUpdateRows={handleClickUpdateRows}
        isCloseModal={isCloseModal}
        setIsCloseModal={setIsCloseModal}
        isPageReload={isPageReload}
        isLoading={isLoading}
        handleClickDeleteStock={handleClickDeleteStock}
        isStockListModalFormValid={isStockListModalFormValid}
        handleClose={handleCloseStockListModal}
        isStockSelectLoading={isStockSelectLoading}
        handleClickStartProcessingBtn={handleClickStartProcessingBtn}
      />
      {/* 在庫選択のモーダル */}
      <SelectStockModal
        open={openSelectStockModal}
        subOrderData={subOrderData}
        handleClickSelectStock={handleClickSelectStock}
        handleClose={handleCloseStockModal}
        isAllStockBtn={isAllStockBtn}
        selectedSubOrderLength={selectedSubOrderLength}
        countCheckbox={selectedSubOrderRows.length}
        isClickSelectStocksBtn={isClickSelectStocksBtn}
        selectedStockIds={selectedStockIds}
        isClickOpenSelectStocks={isClickOpenSelectStocks}
      />
      {/* 一括在庫在庫の確認モーダル */}
      <SelectStocksConfModal
        open={openSelectStocksConfModal}
        setOpen={setOpenSelectStocksConfModal}
        handleClickUpdateRows={handleClickUpdateRows}
        selectedStockId={selectedStockId}
        addLoadingForm={addLoadingForm}
      />
    </>
  );
};
