import React, { useCallback, useState } from 'react';

/**
 * 商品選択モーダルで使用するフック
 */
export const useSelectProductModal = () => {
  const [openProductModal, setOpenProductModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedProductRow, setSelectedProductRow] = useState({});

  /** 商品を選択する */
  const handleClickSelectProduct = useCallback((params) => {
    setSelectedProductRow(params.row);
    setSelectedProductId(params.row.id);
    setOpenProductModal(false);
  }, []);

  return {
    openProductModal,
    selectedProductId,
    setSelectedProductId,
    setOpenProductModal,
    handleClickSelectProduct,
    selectedProductRow,
    setSelectedProductRow,
  };
};
