import React from 'react';
import { Link } from 'react-router-dom';

export const NotFound = () => {
  return (
    <>
      <h1>404</h1>
      <h3>お探しのページは見つかりませんでした。</h3>
      <Link to={{ pathname: '/' }}>ホームに戻る</Link>
    </>
  );
};
