import React from 'react';
import { UpdateForm } from 'components/uiParts/Form/UpdateForm';
import { SubTitle } from 'components/uiParts/SubTitle';
import { Breadcrumb } from 'components/uiParts/Breadcrumb';
// 定数ファイル
import { OTHER_SETTING_COLUMNS } from 'utils/constantsTable';

export const OtherSettingDetailPage = () => {
  const breadcrumbList = [{ name: 'ダッシュボード', href: '/' }, { name: 'その他設定詳細' }];
  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <SubTitle title='その他設定詳細' />
      <UpdateForm
        columns={OTHER_SETTING_COLUMNS}
        api='OtherSetting'
        redirectUrl='/otherSetting/detail?id=1'
      />
    </>
  );
};
