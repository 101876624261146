import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
// MUI
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
// コンポーネント
import { ProcessingSheetPDF } from 'components/uiParts/Pdf/ProcessingSheetPDF';
// カスタムフック
import { useFetchDataWithInit } from 'hooks/useFetchData';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 810,
  bgcolor: 'background.paper',
  p: 2,
};

/**
 * PDF保存の処理はreact-to-printを使用
 * @see https://www.npmjs.com/package/react-to-print
 */
export const ProcessingSheetModal = (props) => {
  const { open, setOpen, pdfDatas, printProcessingSheet } = props;
  const componentRef = useRef();
  const handleClose = () => setOpen(false);
  const [settingDatas, setSettingDatas] = useState([]);

  // 設定情報を取得する
  const url = `${process.env.REACT_APP_API_URL}/FILMa_GetOtherSetting?code=${process.env.REACT_APP_API_CODE}`;
  const { data, error, isLoading } = useFetchDataWithInit(url, [{ id: '1' }]);
  useEffect(() => {
    if (!data) return;

    if (!data.code) {
      // 成功時
      setSettingDatas(data.result);
    } else {
      // 失敗時
      toggleSnack(true, 'error', '設定情報の取得に失敗しました');
    }
  }, [data]);

  // エラーの場合
  useEffect(() => {
    if (!error) return;
    toggleSnack(true, 'error', '設定情報IDの取得に失敗しました');
  }, [error]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <IconButton
            sx={{ position: 'absolute', right: '5px', top: '5px' }}
            color='primary'
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <div style={{ overflow: 'auto', height: '80vh' }}>
            <ProcessingSheetPDF
              ref={componentRef}
              pdfDatas={pdfDatas}
              settingDatas={settingDatas}
            />
          </div>
          <div style={{ margin: '20px 0 0', textAlign: 'right' }}>
            <ReactToPrint
              trigger={() => (
                <Button color='success' variant='contained'>
                  印刷
                </Button>
              )}
              content={() => componentRef.current}
              onBeforeGetContent={() => printProcessingSheet(pdfDatas)}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

ProcessingSheetModal.propTypes = {
  open: PropTypes.bool.isRequired, // モーダル開く
  setOpen: PropTypes.func.isRequired, // モーダル開く
  pdfDatas: PropTypes.array.isRequired, // 印刷用のデータ
  printProcessingSheet: PropTypes.func.isRequired, // 印刷を押したときの関数
};
