import React from 'react';
import { STOCK_TABLE_COLUMNS } from 'utils/constantsTable';
// コンポーネント
import { SubTitle } from 'components/uiParts/SubTitle';
import { UpdateStockForm } from 'components/uiParts/Form/UpdateStockForm';
import { Breadcrumb } from 'components/uiParts/Breadcrumb';
// MUI
import Button from '@mui/material/Button';

export const StockDetailPage = () => {
  const breadcrumbList = [
    { name: 'ダッシュボード', href: '/' },
    { name: '在庫一覧', href: '/stock/list' },
    { name: '在庫詳細' },
  ];
  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SubTitle title='在庫詳細' />
        <Button href={location.origin + '/stock/list'} variant='contained' color='inherit'>
          戻る
        </Button>
      </div>
      <UpdateStockForm api='StockForUser' columns={STOCK_TABLE_COLUMNS} redirectUrl='/stock/list' />
    </>
  );
};
