import React, { useCallback, useState, useEffect } from 'react';
// カスタムフック
import { useFetchDataWithInit, useFetchData } from 'hooks/useFetchData';
import { useSnackBar } from 'hooks/useSnackBar';
import { useGetDetailId } from 'hooks/useGetDetailId';
import { useCommonFunction } from 'hooks/useCommonFunction';

/**
 * 登録フォームを使う際のカスタムフック
 * @param {String} getApi 登録API名
 */
export const useCreateForm = (api, columns, input, redirectUrl) => {
  const [isCreateFormValid, setIsCreateFormValid] = useState(true);
  const [formInputValue, setFormInputValue] = useState(input);
  const [addLoadingForm, setAddLoadingForm] = useState(false);
  const [isCreated, setIsCreated] = useState(false);

  // カスタムフック
  const { onlyNumbersAndPoint } = useCommonFunction();
  const { toggleSnack } = useSnackBar();

  /**
   * True Falseの文字列をBooleanに変換
   */
  const toBoolean = (data) => {
    return String(data).toLowerCase() === 'true';
  };

  /** 登録時のchangeハンドラー */
  const handleChangeCreateForm = (e, type) => {
    if (e.target.value === undefined) return;
    let value = e.target.value;
    switch (type) {
      case 'number':
        value = onlyNumbersAndPoint(value);
        break;
      case 'bool':
        value = toBoolean(value);
        break;
      default:
        break;
    }
    setFormInputValue({ ...formInputValue, [e.target.name]: value });
    setIsCreateFormValid(false);
  };

  /** 登録する */
  const handleClickCreateForm = async (table) => {
    setAddLoadingForm(true); // ローディング表示
    setIsCreated(false);

    const copyInput = { ...formInputValue };

    let isRequiredItem = true;
    for (let key in columns) {
      if (!columns[key].create || columns[key].create === undefined) continue;

      // 必須項目が入力されているかチェックする
      if (columns[key].reauired) {
        if (
          copyInput[columns[key].field] === null ||
          copyInput[columns[key].field] === undefined ||
          copyInput[columns[key].field] === ''
        ) {
          isRequiredItem = false;
        }
      }

      // 登録する前に型を設定する
      if (
        copyInput[columns[key].field] !== undefined &&
        columns[key].formType &&
        columns[key].formType === 'number'
      ) {
        copyInput[columns[key].field] = Number(copyInput[columns[key].field]);
      }
    }

    // 必須項目の入力がない場合はエラー表示
    if (!isRequiredItem) {
      setAddLoadingForm(false); // ローディング非表示
      toggleSnack(true, 'error', '必須項目をご入力ください');
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}/FILMa_Add${api}?code=${process.env.REACT_APP_API_CODE}`;

    let response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify([copyInput]),
    })
      .then((response) => {
        setAddLoadingForm(false); // ローディング非表示
        return response.json();
      })
      .catch((error) => {
        setAddLoadingForm(false); // ローディング非表示
        return false;
      });

    let status = 'success';
    let message = '登録が完了しました';
    if (response === false || response.code) {
      // 失敗時
      status = 'error';
      message = '登録に失敗しました' + ' FILMa_Add' + table;
    } else {
      // 成功時
      setFormInputValue({});
      setIsCreateFormValid(true);
      window.location.replace(location.origin + redirectUrl); // 一覧へリダイレクト
    }

    // エラーコードがあれば足す
    if (response.code) {
      message = message + '[' + response.code + ']';
    }

    toggleSnack(true, status, message);
  };

  return {
    isCreateFormValid, // 登録フォームのボタンを押せるか
    formInputValue, // 登録フォームのinput
    addLoadingForm, // ローディング
    isCreated, // 登録が行われたか
    setFormInputValue, // 登録フォームのinputを作る
    handleChangeCreateForm, // 登録フォームのチェンジメソッド
    handleClickCreateForm, // 登録を行うメソッド
  };
};

/**
 * 更新フォームを使う際のカスタムフック
 * @param {String} getApi 更新後にGETする際のAPI名
 * @param {String} getParams 更新後にGETする際のinput
 */
export const useUpdateForm = (api, columns, redirectUrl) => {
  const [formInputValue, setFormInputValue] = useState([]); // データ取得後のデータ
  const [isFormValid, setIsFormValid] = useState(true);
  const [addLoadingUpdateForm, setAddLoadingUpdateForm] = useState(false);
  // カスタムフック
  const { onlyNumbersAndPoint } = useCommonFunction();
  const { toggleSnack } = useSnackBar();

  // 詳細読み込み用URL
  const getUrl = `${process.env.REACT_APP_API_URL}/FILMa_Get${api}?code=${process.env.REACT_APP_API_CODE}`;
  // カスタムフック
  const { id } = useGetDetailId();
  const { data, error, isLoading } = useFetchDataWithInit(getUrl, [{ id: id }]);

  useEffect(() => {
    if (!data) return;
    let status = 'success';
    let message = 'ページの読み込みに失敗しました';

    if (!data.code) {
      // 成功時
      setFormInputValue(data.result);
    } else {
      // 失敗時
      status = 'error';
      toggleSnack(true, status, message);
      alert(message);
    }
  }, [data]);

  // エラーの場合
  useEffect(() => {
    if (!error) return;
    toggleSnack(true, 'error', 'ページの読み込みに失敗しました');
    alert('ページの読み込みに失敗しました');
  }, [error]);

  /**
   * True Falseの文字列をBooleanに変換
   */
  const toBoolean = (data) => {
    return String(data).toLowerCase() === 'true';
  };

  /** 更新入力時のchangeハンドラー */
  const handleChangeUpdateForm = (e, type) => {
    if (e.target.value === undefined) return;
    let value = e.target.value;
    const copyInput = [...formInputValue];
    // typeに応じて型を指定する
    switch (type) {
      case 'number':
        value = onlyNumbersAndPoint(e.target.value);
        break;
      case 'bool':
        value = toBoolean(e.target.value);
        break;
      default:
        break;
    }
    copyInput[0][e.target.name] = value;
    setFormInputValue(copyInput);
    setIsFormValid(false);
  };

  /** 更新する */
  const handleClickUpdateRows = async () => {
    setAddLoadingUpdateForm(true); // ローディング表示

    const copyInput = [...formInputValue];
    // 更新する前に型を設定する
    for (let i = 0; i < columns.length; i++) {
      if (
        copyInput[0][columns[i].field] !== undefined &&
        columns[i].formType &&
        columns[i].formType === 'number'
      ) {
        copyInput[0][columns[i].field] = Number(copyInput[0][columns[i].field]);
      }
    }

    const url = `${process.env.REACT_APP_API_URL}/FILMa_Update${api}?code=${process.env.REACT_APP_API_CODE}`;

    let response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(copyInput),
    })
      .then((response) => {
        setAddLoadingUpdateForm(false); // ローディング非表示
        return response.json();
      })
      .catch((error) => {
        setAddLoadingUpdateForm(false);
        return false;
      });

    let status = 'success';
    let message = '更新が完了しました';
    if (response === false || response.code) {
      // 失敗時
      status = 'error';
      message = '更新に失敗しました' + ' FILMa_Update' + api;
    } else {
      // 成功時
      setFormInputValue({});
      setIsFormValid(true);
      window.location.replace(location.origin + redirectUrl); // 一覧へリダイレクト
    }

    // エラーコードがあれば足す
    if (response.code) {
      message = message + '[' + response.code + ']';
    }

    toggleSnack(true, status, message);
    alert(message);
  };

  return {
    formInputValue,
    isFormValid, // 更新フォームのボタンを押せるか
    addLoadingUpdateForm, // ローディング
    handleChangeUpdateForm, // 更新フォームのチェンジメソッド
    handleClickUpdateRows, // 更新を行うメソッド
  };
};

/**
 * 削除フォームを使う際のカスタムフック
 * @param {String} getApi 削除後にGETする際のAPI名
 * @param {String} getParams 削除後にGETする際のinput
 */
export const useDeleteForm = (getApi, getParams) => {
  const [openDeleteForm, setOpenDeleteForm] = useState(false);
  const [deleteIds, setDeleteIds] = useState([]);
  const [deleteTableList, setDeleteTableList] = useState([]);
  const [addLoadingDeleteForm, setAddLoadingDeleteForm] = useState(false);
  const [loadinDelete, SetLoadinDelete] = useState(false);
  // 再読み込み用URL
  const getUrl = `${process.env.REACT_APP_API_URL}/FILMa_Get${getApi}?code=${process.env.REACT_APP_API_CODE}`;

  // カスタムフック
  const { toggleSnack } = useSnackBar();
  const { reacquireData } = useFetchDataWithInit(getUrl, getParams);
  const { isFetchLoading, fetchDatas, fetchFunc } = useFetchData();

  /** 削除フォームを開く */
  const handleClickOpenDeleteForm = useCallback((ids) => {
    setOpenDeleteForm(true);
    if (Array.isArray(ids)) {
      setDeleteIds(ids);
    } else {
      setDeleteIds([ids]);
    }
  }, []);

  /**
   * 削除フォームを開く（複数テーブル(現状は2つまで可)削除用）
   * @param String id 一つ目のテーブル削除用のID
   * @param Array tableList 複数テーブル(現状は2つまで可)の名前が入る
   * @param String deleteTablesGetProperty 二つ目のテーブル削除用のIDを取得する際のプロパティ
   */
  const handleClickOpenDeleteTablesForm = useCallback((id, tableList, deleteTablesGetProperty) => {
    if (tableList.length <= 1) return;
    if (!deleteTablesGetProperty) return;

    setOpenDeleteForm(true);
    setDeleteIds([id]);

    // 複数テーブル(現状は2つまで可)削除用にデータを取得
    fetchFunc(tableList[1], [{ [deleteTablesGetProperty]: id }]);

    // 複数テーブル(現状は2つまで可)削除用にinputを用意する
    setDeleteTableList([
      {
        table: tableList[0],
        ids: [id],
      },
      {
        table: tableList[1],
        ids: [], // 2つ目のidsはfetchFuncするので次のuseEffect内で行う
      },
    ]);
  }, []);

  // 複数テーブル(現状は2つまで可)削除用のinputを作成
  useEffect(() => {
    if (!deleteTableList.length || !fetchDatas.length || fetchDatas[0] === null) return;

    // 複数テーブル(現状は2つまで可)のidのリストを作成
    const secondIds = [];
    for (let i = 0; i < fetchDatas.length; i++) {
      secondIds.push(fetchDatas[i].id);
    }

    let copyLists = JSON.parse(JSON.stringify(deleteTableList));
    // 複数テーブル(現状は2つまで可)のidをinputに追加する
    copyLists[1].ids = secondIds; // 現状は2つまでの削除しか対応していないので固定で1とする

    setDeleteTableList(copyLists);
  }, [fetchDatas]);

  /** 削除フォームを閉じる */
  const handleCloseDeleteForm = useCallback(() => {
    setOpenDeleteForm(false);
  }, []);

  /** 削除する */
  const deleteRows = useCallback(async (table, ids) => {
    setAddLoadingDeleteForm(true); // ローディング表示
    const url = `${process.env.REACT_APP_API_URL}/FILMa_Delete${table}?code=${process.env.REACT_APP_API_CODE}`;
    const data = [];
    for (let i = 0; i < ids.length; i++) {
      data.push({ id: ids[i] });
    }

    let response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((response) => {
        setAddLoadingDeleteForm(false); // ローディング非表示
        return response.json();
      })
      .catch((error) => {
        setAddLoadingDeleteForm(false); // ローディング非表示
        return false;
      });

    let status = 'success';
    let message = '削除が完了しました';
    if (response === false || response.code) {
      // 失敗時
      status = 'error';
      message = '削除に失敗しました' + ' FILMa_Delete' + table;
    } else {
      // 成功時
      setDeleteIds([]);
      setOpenDeleteForm(false);
      reacquireData(); // データ再読み込み
    }

    // エラーコードがあれば足す
    if (response.code) {
      message = message + '[' + response.code + ']';
    }

    toggleSnack(true, status, message);
  }, []);

  /**
   * 複数テーブル(現状は2つまで可)削除する
   * @param Array list
   */
  const deleteTables = useCallback(async () => {
    if (!deleteTableList.length) return;
    SetLoadinDelete(true);
    const url = `${process.env.REACT_APP_API_URL}/FILMa_DeleteOrderToStockSelect?code=${process.env.REACT_APP_API_CODE}`;

    let response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(deleteTableList[0]['ids']),
    })
      .then((response) => {
        setAddLoadingDeleteForm(false); // ローディング非表示
        return response.json();
      })
      .catch((error) => {
        setAddLoadingDeleteForm(false); // ローディング非表示
        return false;
      });

    let status = 'success';
    let message = '削除が完了しました';
    if (response === false || response.code) {
      // 失敗時
      status = 'error';
      message = '削除に失敗しました。画面を更新してください。';
      SetLoadinDelete(false);
    } else {
      // 成功時
      setDeleteIds([]);
      SetLoadinDelete(false);
      setOpenDeleteForm(false);
      reacquireData(); // データ再読み込み
    }

    // エラーコードがあれば足す
    if (response.code) {
      message = message + '[' + response.code + ']';
    }

    toggleSnack(true, status, message);
  }, [deleteTableList]);

  /** 削除フォームを閉じる */
  useEffect(() => {
    setOpenDeleteForm(false);
  }, []);

  return {
    openDeleteForm, // 削除フォームを開く
    deleteIds, // 削除するID
    addLoadingDeleteForm, // ローディング
    isFetchLoading, // ローディング
    setDeleteIds, // 削除するIDのリストを作る
    handleClickOpenDeleteForm, // 削除フォームを開くメソッド
    handleClickOpenDeleteTablesForm, // 削除フォーム(複数テーブル(現状は2つまで可)削除用)を開くメソッド
    handleCloseDeleteForm, // 削除フォームを閉じるメソッド
    deleteRows, // 削除を行うメソッド
    deleteTables, // 複数テーブル(現状は2つまで可)削除する場合に使う
    loadinDelete, // 削除中のボタンロード用
  };
};
