import React, { useState, useEffect, useCallback } from 'react';
// カスタムフック
import { useFetchDataWithInit } from 'hooks/useFetchData';
import { useSnackBar } from 'hooks/useSnackBar';
import { useGetFormType } from 'hooks/useGetFormType';

export const useDataTable = (api, params, isReacquireData) => {
  const [rows, setRows] = useState([]); // データ取得後の列データ
  const [retainRows, setRetainRows] = useState([]); // 一回目のキーワード検索結果を保持する列データ
  const [retainSecondRows, setRetainSecondRows] = useState([]); // 二回目のキーワード検索結果を保持する列データ
  const [newRows, setNewRows] = useState([]); //フィルタリングした後の列データ

  const [searchText, setSearchText] = useState(''); // キーワード検索文
  const [searchSecondText, setSearchSecondText] = useState(''); // 二つ目のキーワード検索文
  const [searchThirdText, setSearchThirdText] = useState(''); // 三つ目のキーワード検索文

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [initialRows, setInitialRows] = useState([]); // 初期データの保持用

  const url = `${process.env.REACT_APP_API_URL}/FILMa_Get${api}?code=${process.env.REACT_APP_API_CODE}`;

  // カスタムフック
  const { data, error, isLoading, reacquireData } = useFetchDataWithInit(url, params);
  const { toggleSnack } = useSnackBar();

  useEffect(() => {
    if (!data) return;

    if (!data.code) {
      setRows(data.result);

      // データが無いとき
      if (data.result.length === 0) {
        toggleSnack(true, 'info', 'データの登録がありません');
      }
    } else {
      // 失敗時
      toggleSnack(true, 'error', 'ページの取得に失敗しました');
    }
  }, [data]);

  // エラーの場合
  useEffect(() => {
    if (!error) return;
    toggleSnack(true, 'error', 'ページの読み込みに失敗しました');
  }, [error]);

  useEffect(() => {
    const newValue = [...rows];

    setInitialRows(newValue); // 初期データを保持
    setNewRows(rows);
  }, [rows]);

  // 再読み込み
  useEffect(() => {
    if (!isReacquireData) return;
    reacquireData();
  }, [isReacquireData]);

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  };

  /** キーワード検索 */
  const requestSearch = useCallback(
    (searchValue) => {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = rows.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field] && row[field].toString());
        });
      });
      setRetainRows(filteredRows);
      setNewRows(filteredRows);
    },
    [rows]
  );

  /** 2つ目のキーワード検索 */
  const requestSecondSearch = useCallback(
    (searchValue) => {
      setSearchSecondText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = retainRows.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field] && row[field].toString());
        });
      });
      setRetainSecondRows(filteredRows);
      setNewRows(filteredRows);
    },
    [retainRows]
  );

  /** 3つ目のキーワード検索 */
  const requestThirdSearch = useCallback(
    (searchValue) => {
      setSearchThirdText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = retainSecondRows.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field] && row[field].toString());
        });
      });
      setNewRows(filteredRows);
    },
    [retainSecondRows]
  );

  /** セルを選択したら */
  const handleClickSelectedRows = useCallback((id) => {
    setSelectedRowIds(id);
  }, []);

  // 選択中の行のデータをまとめる
  useEffect(() => {
    if (!selectedRowIds.length) return;

    const list = [];
    for (let i = 0; i < selectedRowIds.length; i++) {
      const copyNewValueIdx = newRows.findIndex((d) => d.id === selectedRowIds[i]); // 該当データのindexを取得
      list.push(newRows[copyNewValueIdx]);
    }

    setSelectedRows(list);
  }, [selectedRowIds, newRows]);

  return {
    rows,
    newRows,
    searchText,
    searchSecondText,
    searchThirdText,
    selectedRowIds,
    isLoading,
    initialRows,
    selectedRows,
    setSelectedRowIds,
    setNewRows,
    requestSearch, // キーワード検索の関数
    requestSecondSearch, // ２つ目のキーワード検索の関数
    requestThirdSearch, // 3つ目のキーワード検索の関数
    handleClickSelectedRows,
    reacquireData,
  };
};
