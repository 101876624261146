import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
// カスタムフック
import { useGetSalesCategory } from 'hooks/useGetSalesCategory';
// コンポーネント
import { Required } from 'components/uiParts/Required';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '15px 25px 10px',
};

export const SelectSellingPriceModal = (props) => {
  const { open, setOpen, create, titleLeft, titleRight, datas, handleClickForm, isSelected, checkBox} =
    props;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [formInputValue, setFormInputValue] = useState(datas);

  const { salesCategoryList } = useGetSalesCategory();

  /** changeハンドラー */
  const handleChangeForm = (e) => {
    if (e.target.value === undefined) return;
    let copyFormInputValue = [...formInputValue]; // inputをコピー
    copyFormInputValue[e.target.id - 1] = Number(e.target.value);
    setFormInputValue(copyFormInputValue);
  };

  return (
    <div>
      <Button onClick={handleOpen} sx={{ margin: '10px 0' }} variant='contained'>
        {isSelected ? '選択済み' : '選択する'}
      </Button>
      <span style={{ margin: '0 0 0 30px' }}>販売価格標準：{formInputValue[0]}</span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <IconButton
            sx={{ position: 'absolute', right: '5px', top: '5px' }}
            color='primary'
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {salesCategoryList.length ? (
            <Table sx={{ margin: '20px 0 0' }} size='small' aria-label='a dense table'>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', width: 200 }}>{titleLeft}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{titleRight}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salesCategoryList.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {data.name}
                      {!checkBox && index === 0 && <Required />}
                    </TableCell>
                    <TableCell>
                      <TextField
                        id={data.id.toString()}
                        defaultValue={formInputValue[index] !== null ? formInputValue[index] : null}
                        sx={{ width: '260px' }}
                        size='small'
                        onChange={(e) => handleChangeForm(e)}
                        type='number'
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <div style={{ padding: '20px' }}>登録がありません</div>
          )}
          <div style={{ textAlign: 'right' }}>
            <Button
              onClick={() => handleClickForm(formInputValue)}
              sx={{ margin: '20px 0' }}
              variant='contained'
              disabled={formInputValue[0] === null ? true : false}
            >
              {create ? '登録する' : '更新する'}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

SelectSellingPriceModal.propTypes = {
  open: PropTypes.bool, // モーダル開閉の状態
  setOpen: PropTypes.func.isRequired, // モーダル開閉の切り替える
  create: PropTypes.bool, // 登録フォーム
  titleLeft: PropTypes.string.isRequired, // thの左で表示するテキスト
  titleRight: PropTypes.string.isRequired, // thの右で表示するテキスト
  datas: PropTypes.array.isRequired, // 表示するデータ
  handleClickForm: PropTypes.func.isRequired, // 登録or更新ボタンを押したときの処理
  isSelected: PropTypes.bool, // 選択したか
  checkBox: PropTypes.bool, // 特殊商品であるか
};
