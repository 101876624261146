/**
 * 詳細で使用するIDを呼び出すカスタムフック
 */
export const useGetDetailId = () => {
  // URLを取得
  const url = new URL(window.location.href);
  // URLSearchParamsオブジェクトを取得
  const params = url.searchParams;
  // getメソッドidを取得
  const id = Number(params.get('id'));

  return {
    id,
  };
};
