import React from 'react';
import { DataTable } from 'components/uiParts/DataGrid/DataTable';
import { STOCK_TABLE_COLUMNS } from 'utils/constantsTable';
import { Breadcrumb } from 'components/uiParts/Breadcrumb';

export const StockListPage = () => {
  const breadcrumbList = [{ name: 'ダッシュボード', href: '/' }, { name: '在庫一覧' }];
  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <DataTable
        title='在庫一覧'
        columns={STOCK_TABLE_COLUMNS}
        table='Stock'
        api='StockForUser'
        isCreateBtn
        isDetailBtn
        isDeleteBtn
        isQRBtn
        createUrl='/stock/create'
        detailUrl='/stock/detail'
        checkboxSelection
      />
    </>
  );
};
