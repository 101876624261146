import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

export const Loading = (props) => {
  const { isLoading } = props;

  return (
    <>
      {isLoading && (
        <div
          style={{
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            bottom: '0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            left: '0',
            position: 'fixed',
            right: '0',
            top: '0',
            zIndex: '9999999',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};
