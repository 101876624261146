import React, { useState } from 'react';

const initialParams = [
  {
    usage_category: '許可',
  },
]; // 許可をデフォルトとする

/**
 * 一覧で使用区分の禁止を含むを押したときに使う
 */
export const useUsageCategory = () => {
  const [params, setParams] = useState(initialParams);

  const handleClickChangeUCStatus = () => {
    let array = [];
    if (params.length === 0) {
      array = initialParams;
    }
    setParams(array);
  };

  return {
    params,
    handleClickChangeUCStatus,
  };
};
