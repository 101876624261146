import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styles from 'utils/css/Print.module.css';

// URLを取得
const url = new URL(window.location.href);

// eslint-disable-next-line react/display-name
export const ProcessingSheetPDF = React.forwardRef((props, ref) => {
  const { pdfDatas, settingDatas } = props;

  const cellStyle = {
    fontSize: '30px',
    border: '1px solid',
    padding: '2px 10px 0px',
    height: '50px',
  };
  const titleStyle = {
    fontSize: '16px',
    fontWeight: 'bold',
  };
  const thStyle = {
    fontSize: '16px',
    fontWeight: 'bold',
    border: '1px solid',
    width: '14%',
    padding: '3px 7px',
  };
  const subTitleStyle = { padding: '5px', fontWeight: 'bold', fontSize: '17px', width: '30%' };
  const cellDataStyle = {
    maxHeight: '40px',
    fontSize: '30px',
    padding: '5px 10px',
  };

  return (
    <div ref={ref}>
      {pdfDatas.map((data, i) => (
        <div key={i}>
          <div
            style={{
              padding: '30px',
              height: '290mm',
            }}
          >
            {/* 加工指示書(1枚目？) */}
            <Table sx={{ minWidth: 650, margin: '20px 0px 0px, 0px' }}>
              <TableBody>
                <TableRow sx={{ height: '16px' }}>
                  <TableCell sx={thStyle} component='th' scope='row' align='center'>
                    注文者名
                  </TableCell>
                  <TableCell
                    sx={{ ...cellStyle, ...{ width: '57%', fontSize: '28px' } }}
                    align='center'
                  >
                    {data.processing_client_name !== null && data.processing_client_name}
                    {data.processing_title_of_honor !== null && ' ' + data.processing_title_of_honor}
                  </TableCell>
                  <TableCell sx={{ ...cellStyle, ...{ verticalAlign: 'top', width: '29%' } }}>
                    <div
                      style={{
                        ...titleStyle,
                        ...{ verticalAlign: 'top', position: 'relative', left: '-10px' },
                      }}
                    >
                      【注文番号】
                    </div>
                    <div style={{ textAlign: 'center' }}>{data.id !== undefined && data.id}</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={thStyle} component='th' scope='row' align='center'>
                    品名
                  </TableCell>
                  <TableCell colSpan={2} sx={cellStyle} align='center'>
                    {data.processing_product_name !== undefined && data.processing_product_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={2} sx={thStyle} component='th' scope='row' align='center'>
                    規格
                  </TableCell>
                  <TableCell colSpan={2} sx={{ border: '1px solid', padding: 0 }} align='right'>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              ...subTitleStyle,
                              ...{ borderBottom: '1px solid', borderRight: '1px solid' },
                            }}
                            align='center'
                          >
                            厚み
                          </TableCell>
                          <TableCell
                            sx={{
                              ...subTitleStyle,
                              ...{ borderBottom: '1px solid', borderRight: '1px solid' },
                            }}
                            align='center'
                          >
                            幅
                          </TableCell>
                          <TableCell
                            sx={{
                              ...subTitleStyle,
                              ...{ borderBottom: '1px solid' },
                            }}
                            align='center'
                          >
                            長さ
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{ ...cellDataStyle, ...{ borderRight: '1px solid' } }}
                            align='center'
                          >
                            {data.order_thickness !== undefined && data.order_thickness}
                          </TableCell>
                          <TableCell
                            sx={{ ...cellDataStyle, ...{ borderRight: '1px solid' } }}
                            align='center'
                          >
                            {data.order_width !== undefined && data.order_width}
                          </TableCell>
                          <TableCell sx={{ ...cellDataStyle }} align='center'>
                            {data.order_length !== undefined && data.order_length}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} sx={{ border: '1px solid', padding: 0 }} align='right'>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              ...subTitleStyle,
                              ...{ borderBottom: '1px solid', borderRight: '1px solid' },
                            }}
                            align='center'
                          >
                            加工方法
                          </TableCell>
                          <TableCell
                            sx={{
                              ...subTitleStyle,
                              ...{ borderBottom: '1px solid', borderRight: '1px solid' },
                            }}
                            align='center'
                          >
                            枚数
                          </TableCell>
                          <TableCell
                            sx={{
                              ...subTitleStyle,
                              ...{ borderBottom: '1px solid' },
                            }}
                            align='center'
                          >
                            重量
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{ ...cellDataStyle, ...{ borderRight: '1px solid' } }}
                            align='center'
                          >
                            {data.order_process !== undefined && data.order_process}
                          </TableCell>
                          <TableCell
                            sx={{
                              ...cellDataStyle,
                              ...{ borderRight: '1px solid', fontSize: '24px' },
                            }}
                            align='center'
                          >
                            {data.order_number !== undefined && data.order_number + '枚口の'}
                            {data.sub_order_group_id !== undefined &&
                              data.sub_order_group_id.split('_')[2] + '枚目'}
                          </TableCell>
                          <TableCell sx={{ ...cellDataStyle }} align='center'>
                            {data.weight !== undefined && data.weight}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table sx={{ minWidth: 650 }}>
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={1}
                    sx={{
                      ...cellStyle,
                      ...{
                        height: '70px',
                        maxWidth: 380,
                        minWidth: 380,
                        verticalAlign: 'top',
                        borderTop: 'none',
                      },
                    }}
                    scope='row'
                  >
                    <div style={titleStyle}>ハウス名</div>
                    <div
                      style={{
                        textAlign: 'center',
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {data.order_house_name !== undefined && data.order_house_name}
                    </div>
                  </TableCell>
                  <TableCell
                    rowSpan={2}
                    sx={{
                      ...cellStyle,
                      ...{ fontSize: '16px', border: 'none', lineHeight: '1.7' },
                    }}
                    align='center'
                  >
                    {settingDatas.length >= 0 &&
                      settingDatas[0].company_overview !== undefined &&
                      settingDatas[0].company_overview}
                    <br />
                    {settingDatas.length >= 0 &&
                      settingDatas[0].name !== undefined &&
                      settingDatas[0].name}
                    <br />
                    {settingDatas.length >= 0 &&
                      settingDatas[0].address !== undefined &&
                      settingDatas[0].address}
                    <br />
                    TEL{' '}
                    {settingDatas.length >= 0 &&
                      settingDatas[0].tel1 !== undefined &&
                      settingDatas[0].tel1}{' '}
                    FAX{' '}
                    {settingDatas.length >= 0 &&
                      settingDatas[0].tel2 !== undefined &&
                      settingDatas[0].tel2}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={1}
                    sx={{
                      ...cellStyle,
                      ...{ height: '70px', maxWidth: 380, minWidth: 380, verticalAlign: 'top' },
                    }}
                    scope='row'
                  >
                    <div style={titleStyle}>備考</div>
                    <div
                      style={{
                        textAlign: 'center',
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {data.sub_order_remark !== undefined && data.sub_order_remark}
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {/* 加工指示書(2枚目？) */}
            <Table sx={{ minWidth: 650, margin: '155px 0 0' }}>
              <TableBody>
                <TableRow>
                  <TableCell sx={thStyle} component='th' scope='row' align='center'>
                    注文者名
                  </TableCell>
                  <TableCell sx={{ ...cellStyle, ...{ width: '57%' } }} align='center'>
                    {data.processing_client_name !== undefined && data.processing_client_name}
                    {data.title_of_honor !== undefined && data.title_of_honor}
                  </TableCell>
                  <TableCell sx={{ ...cellStyle, ...{ verticalAlign: 'top', width: '45%' } }}>
                    <div
                      style={{
                        ...titleStyle,
                        ...{ verticalAlign: 'top', position: 'relative', left: '-10px' },
                      }}
                    >
                      【注文番号】
                    </div>
                    <div style={{ textAlign: 'center' }}>{data.id !== undefined && data.id}</div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={thStyle} component='th' scope='row' align='center'>
                    品名
                  </TableCell>
                  <TableCell colSpan={2} sx={cellStyle} align='center'>
                    {data.processing_product_name !== undefined && data.processing_product_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={2} sx={thStyle} component='th' scope='row' align='center'>
                    規格
                  </TableCell>
                  <TableCell colSpan={2} sx={{ border: '1px solid', padding: 0 }} align='right'>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              ...subTitleStyle,
                              ...{ borderBottom: '1px solid', borderRight: '1px solid' },
                            }}
                            align='center'
                          >
                            厚み
                          </TableCell>
                          <TableCell
                            sx={{
                              ...subTitleStyle,
                              ...{ borderBottom: '1px solid', borderRight: '1px solid' },
                            }}
                            align='center'
                          >
                            幅
                          </TableCell>
                          <TableCell
                            sx={{
                              ...subTitleStyle,
                              ...{ borderBottom: '1px solid' },
                            }}
                            align='center'
                          >
                            長さ
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{ ...cellDataStyle, ...{ borderRight: '1px solid' } }}
                            align='center'
                          >
                            {data.order_thickness !== undefined && data.order_thickness}
                          </TableCell>
                          <TableCell
                            sx={{ ...cellDataStyle, ...{ borderRight: '1px solid' } }}
                            align='center'
                          >
                            {data.order_width !== undefined && data.order_width}
                          </TableCell>
                          <TableCell sx={{ ...cellDataStyle }} align='center'>
                            {data.order_length !== undefined && data.order_length}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} sx={{ border: '1px solid', padding: 0 }} align='right'>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              ...subTitleStyle,
                              ...{ borderBottom: '1px solid', borderRight: '1px solid' },
                            }}
                            align='center'
                          >
                            加工方法
                          </TableCell>
                          <TableCell
                            sx={{
                              ...subTitleStyle,
                              ...{ borderBottom: '1px solid', borderRight: '1px solid' },
                            }}
                            align='center'
                          >
                            枚数
                          </TableCell>
                          <TableCell
                            sx={{
                              ...subTitleStyle,
                              ...{ borderBottom: '1px solid' },
                            }}
                            align='center'
                          >
                            重量
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{ ...cellDataStyle, ...{ borderRight: '1px solid' } }}
                            align='center'
                          >
                            {data.order_process !== undefined && data.order_process}
                          </TableCell>
                          <TableCell
                            sx={{
                              ...cellDataStyle,
                              ...{ borderRight: '1px solid', fontSize: '24px' },
                            }}
                            align='center'
                          >
                            {data.order_number !== undefined && data.order_number + '枚口の'}
                            {data.sub_order_group_id !== undefined &&
                              data.sub_order_group_id.split('_')[2] + '枚目'}
                          </TableCell>
                          <TableCell sx={{ ...cellDataStyle }} align='center'>
                            {data.weight !== undefined && data.weight}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table sx={{ minWidth: 650 }}>
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      ...cellStyle,
                      ...{
                        borderTop: 'none',
                        height: '70px',
                        verticalAlign: 'top',
                        maxWidth: 200,
                        minWidth: 200,
                      },
                    }}
                    scope='row'
                  >
                    <div style={titleStyle}>納品予定日</div>
                    <div style={{ textAlign: 'center' }}>
                      {data.delivery_date !== undefined && data.delivery_date}
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...cellStyle,
                      ...{
                        borderTop: 'none',
                        height: '70px',
                        verticalAlign: 'top',
                        maxWidth: 300,
                        minWidth: 300,
                      },
                    }}
                    scope='row'
                  >
                    <div style={titleStyle}>ハウス名</div>
                    <div
                      style={{
                        textAlign: 'center',
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {data.order_house_name !== undefined && data.order_house_name}
                    </div>
                  </TableCell>
                  <TableCell
                    rowSpan={2}
                    sx={{
                      ...cellStyle,
                      ...{
                        width: '16%',
                        borderTop: 'none',
                        height: '70px',
                        verticalAlign: 'top',
                      },
                    }}
                    scope='row'
                  >
                    <div style={{ ...titleStyle, ...{ fontSize: '10px' } }}>管理QRコード</div>
                    <QRCode
                      value={
                        url.origin + '/order/detail?id=' + data.film_orders_master_id + '&SubOrderId=' + data.id
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      ...cellStyle,
                      ...{ height: '70px', verticalAlign: 'top', maxWidth: 500, minWidth: 500 },
                    }}
                    scope='row'
                    colSpan={2}
                  >
                    <div style={titleStyle}>備考</div>
                    <div
                      style={{
                        textAlign: 'center',
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {data.sub_order_remark !== undefined && data.sub_order_remark}
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          {/* 改ページ */}
          <div style={{ pageBreakAfter: 'always' }}></div>
        </div>
      ))}
    </div>
  );
});

ProcessingSheetPDF.propTypes = {
  pdfDatas: PropTypes.array.isRequired,
  settingDatas: PropTypes.array.isRequired,
};
