import PropTypes from 'prop-types';

// MUI
import { Typography } from '@mui/material';

export const SubTitle = (props) => {
  const { title, hideBorder, color } = props;

  let style = {
    color: color ? color : '#1976d2',
    fontWeight: 'bold',
    lineHeight: '1.2',
    borderLeft: '4px solid',
    padding: '0 0 0 10px',
  };

  if (hideBorder) {
    style = { ...style, ...{ borderLeft: 'none', fontSize: '1.1rem', padding: '0' } };
  }

  return (
    <Typography sx={style} variant='h6' gutterBottom component='div'>
      {title}
    </Typography>
  );
};

SubTitle.propTypes = {
  title: PropTypes.string.isRequired, // タイトル
  hideBorder: PropTypes.bool, // タイトル横に線をつけるか
  color: PropTypes.string, // 文字色
};
