import React, { useState, useEffect } from 'react';
import axios from 'axios';
// カスタムフック
import { useSnackBar } from 'hooks/useSnackBar';
import { useCommonFunction } from 'hooks/useCommonFunction';

export const useCreateClientForm = (columns) => {
  const [formInputValue, setFormInputValue] = useState({
    name: '',
    furigana: '',
    tel: '',
    mobile_tel: '',
    fax: '',
    mail_address: '',
    post_code: '',
    address: '',
    after_address: '',
    film_sales_price_categories_master_id: 1,
    film_processing_price_categories_master_id: 1,
    film_customer_categories_1_master_id: null,
    film_customer_categories_2_master_id: null,
    film_customer_categories_3_master_id: null,
    usage_category: '許可',
    remarks: '',
  });
  const [addLoadingForm, setAddLoadingForm] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const { toggleSnack } = useSnackBar();

  // カスタムフック
  const { convertStr, convertStr2, onlyNumbers, onlyNumbersAndHyphen, onlyNumbersAndPoint } =
    useCommonFunction();

  const updateZipcode = async (value) => {
    // ハイフンがあれば除く
    const newValue = onlyNumbers(value);
    // 字数が7文字あれば郵便番号とみなす
    if (newValue.length === 7) {
      try {
        const res = await axios.get('https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + value);
        if (res.data.results) {
          const result = res.data.results[0];
          setFormInputValue({
            ...formInputValue,
            post_code: value,
            address: result.address1 + result.address2 + result.address3,
          });
        }
      } catch {
        alert('住所の取得に失敗しました。');
      }
    }
  };

  /** 入力時のchangeハンドラー */
  const handleChangeCreateForm = (e, type, typing = false) => {
    if (e.target.value === undefined) return;

    let value = e.target.value;

    // 郵便番号を入力したら住所を自動入力する
    if (e.target.name === 'post_code') {
      updateZipcode(value);
    }

    // フリガナを入力時に半角カタカナを入力したら全角カタカナに自動変換する
    if (e.target.name === 'furigana') {
      if (!typing) {
        value = convertStr(value);
        value = convertStr2(value);
      }
    }

    // 数字のみの入力とする
    if (
      e.target.name === 'tel' ||
      e.target.name === 'mobile_tel' ||
      e.target.name === 'fax' ||
      e.target.name === 'post_code'
    ) {
      value = onlyNumbersAndHyphen(value);
    }

    let inputObject = { [e.target.name]: value };
    // typeに応じて型を指定する
    switch (type) {
      case 'number':
        inputObject[e.target.name] = onlyNumbersAndPoint(value);
        break;
      case 'bool':
        inputObject[e.target.name] = toBoolean(value);
        break;
      default:
        break;
    }

    setFormInputValue({ ...formInputValue, ...inputObject });
  };

  // TODO 簡易必須チェック　バリデーションを実装したら削除
  useEffect(() => {
    if (!Object.keys(formInputValue).length) return;
    if (
      formInputValue.name === undefined ||
      formInputValue.furigana === undefined ||
      formInputValue.title_of_honor === undefined ||
      formInputValue.film_sales_price_categories_master_id === undefined ||
      formInputValue.film_processing_price_categories_master_id === undefined
    )
      return;

    let status = true;
    if (
      formInputValue.name.length > 0 &&
      formInputValue.furigana.length > 0 &&
      formInputValue.title_of_honor.length > 0 &&
      formInputValue.film_sales_price_categories_master_id > 0 &&
      formInputValue.film_processing_price_categories_master_id > 0
    ) {
      status = false;
    }
    setIsFormValid(status);
  }, [formInputValue]);

  /** 登録する */
  const handleClickCreateForm = async () => {
    setAddLoadingForm(true); // ローディング表示

    const copyInput = { ...formInputValue };
    // フリガナをカタカナに変換する
    if (copyInput.furigana) {
      copyInput.furigana = convertStr(copyInput.furigana);
    }

    // 登録する前に型を設定する
    for (let i = 0; i < columns.length; i++) {
      if (
        copyInput[columns[i].field] !== undefined &&
        columns[i].formType &&
        columns[i].formType === 'number'
      ) {
        copyInput[columns[i].field] = Number(copyInput[columns[i].field]);
      }
    }

    // 敬称が無しの場合は空文字で登録する
    if (copyInput.title_of_honor === 'なし') {
      copyInput.title_of_honor = '';
    }

    const url = `${process.env.REACT_APP_API_URL}/FILMa_AddClient?code=${process.env.REACT_APP_API_CODE}`;

    let response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify([copyInput]),
    })
      .then((response) => {
        setAddLoadingForm(false); // ローディング非表示
        return response.json();
      })
      .catch((error) => {
        setAddLoadingForm(false); // ローディング非表示
        return false;
      });

    let status = 'success';
    let message = '登録が完了しました';
    if (response === false || response.code) {
      // 失敗時
      status = 'error';
      message = '登録に失敗しました' + ' FILMa_AddClient';
    } else {
      // 成功時
      setFormInputValue({});
      setIsFormValid(true);
      window.location.replace(location.origin + '/client/list'); // 一覧へリダイレクト
    }

    // エラーコードがあれば足す
    if (response.code) {
      message = message + '[' + response.code + ']';
    }

    toggleSnack(true, status, message);
  };

  return {
    formInputValue,
    addLoadingForm,
    isFormValid,
    handleClickCreateForm,
    handleChangeCreateForm,
  };
};
