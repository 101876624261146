import React, { useState, useEffect, useCallback } from 'react';

// カスタムフック
import { useFetchDataWithInit } from 'hooks/useFetchData';
import { useSnackBar } from 'hooks/useSnackBar';

export const useGetFormType = () => {
  const [formTypeList, setFormTypeList] = useState([]); //フィルタリングした後の列データ

  const url = `${process.env.REACT_APP_API_URL}/FILMa_GetFormType?code=${process.env.REACT_APP_API_CODE}`;

  // カスタムフック
  const { data, error, isLoading, reacquireData } = useFetchDataWithInit(url, []);
  const { toggleSnack } = useSnackBar();

  useEffect(() => {
    if (!data) return;
    let status = 'success';
    let message = '得意先区分の取得に失敗しました。';
    if (!data.code) {
      // 成功時
      setFormTypeList(data.result);
    } else {
      // 失敗時
      status = 'error';
      // if (data.code === 'E0220') {
      //   status = 'info';
      //   message = 'データの登録がありません';
      // }
      toggleSnack(true, status, message);
    }
  }, [data]);

  // エラーの場合
  useEffect(() => {
    if (!error) return;
    toggleSnack(true, 'error', '得意先区分の取得に失敗しました。');
  }, [error]);

  return {
    formTypeList,
  };
};
