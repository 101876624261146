import React from 'react';

/**
 * 認証用の構成パラメーターを記述する
 * @see https://docs.microsoft.com/ja-jp/azure/active-directory/develop/tutorial-v2-react#configure-your-javascript-spa
 */
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_ADB2C_APPLICATION_ID, // アプリケーションIDを入力
    authority: process.env.REACT_APP_ADB2C_AUTHORITY, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    knownAuthorities: [process.env.REACT_APP_ADB2C_KNOWN_AUTHORITIES],
    redirectUri: '/',
    postLogoutRedirectUri: '/login',
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['openid', 'offline_access'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'Enter_the_Graph_Endpoint_Here/v1.0/me',
};
