import React from 'react';
// コンポーネント
import { UpdateOrderForm } from 'components/uiParts/Form/UpdateOrderForm';

export const OrderDetailPage = () => {
  return (
    <>
      <UpdateOrderForm />
    </>
  );
};
