import React from 'react';
import PropTypes from 'prop-types';
// MUI
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Loading } from 'components/uiParts/Loading';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export const ProcessingBulkConfModal = (props) => {
  const { open, setOpen, selectProcessingBulkClick, isConfLoading } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <IconButton
            sx={{ position: 'absolute', right: '5px', top: '5px' }}
            color='primary'
            onClick={handleClose}
            disabled={isConfLoading}
          >
            <CloseIcon />
          </IconButton>
          <div style={{ padding: '10px 0' }}>
            <Box sx={{ width: '100%' }}>
              在庫割当済の注文を加工済に変更しますか？
              <div style={{ margin: '10px 0 0', textAlign: 'right' }}>
                <LoadingButton
                  onClick={() => selectProcessingBulkClick()}
                  variant='contained'
                  loading={isConfLoading}
                >
                  変更する
                </LoadingButton>
              </div>
            </Box>
            {/* <Loading isLoading={addLoadingForm} /> */}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

ProcessingBulkConfModal.propTypes = {
  open: PropTypes.bool.isRequired, // モーダル開く
  setOpen: PropTypes.func.isRequired, // モーダル開く
  selectProcessingBulkClick: PropTypes.func.isRequired, // 更新する
  isConfLoading: PropTypes.bool.isRequired, // ローディング
};
