import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// MUI
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
// コンポーネント
import { Required } from 'components/uiParts/Required';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '15px 25px 10px',
};

export const ChangeLengthModal = (props) => {
  const {
    open,
    setOpen,
    formInputValue,
    changeLength,
    handleChangeLengthForm,
    sHFormInputValue,
    isChangeLengthBtn,
    addLoadingUpdateForm,
    errors,
    helperText,
    handleClose,
  } = props;

  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div>
      <Button onClick={handleOpen} sx={{ margin: '10px 0' }} variant='contained'>
        残長変更
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <IconButton
            sx={{ position: 'absolute', right: '5px', top: '5px' }}
            color='primary'
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          <Table sx={{ margin: '20px 0 0' }} size='small' aria-label='a dense table'>
            <TableBody>
              {900 > windowDimensions.width ? (
                <>
                  <TableRow>
                    <TableCell sx={{ width: '150px', border: 0 }}>残長物理</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <TextField
                        value={
                          formInputValue.length > 0 && formInputValue[0].length_ph !== undefined
                            ? formInputValue[0].length_ph
                            : 0
                        }
                        sx={{ width: '260px' }}
                        size='small'
                        type='number'
                        disabled={true}
                        name='length_ph'
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ position: 'relative', border: 0 }}>
                      使用長
                      <span>
                        <Required />
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <TextField
                        value={
                          sHFormInputValue.length > 0 && sHFormInputValue[0].length_us !== undefined
                            ? sHFormInputValue[0].length_us
                            : 0
                        }
                        sx={{ width: '260px', wordBreak: 'break-all' }}
                        size='small'
                        onChange={(e) => handleChangeLengthForm(e)}
                        type='number'
                        name='length_us'
                        error={errors.length_us ? true : false}
                        helperText={errors.length_us ? helperText : false}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ border: 0 }}>変更事由</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <TextField
                        value={
                          sHFormInputValue.length > 0 && sHFormInputValue[0].reason !== undefined
                            ? sHFormInputValue[0].reason
                            : ''
                        }
                        sx={{ width: '260px' }}
                        size='small'
                        onChange={(e) => handleChangeLengthForm(e)}
                        rows={4}
                        multiline={true}
                        name='reason'
                      />
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <>
                  <TableRow>
                    <TableCell sx={{ width: '150px' }}>残長物理</TableCell>
                    <TableCell>
                      <TextField
                        value={
                          formInputValue.length > 0 && formInputValue[0].length_ph !== undefined
                            ? formInputValue[0].length_ph
                            : 0
                        }
                        sx={{ width: '260px' }}
                        size='small'
                        type='number'
                        disabled={true}
                        name='length_ph'
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ position: 'relative' }}>
                      使用長
                      <span style={{ position: 'absolute', right: '0' }}>
                        <Required />
                      </span>
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={
                          sHFormInputValue.length > 0 && sHFormInputValue[0].length_us !== undefined
                            ? sHFormInputValue[0].length_us
                            : 0
                        }
                        sx={{ width: '260px', wordBreak: 'break-all' }}
                        size='small'
                        onChange={(e) => handleChangeLengthForm(e)}
                        type='number'
                        name='length_us'
                        error={errors.length_us ? true : false}
                        // helperText={errors.length_us ? helperText : false}
                        helperText={errors.length_us ? helperText : false}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>変更事由</TableCell>
                    <TableCell>
                      <TextField
                        value={
                          sHFormInputValue.length > 0 && sHFormInputValue[0].reason !== undefined
                            ? sHFormInputValue[0].reason
                            : ''
                        }
                        sx={{ width: '260px' }}
                        size='small'
                        onChange={(e) => handleChangeLengthForm(e)}
                        rows={4}
                        multiline={true}
                        name='reason'
                      />
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
          <div style={{ textAlign: 'right' }}>
            <LoadingButton
              onClick={changeLength}
              sx={{ margin: '20px 0' }}
              variant='contained'
              disabled={isChangeLengthBtn || errors.length_us}
              loading={addLoadingUpdateForm}
            >
              更新する
            </LoadingButton>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

ChangeLengthModal.propTypes = {
  open: PropTypes.bool.isRequired, // モーダル開閉の状態
  setOpen: PropTypes.func.isRequired, // モーダル開閉の切り替える
  formInputValue: PropTypes.array.isRequired,
  sHFormInputValue: PropTypes.array.isRequired,
  handleChangeLengthForm: PropTypes.func.isRequired,
  changeLength: PropTypes.func.isRequired,
  isChangeLengthBtn: PropTypes.bool.isRequired,
  addLoadingUpdateForm: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  helperText: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};
