import React, { useState, useEffect, useCallback } from 'react';

// カスタムフック
import { useFetchDataWithInit } from 'hooks/useFetchData';
import { useSnackBar } from 'hooks/useSnackBar';

/**
 * 詳細で使用するIDを呼び出すカスタムフック
 */
export const useGetCustomerCategory1 = () => {
  const [customerCategoryList1, setCustomerCategoryList1] = useState([]); //フィルタリングした後の列データ

  const url = `${process.env.REACT_APP_API_URL}/FILMa_GetCustomerCategory1?code=${process.env.REACT_APP_API_CODE}`;

  // カスタムフック
  const { data, error, isLoading, reacquireData } = useFetchDataWithInit(url, []);
  const { toggleSnack } = useSnackBar();

  useEffect(() => {
    if (!data) return;
    let status = 'success';
    let message = '得意先区分1の取得に失敗しました。';
    if (!data.code) {
      // 成功時
      setCustomerCategoryList1(data.result);
    } else {
      // 失敗時
      status = 'error';
      // if (data.code === 'E0220') {
      //   status = 'info';
      //   message = 'データの登録がありません';
      // }
      toggleSnack(true, status, message);
    }
  }, [data]);

  // エラーの場合
  useEffect(() => {
    if (!error) return;
    toggleSnack(true, 'error', '得意先区分1の取得に失敗しました。');
  }, [error]);

  return {
    customerCategoryList1,
  };
};

export const useGetCustomerCategory2 = () => {
  const [customerCategoryList2, setCustomerCategoryList2] = useState([]); //フィルタリングした後の列データ

  const url = `${process.env.REACT_APP_API_URL}/FILMa_GetCustomerCategory2?code=${process.env.REACT_APP_API_CODE}`;

  // カスタムフック
  const { data, error, isLoading, reacquireData } = useFetchDataWithInit(url, []);
  const { toggleSnack } = useSnackBar();

  useEffect(() => {
    if (!data) return;
    let status = 'success';
    let message = '得意先区分の取得に失敗しました。';
    if (!data.code) {
      // 成功時
      setCustomerCategoryList2(data.result);
    } else {
      // 失敗時
      status = 'error';
      // if (data.code === 'E0220') {
      //   status = 'info';
      //   message = 'データの登録がありません';
      // }
      toggleSnack(true, status, message);
    }
  }, [data]);

  // エラーの場合
  useEffect(() => {
    if (!error) return;
    toggleSnack(true, 'error', '得意先区分2の取得に失敗しました。');
  }, [error]);

  return {
    customerCategoryList2,
  };
};

export const useGetCustomerCategory3 = () => {
  const [customerCategoryList3, setCustomerCategoryList3] = useState([]); //フィルタリングした後の列データ

  const url = `${process.env.REACT_APP_API_URL}/FILMa_GetCustomerCategory3?code=${process.env.REACT_APP_API_CODE}`;

  // カスタムフック
  const { data, error, isLoading, reacquireData } = useFetchDataWithInit(url, []);
  const { toggleSnack } = useSnackBar();

  useEffect(() => {
    if (!data) return;
    let status = 'success';
    let message = '得意先区分3の取得に失敗しました。';
    if (!data.code) {
      // 成功時
      setCustomerCategoryList3(data.result);
    } else {
      // 失敗時
      status = 'error';
      // if (data.code === 'E0220') {
      //   status = 'info';
      //   message = 'データの登録がありません';
      // }
      toggleSnack(true, status, message);
    }
  }, [data]);

  // エラーの場合
  useEffect(() => {
    if (!error) return;
    toggleSnack(true, 'error', '得意先区分3の取得に失敗しました。');
  }, [error]);

  return {
    customerCategoryList3,
  };
};
