import React, { useContext, forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// Recoil
import { useRecoilValue } from 'recoil';
import { snackBarState } from 'recoil/atom/SnackBarAtom';
// カスタムフック
import { useSnackBar } from 'hooks/useSnackBar';

/**
 * スナックバー
 * https://qiita.com/TO-TO/items/d1a2e6a5d64f1982c1c0#tasksjsx
 */
export const SimpleSnackbar = () => {
  // Recoil
  const snackState = useRecoilValue(snackBarState);
  // カスタムフック
  const { toggleSnack } = useSnackBar();

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    toggleSnack(false, snackState.type, '');
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackState.isOpen}
        // autoHideDuration={10000} // メッセージが自動で消える
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackState.type}>
          {snackState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
