import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// MUI
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
// コンポーネント
import { BorderTop } from 'components/uiParts/BorderTop';
import { Required } from 'components/uiParts/Required';
// カスタムフック
import { useCreateForm } from 'hooks/useForm';

export const CreateForm = (props) => {
  const { api, columns, input, redirectUrl } = props;

  const {
    isCreateFormValid,
    formInputValue,
    addLoadingForm,
    handleChangeCreateForm,
    handleClickCreateForm,
  } = useCreateForm(api, columns, input, redirectUrl);

  return (
    <>
      <div style={{ margin: '30px 0 0' }}>
        {columns.map(
          (column, index) =>
            column.create && (
              <div key={index} style={{ margin: '20px 0 0' }}>
                <div style={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
                  {column.headerName}
                  {column.reauired && <Required />}
                </div>
                <TextField
                  name={column.field}
                  onChange={(e) =>
                    handleChangeCreateForm(e, column.formType === 'number' ? 'number' : 'text')
                  }
                  sx={{ margin: '10px 0 0', width: 400 }}
                  size='small'
                  value={formInputValue[column.field] ? formInputValue[column.field] : ''}
                  multiline={column.inputRows > 1 ? true : false}
                  rows={column.inputRows > 1 ? column.inputRows : 1}
                />
              </div>
            )
        )}
        <BorderTop />
        <div style={{ textAlign: 'right' }}>
          <LoadingButton
            disabled={isCreateFormValid}
            onClick={handleClickCreateForm}
            variant='contained'
            loading={addLoadingForm}
          >
            登録する
          </LoadingButton>
        </div>
      </div>
    </>
  );
};

CreateForm.propTypes = {
  api: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  input: PropTypes.object.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  // // tabs: PropTypes.object.isrequired,
  // colomuns: PropTypes.array.isrequired,
};
