import React, { useState, useEffect, useCallback } from 'react';

// カスタムフック
import { useFetchDataWithInit } from 'hooks/useFetchData';
import { useSnackBar } from 'hooks/useSnackBar';

/**
 * その他設定を取得するカスタムフック
 */
export const useGetOtherSetting = () => {
  const [otherSettingList, setOtherSettingList] = useState([]);

  const url = `${process.env.REACT_APP_API_URL}/FILMa_GetOtherSetting?code=${process.env.REACT_APP_API_CODE}`;

  // カスタムフック
  const { data, error, isLoading, reacquireData } = useFetchDataWithInit(url, []);
  const { toggleSnack } = useSnackBar();

  useEffect(() => {
    if (!data) return;
    let status = 'success';
    let message = 'その他設定の取得に失敗しました。';
    if (!data.code) {
      // 成功時
      setOtherSettingList(data.result);
    } else {
      // 失敗時
      status = 'error';
      toggleSnack(true, status, message);
    }
  }, [data]);

  // エラーの場合
  useEffect(() => {
    if (!error) return;
    toggleSnack(true, 'error', 'その他設定の取得に失敗しました。');
  }, [error]);

  return {
    otherSettingList,
  };
};
