import React, { useState, useEffect, useCallback } from 'react';
import useSWR from 'swr';
import { useSnackBar } from 'hooks/useSnackBar';

async function fetcherWithInit(url, params) {
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
  });
  return response.json();
}

/**
 * データフェッチのカスタムフック
 */
export const useFetchDataWithInit = (url, params) => {
  const [isLoading, setIsLoading] = useState(false);
  let { data, error, mutate } = useSWR([url, params], fetcherWithInit);

  /**
   * データを再取得する場合に使う
   * @see https://swr.vercel.app/ja/docs/mutation
   */
  const reacquireData = useCallback(() => {
    mutate(data);
  }, []);

  // ローディング処理
  useEffect(() => {
    setIsLoading(!error && !data);
  }, [data, error]);

  return { data, error, isLoading, setIsLoading, reacquireData };
};

/**
 * データフェッチのカスタムフック
 * useFetchDataを叩けば任意のタイミングでgetできる
 */
export const useFetchData = () => {
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [fetchDatas, setFetchDatas] = useState([]);
  const { toggleSnack } = useSnackBar();

  /**
   * データフェッチ
   * fetchDataを叩けば任意のタイミングでgetできる
   * @param {String} api
   * @param {Attay} params
   */
  const fetchFunc = async (api, params) => {
    setIsFetchLoading(true); // ローディング
    const url = `${process.env.REACT_APP_API_URL}/FILMa_Get${api}?code=${process.env.REACT_APP_API_CODE}`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return false;
      });

    setIsFetchLoading(false); // ローディング非表示

    if (!response.code && response) {
      // 成功
      let copyResponse = JSON.parse(JSON.stringify(response.result));
      if (response.result.length === 0) {
        setFetchDatas([null]);
      }
      setFetchDatas(copyResponse);
      return copyResponse;
    } else {
      // 失敗
      toggleSnack(true, 'error', 'ページの読み込みに失敗しましたFILMa_Get' + api);
    }
  };

  return { isFetchLoading, fetchDatas, fetchFunc };
};
