import React from 'react';
// コンポーネント
import { CreateOrderForm } from 'components/uiParts/Form/CreateOrderForm';

export const OrderCreatePage = () => {
  return (
    <>
      <CreateOrderForm />
    </>
  );
};
