import React, { useState, useEffect } from 'react';
// カスタムフック
import { useFetchDataWithInit } from 'hooks/useFetchData';
import { useSnackBar } from 'hooks/useSnackBar';
import { useCommonFunction } from 'hooks/useCommonFunction';

export const useProductDetail = (api, params, columns) => {
  const [formInputValue, setFormInputValue] = useState({}); // データ取得後のデータ
  const [openSPModal, setOpenSPModal] = useState(false);
  const [openPCModal, setOpenPCModal] = useState(false);
  const [addLoadingForm, setAddLoadingForm] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  const url = `${process.env.REACT_APP_API_URL}/FILMa_Get${api}?code=${process.env.REACT_APP_API_CODE}`;

  // カスタムフック
  const { data, error, isLoading } = useFetchDataWithInit(url, params);
  const { toggleSnack } = useSnackBar();
  const { onlyNumbersAndPoint } = useCommonFunction();

  useEffect(() => {
    if (!data) return;
    let status = 'success';
    let message = 'ページの取得に失敗しました';

    if (!data.code) {
      // 成功時
      setFormInputValue(data.result);
    } else {
      // 失敗時
      status = 'error';
      if (data.code === 'E0220') {
        status = 'info';
        message = 'データの登録がありません';
      }
      toggleSnack(true, status, message);
    }
  }, [data]);

  // エラーの場合
  useEffect(() => {
    if (!error) return;
    toggleSnack(true, 'error', 'ページの読み込みに失敗しました');
  }, [error]);

  /**
   * 販売価格モーダルの登録ボタンを押したらformInputValueに入れる
   * @param {Object} array [1000, null, 1000, 1000]
   */
  const handleClickSellingPriceForm = (array) => {
    setFormInputValue([{ ...formInputValue[0], selling_price_m: array }]);
    setOpenSPModal(false);
    setIsFormValid(false);
  };

  /**
   * 加工費モーダルの登録ボタンを押したらformInputValueに入れる
   * @param {Object} array [1000, null, 1000, 1000]
   */
  const handleClickProcessingCostForm = (array) => {
    setFormInputValue([{ ...formInputValue[0], processing_price: array }]);
    setOpenPCModal(false);
    setIsFormValid(false);
  };

  /** 更新時のchangeハンドラー */
  const handleChangeUpdateForm = (e, type) => {
    if (e.target.value === undefined) return;
    let value = e.target.value;
    const copyInput = [...formInputValue];
    // typeに応じて型を指定する
    switch (type) {
      case 'number':
        value = onlyNumbersAndPoint(e.target.value);
        break;
      case 'bool':
        value = toBoolean(e.target.value);
        break;
      default:
        break;
    }
    copyInput[0][e.target.name] = value;

    // 厚みor幅or長さor比重が変更されたら
    // 重さを再計算する（重さ=厚みx幅x長さx比重x0.01）
    if (
      e.target.name === 'thickness' ||
      e.target.name === 'width' ||
      e.target.name === 'length' ||
      e.target.name === 'specific_gravity'
    ) {
      copyInput[0].weight =
        copyInput[0].thickness *
        copyInput[0].width *
        copyInput[0].length *
        copyInput[0].specific_gravity *
        0.01;
    }

    // 商品名or厚みor幅or長さが変更されたら
    // 詳細を再作成する（商品名_厚み_幅_長さ：テスト_T100_W100_L100）
    if (
      e.target.name === 'name' ||
      e.target.name === 'thickness' ||
      e.target.name === 'width' ||
      e.target.name === 'length' ||
      e.target.name === 'specific_gravity'
    ) {
      copyInput[0].detail =
        copyInput[0].name +
        '_T' +
        copyInput[0].thickness +
        '_W' +
        copyInput[0].width +
        '_L' +
        copyInput[0].length;
    }

    // 長さが選択されたら原価を計算する
    if (e.target.name === 'length') {
      copyInput[0].cost_price = Math.round(Number(e.target.value) * copyInput[0].cost_price_m);
    }

    // 原価/mが選択されたら原価を計算する
    if (e.target.name === 'cost_price_m') {
      copyInput[0].cost_price = Math.round(copyInput[0].length * Number(e.target.value));
    }

    setFormInputValue(copyInput);
    setIsFormValid(false);
  };

  /** 更新する */
  const handleClickUpdateForm = async () => {
    setAddLoadingForm(true); // ローディング表示

    const url = `${process.env.REACT_APP_API_URL}/FILMa_UpdateProduct?code=${process.env.REACT_APP_API_CODE}`;

    const copyFormInputValue = [...formInputValue]; // コピー

    // 更新する前に型を設定する
    for (let i = 0; i < columns.length; i++) {
      if (
        copyFormInputValue[0][columns[i].field] !== undefined &&
        columns[i].formType &&
        columns[i].formType === 'number'
      ) {
        copyFormInputValue[0][columns[i].field] = Number(copyFormInputValue[0][columns[i].field]);
      }
    }

    let response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(copyFormInputValue),
    })
      .then((response) => {
        setAddLoadingForm(false); // ローディング非表示
        return response.json();
      })
      .catch((error) => {
        setAddLoadingForm(false); // ローディング非表示
        return false;
      });

    let status = 'success';
    let message = '更新が完了しました';
    if (response === false || response.code) {
      // 失敗時
      status = 'error';
      message = '更新に失敗しました' + ' FILMa_UpdateProduct';
    } else {
      // 成功時
      setFormInputValue({});
      setIsFormValid(true);
      window.location.replace(location.origin + '/product/list'); // 一覧へリダイレクト
    }

    // エラーコードがあれば足す
    if (response.code) {
      message = message + '[' + response.code + ']';
    }

    toggleSnack(true, status, message);
  };

  return {
    formInputValue,
    isLoading,
    addLoadingForm,
    openSPModal,
    openPCModal,
    isFormValid,
    setOpenSPModal,
    setOpenPCModal,
    handleClickSellingPriceForm,
    handleClickProcessingCostForm,
    handleChangeUpdateForm,
    handleClickUpdateForm,
  };
};
