import React, { useState } from 'react';
import PropTypes from 'prop-types';
// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
// コンポーネント
import { DataTable } from 'components/uiParts/DataGrid/DataTable';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export const SelectProductModal = (props) => {
  const {
    open,
    setOpen,
    handleClickSelectProduct,
    isButton,
    setSelectedProductRow,
    columns,
    isSelectProductButton = true,
  } = props;

  const handleOpen = () => {
    if (setSelectedProductRow) {
      setSelectedProductRow({}); // リセット
    }

    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <div>
      {isSelectProductButton && (
        <Button
          disabled={isButton}
          onClick={handleOpen}
          sx={{ margin: '10px 0' }}
          variant='contained'
        >
          商品規格選択
        </Button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <>
            <DataTable
              title=''
              columns={columns}
              api='ProductForUser'
              isDecisionBtn={true}
              handleClickDecision={handleClickSelectProduct}
              columnVisibilityList={{ selling_price_m: false }}
            />
          </>
        </Box>
      </Modal>
    </div>
  );
};

SelectProductModal.propTypes = {
  open: PropTypes.bool.isRequired, // モーダル開く
  setOpen: PropTypes.func.isRequired, // モーダル開く
  handleClickSelectProduct: PropTypes.func.isRequired, // 決定ボタンのクリックイベント
  isButton: PropTypes.bool.isRequired, // ボタンの状態
  setSelectedProductRow: PropTypes.func, // 選択した商品が入る
  columns: PropTypes.array,
  isSelectProductButton: PropTypes.bool,
};
