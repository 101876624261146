import React from 'react';
import PropTypes from 'prop-types';
// MUI
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
// コンポーネント
import { BorderTop } from 'components/uiParts/BorderTop';
import { SelectSellingPriceModal } from 'components/uiParts/Modal/SelectSellingPriceModal';
import { SelectProcessingCostModal } from 'components/uiParts/Modal/SelectProcessingCostModal';
import { Required } from 'components/uiParts/Required';
import { Loading } from 'components/uiParts/Loading';
// カスタムフック
import { useProductDetail } from 'hooks/useProductDetail';
import { useGetFormType } from 'hooks/useGetFormType';
// 定数ファイル
import { PRODUCT_TABLE_COLUMNS } from 'utils/constantsTable';

export const UpdateProductForm = (props) => {
  const { api, params, columns } = props;

  // カスタムフック
  const {
    formInputValue,
    isLoading,
    addLoadingForm,
    openSPModal,
    openPCModal,
    isFormValid,
    setOpenSPModal,
    setOpenPCModal,
    handleClickSellingPriceForm,
    handleClickProcessingCostForm,
    handleChangeUpdateForm,
    handleClickUpdateForm,
  } = useProductDetail(api, params, columns);

  const { formTypeList } = useGetFormType();

  return (
    <>
      {formInputValue && formInputValue.length ? (
        <Table sx={{ margin: '20px 0 0', width: 'auto' }} size='small' aria-label='a dense table'>
          <TableBody>
            {PRODUCT_TABLE_COLUMNS.map(
              (column, index) =>
                column.update && (
                  <TableRow key={index} sx={{ border: 0 }}>
                    <TableCell
                      sx={{
                        width: '130px',
                        border: 0,
                        paddingLeft: 0,
                        position: 'relative',
                        paddingRight: '50px',
                      }}
                    >
                      {column.headerName}
                      {column.reauired && (
                        <span style={{ position: 'absolute', right: '10px' }}>
                          <Required />
                        </span>
                      )}
                    </TableCell>
                    {column.formType === 'select' ? (
                      <>
                        {column.field === 'film_form_types_master_id' ? ( // 形状はAPIでセレクトボックスを作成する
                          <TableCell sx={{ border: 0, padding: '16px 0' }}>
                            {formTypeList.length > 0 && (
                              <TextField
                                name={column.field}
                                onChange={(e) =>
                                  handleChangeUpdateForm(
                                    e,
                                    column.formType === 'number' ? 'number' : 'text'
                                  )
                                }
                                select
                                defaultValue={
                                  formInputValue[0][column.field]
                                    ? formInputValue[0][column.field]
                                    : 'defalut'
                                }
                                size='small'
                                sx={{ margin: '10px 0 0', width: '100%' }}
                              >
                                <MenuItem value='defalut' hidden>
                                  選択してください
                                </MenuItem>
                                {formTypeList.map((data, index) => (
                                  <MenuItem key={index} value={data.id}>
                                    {data.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          </TableCell>
                        ) : (
                          <TableCell sx={{ border: 0, padding: '16px 0' }}>
                            {column.valueOptions && column.valueOptions.length > 0 && (
                              <TextField
                                name={column.field}
                                onChange={(e) =>
                                  handleChangeUpdateForm(
                                    e,
                                    column.formType === 'number' ? 'number' : 'text'
                                  )
                                }
                                select
                                defaultValue={
                                  formInputValue[0][column.field]
                                    ? formInputValue[0][column.field]
                                    : 'defalut'
                                }
                                size='small'
                                sx={{ margin: '10px 0 0', width: '100%' }}
                              >
                                <MenuItem value='defalut' hidden>
                                  選択してください
                                </MenuItem>
                                {column.valueOptions.map((data, index) => (
                                  <MenuItem key={index} value={data}>
                                    {data}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          </TableCell>
                        )}
                      </>
                    ) : (
                      <TableCell sx={{ border: 0, padding: '16px 0' }}>
                        <TextField
                          name={column.field}
                          onChange={(e) =>
                            handleChangeUpdateForm(
                              e,
                              column.formType === 'number' ? 'number' : 'text'
                            )
                          }
                          value={
                            formInputValue[0][column.field] !== undefined
                              ? formInputValue[0][column.field]
                              : ''
                          }
                          disabled={column.disabled}
                          sx={{ width: '260px' }}
                          size='small'
                        />
                      </TableCell>
                    )}
                  </TableRow>
                )
            )}
            <TableRow sx={{ border: 0 }}>
              <TableCell
                sx={{ border: 0, paddingLeft: 0, position: 'relative', paddingRight: '50px' }}
              >
                販売価格/m
                <span style={{ position: 'absolute', right: '10px' }}>
                  <Required />
                </span>
              </TableCell>
              <TableCell sx={{ border: 0, padding: '16px 0' }}>
                <SelectSellingPriceModal
                  open={openSPModal}
                  setOpen={setOpenSPModal}
                  titleLeft='グループ名'
                  titleRight='販売価格/m'
                  datas={
                    formInputValue[0].selling_price_m.length ? formInputValue[0].selling_price_m : []
                  }
                  handleClickForm={handleClickSellingPriceForm}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell sx={{ border: 0, paddingLeft: 0 }}>加工費/m</TableCell>
              <TableCell sx={{ border: 0, padding: '16px 0' }}>
                <SelectProcessingCostModal
                  open={openPCModal}
                  setOpen={setOpenPCModal}
                  titleLeft='得意先'
                  titleRight='加工費/m'
                  datas={
                    formInputValue[0].processing_price.length
                      ? formInputValue[0].processing_price
                      : []
                  }
                  handleClickForm={handleClickProcessingCostForm}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <></>
      )}
      <Loading isLoading={isLoading} />
      <BorderTop />
      {formInputValue && formInputValue.length > 0 && (
        <div style={{ textAlign: 'right' }}>
          <LoadingButton
            disabled={isFormValid}
            onClick={handleClickUpdateForm}
            variant='contained'
            loading={addLoadingForm}
          >
            更新する
          </LoadingButton>
        </div>
      )}
    </>
  );
};

UpdateProductForm.propTypes = {
  api: PropTypes.string.isRequired, // Get○○
  params: PropTypes.array.isRequired, // getする際のinput
  columns: PropTypes.array.isRequired, // 詳細を取得するid
};
