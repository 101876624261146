import React, { useState } from 'react';
import PropTypes from 'prop-types';
// MUI
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
// コンポーネント
import { SubTitle } from 'components/uiParts/SubTitle';
import { BorderTop } from 'components/uiParts/BorderTop';
import { SelectProductModal } from 'components/uiParts/Modal/SelectProductModal';
import { CustomDatePicker } from 'components/uiParts/CustomDatePicker';
// カスタムフック
import { useProductModal } from 'hooks/useProductModal';
import { useCreateStockForm } from 'hooks/useStockForm';
import { useCommonFunction } from 'hooks/useCommonFunction';
import { Required } from 'components/uiParts/Required';
// 定数
import { PRODUCT_MODAL_COLUMNS_FOR_STOCK } from 'utils/constantsTable';

export const CreateStockForm = (props) => {
  const { api, columns, input, redirectUrl } = props;
  const { useGetToday } = useCommonFunction();
  const [selectedOrderDate, setSelectedOrderDate] = useState(useGetToday);
  const [createCount, setCreateCount] = useState(1);

  // カスタムフック
  const { openProductModal, setOpenProductModal, selectedProduct, handleClickSelectProduct } =
    useProductModal();

  const {
    isCreateFormValid,
    formInputValue,
    addLoadingForm,
    handleChangeCreateForm,
    handleClickCreateForm,
  } = useCreateStockForm(
    api,
    columns,
    input,
    redirectUrl,
    selectedProduct,
    selectedOrderDate,
    createCount
  );

  return (
    <>
      <SelectProductModal
        open={openProductModal}
        setOpen={setOpenProductModal}
        handleClickSelectProduct={handleClickSelectProduct}
        isButton={false}
        columns={PRODUCT_MODAL_COLUMNS_FOR_STOCK}
      />
      <div style={{ margin: '20px 0 0' }}>
        <SubTitle title='選択した商品' hideBorder={true} />
      </div>
      <Table
        sx={{ backgroundColor: '#EBEBEB', margin: '0', width: 'auto' }}
        size='small'
        aria-label='a dense table'
      >
        <TableBody>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ width: '130px', border: 0 }}>商品ID</TableCell>
            <TableCell sx={{ border: 0, padding: '16px' }}>
              {selectedProduct.id !== undefined ? selectedProduct.id : null}
            </TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ width: '150px', border: 0 }}>商品名</TableCell>
            <TableCell sx={{ width: '200px', border: 0, padding: '16px' }}>
              {selectedProduct.name !== undefined ? selectedProduct.name : null}
            </TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ border: 0 }}>厚み</TableCell>
            <TableCell sx={{ border: 0, padding: '16px' }}>
              {selectedProduct.thickness !== undefined ? selectedProduct.thickness : null}
            </TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ border: 0 }}>幅</TableCell>
            <TableCell sx={{ border: 0, padding: '16px' }}>
              {selectedProduct.width !== undefined ? selectedProduct.width : null}
            </TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ border: 0 }}>長さ</TableCell>
            <TableCell sx={{ border: 0, padding: '16px 16px 16px 0' }}>
              {selectedProduct.length !== undefined && (
                <TextField
                  onChange={(e) => handleChangeCreateForm(e, 'number')}
                  value={formInputValue.length !== undefined ? formInputValue.length : null}
                  name='length'
                  size='small'
                  type='number'
                />
              )}
            </TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ border: 0 }}>原価</TableCell>
            <TableCell sx={{ border: 0, padding: '16px 16px 16px 0' }}>
              {selectedProduct.cost_price !== undefined && (
                <TextField
                  onChange={(e) => handleChangeCreateForm(e, 'number')}
                  value={formInputValue.cost_price !== undefined ? formInputValue.cost_price : null}
                  name='cost_price'
                  size='small'
                  type='number'
                />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <BorderTop />
      <Table sx={{ margin: '20px 0 0' }} size='small' aria-label='a dense table'>
        <TableBody>
          {columns.map(
            (column, index) =>
              column.create && (
                <TableRow key={index} sx={{ border: 0 }}>
                  <TableCell sx={{ width: '120px', border: 0, paddingLeft: 0 }}>
                    {column.headerName}
                    {column.reauired && <Required />}
                  </TableCell>
                  <TableCell sx={{ border: 0, paddingLeft: 0 }}>
                    {column.formType === 'date' && (
                      <div style={{ width: '260px' }}>
                        <CustomDatePicker date={selectedOrderDate} setDate={setSelectedOrderDate} />
                      </div>
                    )}
                    {column.formType === 'select' &&
                      column.valueOptions &&
                      column.valueOptions.length > 0 && (
                        <TextField
                          name={column.field}
                          onChange={(e) =>
                            handleChangeCreateForm(
                              e,
                              column.formType === 'number' ? 'number' : 'text'
                            )
                          }
                          select
                          defaultValue={column.valueOptions[0]}
                          size='small'
                          sx={{ width: '260px' }}
                          required
                        >
                          {column.valueOptions.map((data, index) => (
                            <MenuItem key={index} value={data}>
                              {data}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    {column.formType !== 'select' && column.formType !== 'date' && (
                      <TextField
                        onChange={(e) =>
                          handleChangeCreateForm(
                            e,
                            column.formType === 'number' ? 'number' : 'text'
                          )
                        }
                        value={
                          formInputValue[column.field] !== undefined
                            ? formInputValue[column.field]
                            : null
                        }
                        name={column.field}
                        sx={{ width: '260px' }}
                        size='small'
                        type={column.formType === 'number' ? 'number' : 'text'}
                      />
                    )}
                  </TableCell>
                </TableRow>
              )
          )}
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ border: 0, paddingLeft: 0 }}>追加する個数</TableCell>
            <TableCell sx={{ border: 0, paddingLeft: 0 }}>
              <TextField
                onChange={(e) => setCreateCount(e.target.value)}
                value={createCount}
                name='count'
                sx={{ width: '260px' }}
                size='small'
                type='number'
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <BorderTop />
      <div style={{ textAlign: 'right' }}>
        <LoadingButton
          disabled={isCreateFormValid}
          onClick={handleClickCreateForm}
          variant='contained'
          loading={addLoadingForm}
        >
          在庫を登録する
        </LoadingButton>
      </div>
    </>
  );
};

CreateStockForm.propTypes = {
  api: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  input: PropTypes.object.isRequired,
  redirectUrl: PropTypes.string.isRequired,
};
