import React from 'react';
import PropTypes from 'prop-types';

import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from '@mui/x-data-grid';

import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { createTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        background: '#fff',
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
      textField: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

export const QuickSearchToolbar = (props) => {
  const {
    searchText,
    searchSecondText,
    searchThirdText,
    requestSearch,
    requestSecondSearch,
    requestThirdSearch,
    clearSearch,
    clearSecondSearch,
    clearThirdSearch,
    title,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex' }}>
        <GridToolbarFilterButton />
        <Box component='div' sx={{ textAlign: 'left' }}>
          <TextField
            style={{ margin: '0 5px 0 5px' }}
            variant='standard'
            value={searchText}
            onChange={requestSearch}
            placeholder='キーワード検索…'
            className={classes.textField}
            disabled={searchSecondText.length > 0}
            InputProps={{
              startAdornment: <SearchIcon fontSize='small' />,
              endAdornment: (
                <IconButton
                  title='Clear'
                  aria-label='Clear'
                  size='small'
                  style={{ visibility: searchText ? 'visible' : 'hidden' }}
                  onClick={clearSearch}
                  disabled={searchSecondText.length > 0}
                >
                  <ClearIcon fontSize='small' />
                </IconButton>
              ),
            }}
          />
          {searchText && (
            <TextField
              style={{ margin: '0 5px 0 5px' }}
              variant='standard'
              value={searchSecondText}
              onChange={requestSecondSearch}
              placeholder='2つ目の検索…'
              className={classes.textField}
              disabled={searchThirdText.length > 0}
              InputProps={{
                startAdornment: <SearchIcon fontSize='small' />,
                endAdornment: (
                  <IconButton
                    title='Clear'
                    aria-label='Clear'
                    size='small'
                    style={{ visibility: searchText ? 'visible' : 'hidden' }}
                    onClick={clearSecondSearch}
                    disabled={searchThirdText.length > 0}
                  >
                    <ClearIcon fontSize='small' />
                  </IconButton>
                ),
              }}
            />
          )}
          {searchSecondText && (
            <TextField
              style={{ margin: '0 5px 0 5px' }}
              variant='standard'
              value={searchThirdText}
              onChange={requestThirdSearch}
              placeholder='3つ目の検索…'
              className={classes.textField}
              InputProps={{
                startAdornment: <SearchIcon fontSize='small' />,
                endAdornment: (
                  <IconButton
                    title='Clear'
                    aria-label='Clear'
                    size='small'
                    style={{ visibility: searchSecondText ? 'visible' : 'hidden' }}
                    onClick={clearThirdSearch}
                  >
                    <ClearIcon fontSize='small' />
                  </IconButton>
                ),
              }}
            />
          )}
        </Box>
      </div>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport
          csvOptions={{
            fileName: title,
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    </div>
  );
};

QuickSearchToolbar.propTypes = {
  searchText: PropTypes.string.isRequired, // キーワード検索文
  searchSecondText: PropTypes.string.isRequired, // ２つ目のキーワード検索文
  searchThirdText: PropTypes.string.isRequired, // 3つ目のキーワード検索文
  requestSearch: PropTypes.func.isRequired, // キーワード検索文を入力した時に、配列内で検索を行う関数
  requestSecondSearch: PropTypes.func.isRequired, // 2つ目のキーワード検索文を入力した時に、配列内で検索を行う関数
  requestThirdSearch: PropTypes.func.isRequired, // 3つ目のキーワード検索文を入力した時に、配列内で検索を行う関数
  clearSearch: PropTypes.func.isRequired, // キーワード検索の初期化関数
  clearSecondSearch: PropTypes.func.isRequired, // キーワード検索の初期化関数
  clearThirdSearch: PropTypes.func.isRequired, // キーワード検索の初期化関数
  title: PropTypes.string.isRequired, // テーブルのタイトル
};
