import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
// MUI
import { Button, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AutorenewIcon from '@mui/icons-material/Autorenew';
// コンポーネント
import { CustomDatePicker } from 'components/uiParts/CustomDatePicker';
import { GridCellExpand } from 'components/uiParts/DataGrid/GridCellExpand';
import { SubTitle } from 'components/uiParts/SubTitle';
import { CustomDataGrid } from 'components/uiParts/DataGrid/CustomDataGrid';
import { ShipSlipOutFormModal } from 'components/uiParts/Modal/ShipSlipOutFormModal';
import { ProcessingBulkConfModal } from 'components/uiParts/Modal/ProcessingBulkConfModal';
// カスタムフック
import { useDataTable } from 'hooks/useDataTable';
import { useCommonFunction } from 'hooks/useCommonFunction';
import { useShipSlipOutput } from 'hooks/useShipSlipOutput';
import { useSnackBar } from 'hooks/useSnackBar';
import BigNumber from 'bignumber.js';

/**
 * セルの中身が多い場合に、ホバーすると表示されるテキストコンポーネントを呼ぶ関数
 * @see https://github.com/mui/mui-x/issues/417#issuecomment-782772202
 * @see https://codesandbox.io/s/bjupl?file=/demo.js:2836-2842
 * @return  セルの中身を表示
 */
function renderCellExpand(params) {
  if (params.value === undefined || params.value === null) return;
  return <GridCellExpand params={params} width={params.colDef.computedWidth} />;
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string.isRequired,
};

// 日付をYYYY-MM-DDの書式で返すメソッド
function formatDate(dt) {
  if (dt === '') return;
  var y = dt.getFullYear();
  var m = ('00' + (dt.getMonth() + 1)).slice(-2);
  var d = ('00' + dt.getDate()).slice(-2);
  return y + '-' + m + '-' + d;
}

export const EditDataTable = (props) => {
  const {
    update,
    emptyRows,
    editRows,
    setEditRows,
    editModalRows,
    setEditModalRows,
    title,
    columns,
    api,
    params,
    checkboxSelection,
    isDeleteBtn,
    vh = '80vh', // デフォルトは80vhとする
    selectedProductId,
    clientInfo,
    setClientInfo,
    selectedOrderDate,
    setSelectedOrderDate,
    setSelectedOrderMoney,
    setSelectedDeliveryDate,
    columnVisibilityList,
    isStockBtn,
    isMIOutputBtn,
    handleClickProcessingSheet,
    handleClickOpenSelectStock,
    handleClickOpenSelectStocks,
    setSelectedProductId,
    deleteIds,
    setDeleteIds,
    isPageReload,
    handleClickPrintShipSheet,
    handleClickProcessingAndStockBtn,
    isProcessingAndStockBtn,
    isPOutputBtn,
    isSortSubOrderGId,
    isSortSubOrderGIdForModal,
    selectedSubOrderData,
    setSubOrderGRows,
    isSubOrderModal,
    isSelectStocksBtn,
    setIsViewStockSelectList,
    setSelectedRows,
    rowSelectable,
    resetSelectedRowIds,
    getRows,
    addProductRows,
    setAddProductRows,
    selectedProductRow,
    subOrderGRows,
    selectedSubOrderDeliveryDate,
    setSelectedSubOrderDeliveryDate,
    setIsFormValidation,
    customDataGridStyle,
    cellEditStart,
    isPSelectOutputBtn,
    isSubPSelectOutputBtn,
    handleClickProcessingBulk,
    isDeleteBtnOrderDetail,
    updateHandleOpen,
  } = props;

  // カスタムフック
  const {
    rows,
    newRows,
    searchText,
    searchSecondText,
    searchThirdText,
    isLoading,
    selectedRows,
    selectedRowIds,
    setSelectedRowIds,
    setNewRows,
    requestSearch,
    requestSecondSearch,
    requestThirdSearch,
    handleClickSelectedRows,
    reacquireData,
  } = useDataTable(api, params);
  const { getStringFromDate, filteringOrderList, useGetToday } = useCommonFunction();
  const {
    openShipSlipOutModal,
    setOpenShipSlipOutModal,
    pdfDataList,
    setPdfDataList,
    handleClickShipSlipOutput,
  } = useShipSlipOutput(selectedRowIds, newRows);
  const { toggleSnack } = useSnackBar();

  const [dataColumns, setDataColumns] = useState([]);
  const [deleteId, setDeleteId] = useState(''); // 登録時に使用する削除するIDが入る
  const [editDeliveryDateRow, setEditDeliveryDateRow] = useState('');
  const [isShipSlipOutput, setIsShipSlipOutput] = useState(true);
  const [openProcessingBulkConfModal, setOpenProcessingBulkConfModal] = useState(false);
  const [isConfLoading, setIsConfLoading] = useState(false);

  // columnに、rederCellExpandを追加する事で、セルの折り返し内容をホバーした時に表示させる
  useEffect(() => {
    columns.map((column) => {
      if (column['renderCell'] === undefined) {
        column['renderCell'] = renderCellExpand;
      }
    });
  }, [columns]);

  useEffect(() => {
    const dataColumns = [...columns];

    // 納品日編集可の場合、CustomDatePickerを追加
    const deliveryDateIdx = dataColumns.findIndex((d) => d.field === 'delivery_date' && d.editable); // 納品日のindexを取得
    if (deliveryDateIdx >= 0) {
      dataColumns[deliveryDateIdx] = {
        ...dataColumns[deliveryDateIdx],
        ...{
          renderCell: (params) => (
            <>
              <CustomDatePicker
                addStyle={{
                  '.MuiOutlinedInput-input': {
                    fontSize: '13px',
                    padding: 0,
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
                date={params.value}
                setDate={setSelectedSubOrderDeliveryDate}
                params={params}
                getRow={setEditDeliveryDateRow}
                cellEditStart={cellEditStart}
              />
            </>
          ),
        },
      };
    }

    isProcessingAndStockBtn &&
      dataColumns.unshift({
        field: 'ProcessingAndStockBtn',
        headerName: '加工/在庫',
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        // eslint-disable-next-line react/display-name
        renderCell: (params) => (
          <IconButton
            onClick={() => handleClickProcessingAndStockBtn(params)}
            style={{ margin: 'auto' }}
            aria-label='delete'
            color='primary'
            disabled={String(params.row.id).split('_')[0] === 'add'} // idの先頭にaddがある場合は登録前のレコードなので無効にする
          >
            <MoreHorizIcon />
          </IconButton>
        ),
        create: false,
        update: false,
      });

    isPOutputBtn &&
      dataColumns.unshift({
        field: 'POutputBtn',
        headerName: '加工指示出力',
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        // eslint-disable-next-line react/display-name
        renderCell: (params) => (
          <IconButton
            onClick={() => handleClickProcessingSheet(params)}
            style={{ margin: 'auto' }}
            aria-label='delete'
            color='primary'
          >
            <FileDownloadIcon />
          </IconButton>
        ),
        create: false,
        update: false,
      });

    // 在庫選択追加
    isStockBtn &&
      dataColumns.unshift({
        field: 'stockBtn',
        headerName: '在庫選択',
        sortable: false,
        width: 90,
        disableClickEventBubbling: true,
        // eslint-disable-next-line react/display-name
        renderCell: (params) => (
          <IconButton
            onClick={() => handleClickOpenSelectStock(params.row.id, params)}
            style={{ margin: 'auto' }}
            aria-label='delete'
            color='primary'
            disabled={params.row.outsourcing === 'する'}
          >
            <MoreHorizIcon />
          </IconButton>
        ),
        create: false,
        update: false,
      });

    // 削除ボタン追加
    isDeleteBtn &&
      dataColumns.push({
        field: 'deleteBtn',
        headerName: '削除',
        sortable: false,
        width: 90,
        disableClickEventBubbling: true,
        // eslint-disable-next-line react/display-name
        renderCell: (params) => (
          <IconButton
            onClick={() => handleClickDeleteRow(params.id, params.row)}
            style={{ margin: 'auto' }}
            aria-label='delete'
            color='primary'
            disabled={params.row.film_products_master_id === null}
          >
            <DeleteIcon />
          </IconButton>
        ),
        create: false,
        update: false,
      });

    // 削除ボタン追加(受注詳細用)
    isDeleteBtnOrderDetail &&
      dataColumns.push({
        field: 'deleteBtn',
        headerName: '削除',
        sortable: false,
        width: 90,
        disableClickEventBubbling: true,
        // eslint-disable-next-line react/display-name
        renderCell: (params) => (
          <IconButton
            onClick={() => handleClickDeleteRow(params.id, params.row)}
            style={{ margin: 'auto' }}
            aria-label='delete'
            color='primary'
            disabled={params.row.NotDeleteBtn}
          >
            <DeleteIcon />
          </IconButton>
        ),
        create: false,
        update: false,
      });

    setDataColumns(dataColumns);
  }, [editRows]);

  // セルの更新
  const changeCell = (v) => {
    if (!newRows.length) return;
    let copyNewValue = JSON.parse(JSON.stringify(newRows)); // コピー
    let copyNewValueIdx = null;

    // 詳細画面
    if (update) {
      let copyEditRows = [];

      if (isSubOrderModal) {
        // 注文モーダルの場合
        copyEditRows = JSON.parse(JSON.stringify(editModalRows)); // コピー
      } else {
        // 詳細の場合
        copyEditRows = JSON.parse(JSON.stringify(editRows)); // コピー
      }

      copyNewValueIdx = newRows.findIndex((d) => d.id === v.id); // 該当データのindexを取得
      let value = v.value;

      if (v.field === 'delivery_date') {
        // 日付(Date)型→文字列(String)型に変換
        value = getStringFromDate(v.value);
      }
      copyNewValue[copyNewValueIdx][v.field] = value;

      // 長さが変更されたら再計算する
      if (v.field === 'order_length') {
        copyNewValue = calculate(copyNewValue, copyNewValueIdx);
      }

      // 外注が切り替わった時
      if (v.field === 'outsourcing') {
        if (copyNewValue[copyNewValueIdx]['status'] !== '出荷済') {
          if (value === 'しない') {
            copyNewValue[copyNewValueIdx][v.field] = value;
            copyNewValue[copyNewValueIdx]['status'] = '新規';
          }
          if (value === 'する') {
            copyNewValue[copyNewValueIdx][v.field] = value;
            copyNewValue[copyNewValueIdx]['status'] = '加工済';
          }
        }
      }

      const editIdx = copyEditRows.findIndex((d) => d.id === copyNewValue[copyNewValueIdx].id); // 該当データのindexを取得
      if (editIdx >= 0) {
        // 存在する場合は上書き
        copyEditRows[editIdx] = copyNewValue[copyNewValueIdx];
      } else {
        // 存在しない場合は追加
        copyEditRows.push(copyNewValue[copyNewValueIdx]);
      }

      setNewRows(copyNewValue);

      if (isSubOrderModal) {
        setEditModalRows(copyEditRows); // データを置き換える
      } else {
        setEditRows(copyEditRows); // データを置き換える
      }
    } else {
      let copyEditRows = JSON.parse(JSON.stringify(editRows)); // コピー

      // 登録画面
      copyNewValueIdx = newRows.findIndex((d) => d.id === Number(v.value)); // 該当データのindexを取得
      let copyEditRowsIdx = editRows.findIndex((d) => d.id === v.id); // 該当データのindexを取得
      let changeFlg = false;
      if (v.field === 'film_products_master_id') {
        // 商品変更の場合はchangeFlgをtrueにする
        if (copyEditRows[copyEditRowsIdx].film_products_master_id === null) {
          changeFlg = true;
        }

        //  idの場合はnewRowsから当てはまる商品情報を追加する
        if (copyNewValueIdx >= 0) {
          // 登録画面の場合は同じ商品が複数選択する場合があり、idは重複できないのでProductIdに入れる
          copyNewValue[copyNewValueIdx].film_products_master_id = copyNewValue[copyNewValueIdx].id;
          copyNewValue[copyNewValueIdx].order_number = 1; // 注文数は初期値を1とする
          copyNewValue[copyNewValueIdx].ProductWeight = copyNewValue[copyNewValueIdx].weight; // 商品の重さは注文の重さの計算で使用するのでProductWeightに保持する
          copyNewValue[copyNewValueIdx].ProductLength = copyNewValue[copyNewValueIdx].length; // 商品の長さは重さの計算で使用するのでProductLengthに保持する
          copyNewValue[copyNewValueIdx].length = 0; // 長さはnullにする
          copyNewValue[copyNewValueIdx].unit = '枚'; // 単位は枚にする
          copyNewValue[copyNewValueIdx].delivery_date = ''; // 納品日は空にする
          copyNewValue[copyNewValueIdx].order_process = '開反'; // 加工方法は開反とする
          copyEditRows[copyEditRowsIdx] = copyNewValue[copyNewValueIdx]; // newRowで取得した商品IDのオブジェクトを追加
          copyNewValue[copyNewValueIdx].outsourcing = 'しない'; // 外注はしないにする

          // 商品変更の場合は空行を追加しない
          if (changeFlg) {
            copyEditRows.push(emptyRows[0]); // 新しい空行を追加
          }

          // 登録画面の場合は同じ商品が複数選択する場合があり、idは重複できないので自動採番する
          for (let i = 0; i < copyEditRows.length; i++) {
            copyEditRows[i].id = String(i);
          }
        }
      } else {
        //  id以外は入力した値を追加する
        let value = v.value;

        if (v.field === 'delivery_date') {
          // 日付(Date)型→文字列(String)型に変換
          value = getStringFromDate(v.value);
        }

        copyEditRows[copyEditRowsIdx][v.field] = value;
      }

      // 注文金額を計算する ※原価~注文金額を手動入力可にするため計算は行わない
      if (
        v.field !== 'cost_price' &&
        v.field !== 'unit_price' &&
        v.field !== 'processing_price_value' &&
        v.field !== 'sub_total' &&
        v.field !== 'amount_money'
      ) {
        copyEditRows = calculate(copyEditRows, copyEditRowsIdx);
      }
      setEditRows(copyEditRows); // データを置き換える
    }
  };

  // 登録画面にて「商品を選択する」から商品が決定されたらレコードに追加する
  useEffect(() => {
    if (update) return;
    if (!newRows.length) return;
    if (!editRows.length) return;
    if (selectedProductId === null) return;
    let copyNewValue = JSON.parse(JSON.stringify(newRows)); // コピー
    let copyNewValueIdx = newRows.findIndex((d) => d.id === selectedProductId); // 該当データのindexを取得

    let copyEditRows = JSON.parse(JSON.stringify(editRows)); // コピー

    if (copyNewValueIdx >= 0) {
      // 登録画面の場合は同じ商品が複数選択する場合があり、idは重複できないのでProductIdに入れる
      if (!update) {
        copyNewValue[copyNewValueIdx].film_products_master_id = copyNewValue[copyNewValueIdx].id;
      }
      copyEditRows[copyEditRows.length - 1] = copyNewValue[copyNewValueIdx]; // 最後に商品のオブジェクトを追加

      for (let k in emptyRows[0]) {
        // 足りないプロパティを追加する
        if (k in copyEditRows[copyEditRows.length - 1] === false) {
          copyEditRows[copyEditRows.length - 1][k] = emptyRows[0][k];
        }
      }

      copyEditRows[copyEditRows.length - 1].ProductWeight =
        copyEditRows[copyEditRows.length - 1].weight; // 商品の重さは注文の重さの計算で使用するのでProductWeightに保持する
      copyEditRows[copyEditRows.length - 1].ProductLength =
        copyEditRows[copyEditRows.length - 1].length; // 商品の長さは重さの計算で使用するのでProductLengthに保持する
      copyEditRows[copyEditRows.length - 1].length = null; // 注文時の最初の長さはnullとする
      copyEditRows[copyEditRows.length - 1].cost_price = 0; // 原価は0とする
      copyEditRows[copyEditRows.length - 1].unit_price = 0; // 単価は0とする
      copyEditRows[copyEditRows.length - 1].processing_price_value = 0; // 加工費は0とする
      copyEditRows[copyEditRows.length - 1].sub_total = 0; // 小計は0とする
      copyEditRows[copyEditRows.length - 1].amount_money = 0; // 注文金額は0とする
      copyEditRows[copyEditRows.length - 1].order_process = '開反'; // 加工方法は開反とする
      copyEditRows[copyEditRows.length - 1].order_number =
        selectedProductRow.order_number !== undefined ? selectedProductRow.order_number : 1; // 入力した注文数を入れる
      copyEditRows[copyEditRows.length - 1].outsourcing = 'しない'; // 外注はしないにする

      copyEditRows.push(emptyRows[0]); // 新しい空の行を追加
    }

    // 注文金額を計算する 最後は空行なのでその前のindex
    copyEditRows = calculate(copyEditRows, copyEditRows.length - 2);

    // 登録画面の場合は同じ商品が複数選択する場合があり、idは重複できないので自動採番する
    if (!update) {
      for (let i = 0; i < copyEditRows.length; i++) {
        copyEditRows[i].id = String(i);
      }
    }

    setEditRows(copyEditRows); // データを置き換える
    setSelectedProductId(null); // 選択済みの商品をリセット
  }, [selectedProductId, selectedProductRow]);

  // 詳細画面にて「商品を選択する」から商品が決定されたらレコードに追加する
  useEffect(() => {
    if (!update) return;
    if (isSortSubOrderGIdForModal) return;
    if (!Object.keys(selectedProductRow).length) return;

    let copyNewRows = JSON.parse(JSON.stringify(newRows)); // コピー
    let copyAddProductRows = JSON.parse(JSON.stringify(addProductRows)); // コピー

    if (selectedProductRow.id !== undefined) {
      const addObject = {
        id: 'add_' + new Date().getTime().toString(), // 同じ商品が選択される可能性もあるので、タイムスタンプを使いユニークなIDにする
        film_orders_master_id: params[0].film_orders_master_id, // 受注ID
        film_products_master_id: selectedProductRow.id !== undefined ? selectedProductRow.id : '', // 商品ID
        processing_product_name: selectedProductRow.name !== undefined ? selectedProductRow.name : '', // 商品名
        order_process: '開反', // 加工方法
        order_thickness: selectedProductRow.thickness !== undefined ? selectedProductRow.thickness : '', // 厚み
        order_width: selectedProductRow.width !== undefined ? selectedProductRow.width : '', // 幅
        ProductWeight: selectedProductRow.weight !== undefined ? selectedProductRow.weight : null, // 商品の重さは注文の重さの計算で使用するのでProductWeightに保持する
        ProductLength: selectedProductRow.length !== undefined ? selectedProductRow.length : null, // 商品の長さは重さの計算で使用するのでProductLengthに保持する
        order_length: '', // 長さ
        order_number: selectedProductRow.order_number !== undefined ? selectedProductRow.order_number : 1, // 注文数
        order_house_name: '', // ハウス名
        order_date: useGetToday(), // 受注日
        delivery_date: '', // 納品日
        order_remark: '', // 備考
        cost_price: 0, // 原価
        cost_price_m: selectedProductRow.cost_price_m !== undefined ? selectedProductRow.cost_price_m : 0, // 原価/m
        unit_price: 0, // 単価
        processing_price_value: 0, // 加工費
        sub_total: 0, // 小計
        amount_money: 0, // 注文金額
        specific_gravity:
          selectedProductRow.specific_gravity !== undefined ? selectedProductRow.specific_gravity : 0, // 比重
        weight: selectedProductRow.weight !== undefined ? selectedProductRow.weight : 0, // 重さ
        unit: '枚', // 単位
        selling_price_m:
          selectedProductRow.selling_price_m !== undefined ? selectedProductRow.selling_price_m : [], // 販売価格/m
        processing_price:
          selectedProductRow.processing_price !== undefined
            ? selectedProductRow.processing_price
            : [], // 加工費
        outsourcing: 'しない',
      };

      copyAddProductRows.push(addObject);
      copyNewRows.push(addObject);

      setAddProductRows(copyAddProductRows); // データを置き換える
      setNewRows(copyNewRows); // データを置き換える
    }
  }, [selectedProductRow]);

  // 登録画面にて顧客が変更されたら再計算する
  useEffect(() => {
    if (update) return;
    if (!editRows.length) return;
    if (!setEditRows) return;
    let copyEditRows = JSON.parse(JSON.stringify(editRows)); // コピー
    // 注文金額を計算する
    const array = [];
    for (let i = 0; i < copyEditRows.length; i++) {
      array.push(calculate([copyEditRows[i]], 0)[0]);
    }
    setEditRows(array); // データを置き換える
  }, [clientInfo]);

  /**
   * 注文に必要な項目の計算を行う
   * @param {Array} datas
   * @returns
   */
  const calculate = (datas, index) => {
    let deliveryDate = '';
    let costPriceResult = 0;
    let unitPriceResult = 0;
    let processingPriceResult = 0;
    let subtotalResult = 0;
    let amountMoneyResult = 0;

    // 原価を計算する（注文の長さ×商品の原価/m）
    if (
      datas[index].length !== undefined &&
      datas[index].length !== null &&
      datas[index].length !== 0 &&
      datas[index].cost_price_m !== undefined &&
      datas[index].cost_price_m !== null &&
      datas[index].cost_price_m !== 0
    ) {
      costPriceResult = BigNumber(datas[index].length).times(datas[index].cost_price_m);
    } else if (
      datas[index].order_length !== undefined &&
      datas[index].order_length !== null &&
      datas[index].order_length !== 0 &&
      datas[index].cost_price_m !== undefined &&
      datas[index].cost_price_m !== null &&
      datas[index].cost_price_m !== 0
    ) {
      costPriceResult = BigNumber(datas[index].order_length).times(datas[index].cost_price_m);
    }
    datas[index].cost_price = Number(costPriceResult.toFixed(2)); // 少数第二位まで表示し、第三位を四捨五入する

    // 単価を計算する（注文の長さ×販売価格/m）
    if (
      datas[index].length !== undefined &&
      datas[index].length !== null &&
      datas[index].length !== 0 &&
      datas[index].selling_price_m !== undefined &&
      datas[index].selling_price_m !== null &&
      datas[index].selling_price_m[clientInfo.salesPriceCategoryId - 1] !== undefined
    ) {
      let num = clientInfo.salesPriceCategoryId - 1;
      if (num >= 0) {
        unitPriceResult = BigNumber(datas[index].selling_price_m[num]).times(datas[index].length);
      }
    } else if (
      datas[index].order_length !== undefined &&
      datas[index].order_length !== null &&
      datas[index].order_length !== 0 &&
      datas[index].selling_price_m !== undefined &&
      datas[index].selling_price_m !== null &&
      datas[index].selling_price_m[clientInfo.salesPriceCategoryId - 1] !== undefined
    ) {
      let num = clientInfo.salesPriceCategoryId - 1;
      if (num >= 0) {
        unitPriceResult = BigNumber(datas[index].selling_price_m[num]).times(datas[index].order_length);
      }
    }
    datas[index].unit_price = Number(Math.round(unitPriceResult));

    // 加工費を計算する（注文の長さ×商品の加工費区分/m）
    if (
      datas[index].length !== undefined &&
      datas[index].length !== null &&
      datas[index].length !== 0 &&
      datas[index].processing_price !== undefined &&
      datas[index].processing_price !== null &&
      datas[index].processing_price[clientInfo.processingPriceCategoryId - 1] !== undefined
    ) {
      let num = clientInfo.processingPriceCategoryId - 1;
      if (num >= 0) {
        processingPriceResult = BigNumber(datas[index].processing_price[num]).times(
          datas[index].length
        );
      }
    } else if (
      datas[index].order_length !== undefined &&
      datas[index].order_length !== null &&
      datas[index].order_length !== 0 &&
      datas[index].processing_price !== undefined &&
      datas[index].processing_price !== null &&
      datas[index].processing_price[clientInfo.processingPriceCategoryId - 1] !== undefined
    ) {
      let num = clientInfo.processingPriceCategoryId - 1;
      if (num >= 0) {
        processingPriceResult = BigNumber(datas[index].processing_price[num]).times(
          datas[index].order_length
        );
      }
    }
    datas[index].processing_price_value = Number(Math.round(processingPriceResult));

    // 小計を計算する（単価+加工費）
    if (
      datas[index].unit_price !== undefined &&
      datas[index].unit_price !== null &&
      datas[index].processing_price_value !== undefined &&
      datas[index].processing_price_value !== null
    ) {
      subtotalResult = BigNumber(datas[index].unit_price).plus(datas[index].processing_price_value);
    }
    datas[index].sub_total = Math.round(subtotalResult);

    // 注文金額を計算する（小計×注文数）
    if (
      datas[index].sub_total !== undefined &&
      datas[index].sub_total !== null &&
      datas[index].sub_total !== 0 &&
      datas[index].order_number !== undefined &&
      datas[index].order_number !== null &&
      datas[index].order_number !== 0
    ) {
      amountMoneyResult = BigNumber(datas[index].sub_total).times(datas[index].order_number);
    }
    datas[index].amount_money = Math.round(amountMoneyResult);

    // 注文に受注日を追加
    if (selectedOrderDate) {
      datas[index].order_date = selectedOrderDate;
    }

    // 直近の納品日を探す
    for (let i = 0; i < datas.length; i++) {
      // 直近の納品日を探す
      deliveryDate = datas[i].delivery_date;
      if (new Date(deliveryDate) < new Date(datas[i].delivery_date)) {
        deliveryDate = datas[i].delivery_date;
      }
    }

    // 重さを計算する（商品の重さ÷商品の長さ×注文長さ）
    let weight = 0;
    const length =
      (datas[index].length !== undefined && datas[index].length) || // 登録画面ではカラム名がLength
      (datas[index].order_length !== undefined && datas[index].order_length); // 更新画面ではカラム名がorder_length
    if (
      datas[index].ProductWeight !== undefined &&
      datas[index].ProductWeight !== null &&
      datas[index].ProductWeight !== 0 &&
      datas[index].ProductLength !== undefined &&
      datas[index].ProductLength !== null &&
      datas[index].ProductLength !== 0 &&
      length
    ) {
      // 登録画面
      let weightProduct = BigNumber(datas[index].ProductWeight).div(datas[index].ProductLength);
      weight = BigNumber(weightProduct).times(length);
    } else if (
      datas[index].weight !== undefined &&
      datas[index].weight !== null &&
      datas[index].weight !== 0 && length
    ) {
      // 更新画面
      weight = BigNumber(datas[index].weight).times(length);
    }
    datas[index].weight = Number(weight.toFixed(1));

    // 合計重量を計算する（重さ×注文数）
    let totalWeight = 0;
    if (
      datas[index].weight !== undefined &&
      datas[index].weight !== null &&
      datas[index].weight !== 0 &&
      datas[index].order_number !== undefined &&
      datas[index].order_number !== null &&
      datas[index].order_number !== 0
    ) {
      totalWeight = BigNumber(datas[index].weight).times(datas[index].order_number);
    }
    datas[index].total_weight = Number(totalWeight);

    return datas;
  };

  // 受注金額の計算
  useEffect(() => {
    if (!editRows.length) return;
    if (!newRows.length) return;
    if (update) return;
    let copyRows = [];
    // 更新の場合はnewRows
    if (update) {
      copyRows = JSON.parse(JSON.stringify(newRows)); // コピー
    } else {
      copyRows = JSON.parse(JSON.stringify(editRows)); // コピー
    }
    let orderMoney = 0;
    for (let i = 0; i < copyRows.length; i++) {
      if (update) {
        if (copyRows[i].amount_money > 0) {
          // 更新の場合は小計(sub_total)の合計値とする
          orderMoney += copyRows[i].sub_total;
        }
      } else {
        if (copyRows[i].amount_money > 0) {
          // 登録の場合は注文金額(amount_money)*注文数(order_number)の合計値とする
          orderMoney += copyRows[i].amount_money;
        }
      }
    }
    setSelectedOrderMoney(orderMoney);
  }, [editRows]);

  // 詳細画面用
  useEffect(() => {
    if (!newRows.length) return;
    if (!update) return;
    if (!handleClickProcessingAndStockBtn) return;

    // 顧客情報取得
    setClientInfo({
      id: newRows[0].film_clients_master_id,
      name: newRows[0].processing_client_name,
      salesPriceCategoryId: newRows[0].film_sales_price_categories_master_id,
      processingPriceCategoryId: newRows[0].film_processing_price_categories_master_id,
      titleOfHonor: newRows[0].processing_title_of_honor,
    });

    let orderDate = '';
    let countAmountMoney = 0;
    let statusList = [];
    for (let i = 0; i < newRows.length; i++) {
      // 受注日取得
      if (orderDate === '') {
        orderDate = newRows[i].order_date;
      } else if (new Date(orderDate) > new Date(newRows[i].order_date)) {
        orderDate = newRows[i].order_date;
      }

      // 受注金額計算
      if (newRows[i].amount_money !== undefined) {
        countAmountMoney += Number(newRows[i].amount_money);
      }

      // ステータスリスト作成
      if (newRows[i].status !== undefined) {
        statusList.push(newRows[i].status);
      }
    }
    setSelectedOrderDate(orderDate);
    setSelectedOrderMoney(countAmountMoney);
  }, [newRows]);

  // 詳細画面用 受注日を注文の受注日に反映する
  useEffect(() => {
    if (!newRows.length) return;
    if (!emptyRows.length) return;
    if (!update) return;
    if (selectedOrderDate === '') return;

    let copyNewRows = JSON.parse(JSON.stringify(newRows)); // コピー
    for (let i = 0; i < copyNewRows.length; i++) {
      copyNewRows[i].order_date = selectedOrderDate;
    }

    setNewRows(copyNewRows);
    setEditRows(copyNewRows);
  }, [selectedOrderDate]);

  // 削除ボタンが押されたらレコードを削除
  const handleClickDeleteRow = (dId, data) => {
    if (dId === null || dId === undefined) return;
    const copyEditRows = JSON.parse(JSON.stringify(editRows)); // コピー
    const copyEditRowsIdx = editRows.findIndex((d) => d.id === dId); // 該当データのindexを取得
    // 詳細画面
    if (update) {
      // idの先頭にaddがある場合は登録前のレコードなので削除処理のAPIは実行しない
      if (String(dId).split('_')[0] !== 'add') {
        setDeleteIds((arr) => [...arr, dId]);
      }
      setDeleteId(dId);

      // 編集配列に入っていたら削除
      if (copyEditRowsIdx >= 0) {
        copyEditRows.splice(copyEditRowsIdx, 1); // 該当データのオブジェクトを削除
        setEditRows(copyEditRows); // データを置き換える
      }
    } else {
      // 登録画面
      copyEditRows.splice(copyEditRowsIdx, 1); // 該当データのオブジェクトを削除
      setEditRows(copyEditRows); // データを置き換える
    }
  };

  // 詳細画面レコードを削除
  useEffect(() => {
    if (!update) return;
    if (deleteId === null || deleteId === undefined) return;
    const copyNewRows = JSON.parse(JSON.stringify(newRows)); // コピー
    let copyNewRowsIdx = copyNewRows.findIndex((d) => d.id === deleteId); // 該当データのindexを取得
    copyNewRows.splice(copyNewRowsIdx, 1); // 該当データのオブジェクトを削除
    setNewRows(copyNewRows); // データを置き換える
  }, [deleteId]);

  // 再取得する
  useEffect(() => {
    if (!isPageReload) return;
    reacquireData();
  }, [isPageReload]);

  // 受注詳細用にフィルタリングする
  // 【SubOrderGId：受注ID_1_1】 _で分割して[2]が1のデータだけをフィルタリングする
  // 原価～注文金額は分割した[1]が同じデータを足し算する
  useEffect(() => {
    if (!update) return;
    if (!isSortSubOrderGId) return;

    setNewRows(filteringOrderList(rows)[0]); // データを置き換える
    setSubOrderGRows(filteringOrderList(rows)[1]); // 更新用に各注文をオブジェクトとしてまとめておく
  }, [rows]);

  // 受注詳細のモーダル用にフィルタリングする
  // 【SubOrderGId：受注ID_1_1】 _で分割して[1]が同じデータだけをフィルタリングする
  useEffect(() => {
    if (!update) return;
    if (!isSortSubOrderGIdForModal) return;
    if (!selectedSubOrderData && !Object.keys(selectedSubOrderData).length) return;

    const copyRows = JSON.parse(JSON.stringify(rows)); // コピー
    const sortRow = [];
    for (let i = 0; i < copyRows.length; i++) {
      if (copyRows[i].film_orders_master_id !== undefined && copyRows[i].sub_order_group_id !== undefined) {
        let selectedSubOrderGIdAry = selectedSubOrderData.row.sub_order_group_id.split('_');
        let subOrderGIdAry = copyRows[i].sub_order_group_id.split('_');
        if (selectedSubOrderGIdAry[1] === subOrderGIdAry[1]) {
          sortRow.push(copyRows[i]);
        }
      }
    }
    setNewRows(sortRow); // データを置き換える
  }, [rows]);

  // 選択中の行のデータをまとめる
  useEffect(() => {
    if (!update) return;
    if (!setSelectedRows) return;
    if (!selectedRowIds.length) return;

    const list = [];
    for (let i = 0; i < selectedRowIds.length; i++) {
      const copyNewValueIdx = newRows.findIndex((d) => d.id === selectedRowIds[i]); // 該当データのindexを取得
      list.push(newRows[copyNewValueIdx]);
    }

    setSelectedRows(list);
  }, [selectedRowIds, newRows]);

  // 選択中の行のデータをまとめる
  useEffect(() => {
    const list = [];
    for (let i = 0; i < selectedRowIds.length; i++) {
      const copyNewValueIdx = newRows.findIndex((d) => d.id === selectedRowIds[i]); // 該当データのindexを取得
      list.push(newRows[copyNewValueIdx]);
    }
    setIsShipSlipOutput(false);
    if (list.length === 0) {
      setIsShipSlipOutput(true);
    }
    for (let i = 0; i < list.length; i++) {
      if (list[i]['isShipSlipOutput'] === false) {
        setIsShipSlipOutput(true);
      }
    }
  }, [selectedRowIds, newRows]);

  useEffect(() => {
    if (!update) return;
    if (!resetSelectedRowIds) return;
    setSelectedRowIds([]);
  }, [resetSelectedRowIds]);

  /**
   * データを取得する
   */
  useEffect(() => {
    if (!getRows) return;
    if (!rows.length) return;
    getRows(rows);
  }, [rows, getRows]);

  // 納品日を選択
  useEffect(() => {
    if (selectedSubOrderDeliveryDate === '') return;
    if (!Object.keys(editDeliveryDateRow).length) return;

    if (!update) {
      if (!editRows.length) return;

      // 登録画面
      let copyRows = JSON.parse(JSON.stringify(editRows)); // コピー

      // 先頭の納品日が登録されたら同様の日付を下の注文に反映する
      if (editDeliveryDateRow.id === '0') {
        for (let i = 0; i < copyRows.length; i++) {
          // 商品IDがない注文はスキップ
          if (copyRows[i].film_products_master_id === null) {
            continue;
          }
          copyRows[i].delivery_date = selectedSubOrderDeliveryDate;
        }
      } else {
        const idx = copyRows.findIndex((d) => d.id === editDeliveryDateRow.id); // indexを取得
        copyRows[idx].delivery_date = selectedSubOrderDeliveryDate;
      }

      setEditRows(copyRows);
    } else {
      if (!newRows.length) return;

      // 詳細画面
      let copyRows = JSON.parse(JSON.stringify(newRows)); // コピー
      let copyEditRows = JSON.parse(JSON.stringify(editRows)); // コピー
      const editIdx = copyEditRows.findIndex((d) => d.id === editDeliveryDateRow.id); // 該当データのindexを取得
      if (editIdx >= 0) {
        // 存在する場合は上書き
        copyEditRows[editIdx] = {
          ...editDeliveryDateRow,
          ...{ delivery_date: selectedSubOrderDeliveryDate },
        };
      } else {
        // 存在しない場合は追加
        copyEditRows.push({
          ...editDeliveryDateRow,
          ...{ delivery_date: selectedSubOrderDeliveryDate },
        });
      }

      const idx = copyRows.findIndex((d) => d.id === editDeliveryDateRow.id); // indexを取得
      copyRows[idx].delivery_date = selectedSubOrderDeliveryDate;

      setNewRows(copyRows);
      setEditRows(copyEditRows);
    }
  }, [selectedSubOrderDeliveryDate, editDeliveryDateRow]);

  // 必須項目の入力チェック
  useEffect(() => {
    if (!setIsFormValidation) return;
    if (!dataColumns) return;

    let flg = false;
    let copyRows = [];
    if (!update) {
      // 登録画面
      copyRows = JSON.parse(JSON.stringify(editRows)); // コピー
    } else {
      // 詳細画面
      copyRows = JSON.parse(JSON.stringify(newRows)); // コピー
    }

    if (copyRows.length > 0) {
      for (let i = 0; i < copyRows.length; i++) {
        // 登録画面のみ最下部に空レコードがあるため最後のループは行わない
        if (!update && i === copyRows.length - 1) {
          continue;
        }
        for (let c = 0; c < dataColumns.length; c++) {
          if (
            (dataColumns[c].reauired && copyRows[i][dataColumns[c].field] === '') ||
            (dataColumns[c].reauired && copyRows[i][dataColumns[c].field] === null) ||
            (dataColumns[c].reauired && copyRows[i][dataColumns[c].field] === undefined)
          ) {
            // 必須項目が空の場合はtrueにする
            flg = true;
          }
        }
      }
    }
    setIsFormValidation(flg);
  }, [newRows, editRows, dataColumns]);

  // 受注の納品日作成
  // 注文の中で一番近い日付を探す
  useEffect(() => {
    if (!setSelectedDeliveryDate) return;

    let copyRows = [];
    if (!update) {
      // 登録画面
      copyRows = JSON.parse(JSON.stringify(editRows)); // コピー
    } else {
      // 詳細画面
      copyRows = JSON.parse(JSON.stringify(newRows)); // コピー
    }

    if (copyRows.length > 0) {
      // delivery_dateの昇順
      copyRows = copyRows.sort(function (a, b) {
        if (a.delivery_date && b.delivery_date && a.delivery_date > b.delivery_date) return 1;
        if (a.delivery_date && b.delivery_date && b.delivery_date > a.delivery_date) return -1;

        return 0;
      });
      setSelectedDeliveryDate(copyRows[0].delivery_date);
    }
  }, [newRows, editRows]);

  // 加工指示ボタンを押した時
  const selectProcessingClick = () => {
    let selectProcessingRows = { row: [] };
    for (let i = 0; i < selectedRows.length; i++) {
      let splitGid = selectedRows[i]['sub_order_group_id'].split('_');
      for (let i = 0; i < rows.length; i++) {
        let splitRowsGid = rows[i]['sub_order_group_id'].split('_');
        if ((splitRowsGid[0] === splitGid[0]) & (splitRowsGid[1] === splitGid[1])) {
          selectProcessingRows['row'].push(rows[i]);
        }
      }
    }
    handleClickProcessingSheet(selectProcessingRows);
  };

  const selectSubProcessingClick = () => {
    let selectProcessingRows = { row: [] };
    selectProcessingRows['row'] = selectedRows;
    handleClickProcessingSheet(selectProcessingRows);
  };

  // 一括加工済変更
  const selectProcessingBulkClick = () => {
    setIsConfLoading(true);
    handleClickProcessingBulk(newRows);
  };

  return (
    <>
      <div className='orderPage' style={{ display: 'flex', width: '100%', height: vh }}>
        <div style={{ flexGrow: 1 }}>
          <SubTitle title={title} />
          <div style={{ marginBottom: '10px' }}>
            {isMIOutputBtn && (
              <Button
                onClick={handleClickShipSlipOutput}
                variant='contained'
                color='primary'
                startIcon={<FileDownloadIcon />}
                disabled={isShipSlipOutput}
              >
                出荷伝票
              </Button>
            )}
            {isSelectStocksBtn && (
              <Button
                onClick={() => handleClickOpenSelectStocks()}
                variant='contained'
                color='primary'
                startIcon={<FileDownloadIcon />}
                disabled={selectedRowIds.length > 0 ? false : true}
                style={{ marginLeft: '10px' }}
              >
                一括在庫選択
              </Button>
            )}
            {isPSelectOutputBtn && (
              <Button
                onClick={() => selectProcessingClick()}
                variant='contained'
                color='primary'
                startIcon={<FileDownloadIcon />}
                disabled={selectedRowIds.length > 0 ? false : true}
                style={{ marginLeft: '10px' }}
              >
                加工指示出力
              </Button>
            )}
            {isSubPSelectOutputBtn && (
              <Button
                onClick={() => selectSubProcessingClick()}
                variant='contained'
                color='primary'
                startIcon={<FileDownloadIcon />}
                disabled={selectedRowIds.length > 0 ? false : true}
                style={{ marginLeft: '10px' }}
              >
                加工指示出力
              </Button>
            )}
            {isSubPSelectOutputBtn && (
              <Button
                onClick={() => setOpenProcessingBulkConfModal(true)}
                variant='contained'
                color='primary'
                startIcon={<AutorenewIcon />}
                style={{ marginLeft: '10px' }}
              >
                一括加工済変更
              </Button>
            )}
            {updateHandleOpen && (
              <Button onClick={updateHandleOpen} variant='contained' style={{ marginLeft: '10px' }}>
                商品規格選択
              </Button>
            )}
          </div>
          <ProcessingBulkConfModal
            open={openProcessingBulkConfModal}
            setOpen={setOpenProcessingBulkConfModal}
            selectProcessingBulkClick={selectProcessingBulkClick}
            isConfLoading={isConfLoading}
          />
          <CustomDataGrid
            isEdit={true}
            rows={update ? newRows : editRows}
            columns={dataColumns}
            searchText={searchText}
            searchSecondText={searchSecondText}
            searchThirdText={searchThirdText}
            requestSearch={requestSearch}
            requestSecondSearch={requestSecondSearch}
            requestThirdSearch={requestThirdSearch}
            title={title}
            checkboxSelection={checkboxSelection}
            onSelectionModelChange={handleClickSelectedRows}
            isLoading={isLoading}
            autoHeight
            // onSelectionModelChange={(v) => (selRows.current = v)}
            changeCell={changeCell}
            hideSearchToolbar
            columnVisibilityList={columnVisibilityList}
            rowSelectable={rowSelectable}
            customDataGridStyle={customDataGridStyle}
            cellEditStart={cellEditStart}
          />
          <ShipSlipOutFormModal
            open={openShipSlipOutModal}
            setOpen={setOpenShipSlipOutModal}
            setSelectedRowIds={setSelectedRowIds}
            setPdfDataList={setPdfDataList}
            pdfDataList={pdfDataList}
            handleClickPrintShipSheet={handleClickPrintShipSheet}
          />
        </div>
      </div>
    </>
  );
};

EditDataTable.propTypes = {
  update: PropTypes.bool, // 詳細画面か
  emptyRows: PropTypes.array.isRequired,
  editRows: PropTypes.array.isRequired,
  editModalRows: PropTypes.array,
  setEditRows: PropTypes.func,
  setEditModalRows: PropTypes.func, // モーダル用
  checkboxSelection: PropTypes.bool, // チェックボックスを使うかどうか
  // isHide: PropTypes.bool, // テーブルを非表示にする true or false
  title: PropTypes.string.isRequired, // テーブルのタイトル
  columns: PropTypes.array.isRequired, // テーブルのカラム
  table: PropTypes.string, // テーブル名
  api: PropTypes.string, // Get○○
  params: PropTypes.array, // 引数
  isCreateBtn: PropTypes.bool, // 登録ボタンを表示する時に使う
  isDetailBtn: PropTypes.bool, // 詳細ボタンを表示する時に使う
  isDeleteBtn: PropTypes.bool, // 削除ボタンを表示する時に使う
  createUrl: PropTypes.string, // 登録画面のURL
  detailUrl: PropTypes.string, // 詳細画面のURL
  testRows: PropTypes.array, // テスト用の固定データ
  isStockBtn: PropTypes.bool, // 在庫選択ボタン
  autoHeight: PropTypes.bool,
  vh: PropTypes.string,
  handleClickChangeUCStatus: PropTypes.func, // 顧客ページの使用区分をクリックしたときの関数
  selectedProductId: PropTypes.number, // 商品選択から決定したID
  clientInfo: PropTypes.object, // 顧客情報
  setClientInfo: PropTypes.func, // 顧客情報
  selectedOrderDate: PropTypes.string, // 選択した受注日
  setSelectedOrderDate: PropTypes.func, // 選択した受注日
  setSelectedOrderMoney: PropTypes.func, // 受注金額
  setSelectedDeliveryDate: PropTypes.func, // 納品日
  columnVisibilityList: PropTypes.object, // カラムの表示を切り替えるオブジェクト
  isMIOutputBtn: PropTypes.bool, // 加工指示出力ボタン
  handleClickProcessingSheet: PropTypes.func, // 加工指示出力を押したときの関数
  handleClickOpenSelectStock: PropTypes.func,
  handleClickOpenSelectStocks: PropTypes.func,
  setSelectedProductId: PropTypes.func, // 商品IDを選択する
  deleteIds: PropTypes.array, // 詳細画面の削除IDのリストとして使う
  setDeleteIds: PropTypes.func, // 詳細画面の削除IDのリストとして使う
  handleClickPrintShipSheet: PropTypes.func,
  isPageReload: PropTypes.bool, // 更新後の再読み込み
  handleClickProcessingAndStockBtn: PropTypes.func, //
  isProcessingAndStockBtn: PropTypes.bool, //
  isPOutputBtn: PropTypes.bool, //
  isSortSubOrderGId: PropTypes.bool, //
  isSortSubOrderGIdForModal: PropTypes.bool, //
  selectedSubOrderData: PropTypes.object, //
  setSubOrderGRows: PropTypes.func, //
  isSubOrderModal: PropTypes.bool,
  isSelectStocksBtn: PropTypes.bool,
  setOpenStockModal: PropTypes.func,
  setIsViewStockSelectList: PropTypes.func,
  setSelectedRows: PropTypes.func,
  rowSelectable: PropTypes.func,
  resetSelectedRowIds: PropTypes.bool,
  getRows: PropTypes.func,
  addProductRows: PropTypes.array,
  setAddProductRows: PropTypes.func,
  selectedProductRow: PropTypes.object,
  subOrderGRows: PropTypes.object,
  selectedSubOrderDeliveryDate: PropTypes.string,
  setSelectedSubOrderDeliveryDate: PropTypes.func,
  setIsFormValidation: PropTypes.func,
  customDataGridStyle: PropTypes.object,
  cellEditStart: PropTypes.func,
  isPSelectOutputBtn: PropTypes.bool,
  isSubPSelectOutputBtn: PropTypes.bool,
  handleClickProcessingBulk: PropTypes.func, // 一括加工済変更
  isDeleteBtnOrderDetail: PropTypes.bool,
  updateHandleOpen: PropTypes.func,
};
