import React, { useState, useEffect } from 'react';
import axios from 'axios';
// カスタムフック
import { useFetchDataWithInit } from 'hooks/useFetchData';
import { useSnackBar } from 'hooks/useSnackBar';
import { useCommonFunction } from 'hooks/useCommonFunction';

export const useClientDetail = (api, params, columns) => {
  const [formInputValue, setFormInputValue] = useState({});
  const [addLoadingForm, setAddLoadingForm] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  const url = `${process.env.REACT_APP_API_URL}/FILMa_Get${api}?code=${process.env.REACT_APP_API_CODE}`;

  // カスタムフック
  const { convertStr, convertStr2, onlyNumbers, onlyNumbersAndHyphen, onlyNumbersAndPoint } =
    useCommonFunction();
  const { data, error, isLoading } = useFetchDataWithInit(url, params);
  const { toggleSnack } = useSnackBar();

  const updateZipcode = async (value) => {
    // ハイフンがあれば除く
    const newValue = onlyNumbers(value);
    if (String(newValue).length === 7) {
      const copyInput = [...formInputValue];
      try {
        const res = await axios.get('https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + value);
        if (res.data.results) {
          const result = res.data.results[0];
          copyInput[0].post_code = value;
          copyInput[0].address = result.address1 + result.address2 + result.address3;
          setFormInputValue(copyInput);
        }
      } catch {
        alert('住所の取得に失敗しました。');
      }
    }
  };

  useEffect(() => {
    if (!data) return;
    let status = 'success';
    let message = 'ページの取得に失敗しました';

    if (!data.code) {
      // 成功時
      setFormInputValue(data.result);
    } else {
      // 失敗時
      status = 'error';
      if (data.code === 'E0220') {
        status = 'info';
        message = 'データの登録がありません';
      }
      toggleSnack(true, status, message);
    }
  }, [data]);

  // エラーの場合
  useEffect(() => {
    if (!error) return;
    toggleSnack(true, 'error', 'ページの読み込みに失敗しました');
  }, [error]);

  /** 更新時のchangeハンドラー */
  const handleChangeUpdateForm = (e) => {
    if (e.target.value === undefined) return;
    let value = e.target.value;
    const copyInput = [...formInputValue];
    // typeに応じて型を指定する
    switch (e.target.type) {
      case 'number':
        value = onlyNumbersAndPoint(e.target.value);
        break;
      case 'bool':
        value = toBoolean(e.target.value);
        break;
      default:
        break;
    }

    // 郵便番号を入力したら住所を自動入力する
    if (e.target.name === 'post_code') {
      updateZipcode(e.target.value);
    }

    // フリガナを入力時に半角カタカナを入力したら全角カタカナに自動変換する
    if (e.target.name === 'furigana') {
      value = convertStr2(value);
    }

    // 数字のみの入力とする
    if (
      e.target.name === 'tel' ||
      e.target.name === 'mobile_tel' ||
      e.target.name === 'fax' ||
      e.target.name === 'post_code'
    ) {
      value = onlyNumbersAndHyphen(value);
    }

    copyInput[0][e.target.name] = value;
    setFormInputValue(copyInput);

    if (!Object.keys(formInputValue).length) return;
    if (
      formInputValue[0].name === undefined ||
      formInputValue[0].furigana === undefined ||
      formInputValue[0].title_of_honor === undefined ||
      formInputValue[0].film_sales_price_categories_master_id === undefined ||
      formInputValue[0].film_processing_price_categories_master_id === undefined
    )
      return;

    let status = true;
    if (
      formInputValue[0].name.length > 0 &&
      formInputValue[0].furigana.length > 0 &&
      formInputValue[0].title_of_honor.length > 0 &&
      formInputValue[0].film_sales_price_categories_master_id > 0 &&
      formInputValue[0].film_processing_price_categories_master_id > 0
    ) {
      status = false;
    }
    setIsFormValid(status);
  };

  /** 更新する */
  const handleClickUpdateForm = async () => {
    setAddLoadingForm(true); // ローディング表示

    const copyInput = [...formInputValue];
    // フリガナをカタカナに変換する
    if (copyInput[0].furigana) {
      copyInput[0].furigana = convertStr(copyInput[0].furigana);
    }

    // 敬称が無しの場合は空文字で登録する
    if (copyInput[0].title_of_honor === 'なし') {
      copyInput[0].title_of_honor = '';
    }

    // 更新する前に型を設定する
    for (let i = 0; i < columns.length; i++) {
      if (
        copyInput[0][columns[i].field] !== undefined &&
        columns[i].formType &&
        columns[i].formType === 'number'
      ) {
        copyInput[0][columns[i].field] = Number(copyInput[0][columns[i].field]);
      }
    }

    const url = `${process.env.REACT_APP_API_URL}/FILMa_UpdateClient?code=${process.env.REACT_APP_API_CODE}`;

    let response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(copyInput),
    })
      .then((response) => {
        setAddLoadingForm(false); // ローディング非表示
        return response.json();
      })
      .catch((error) => {
        setAddLoadingForm(false); // ローディング非表示
        return false;
      });

    let status = 'success';
    let message = '更新が完了しました';
    if (response === false || response.code) {
      // 失敗時
      status = 'error';
      message = '更新に失敗しました' + ' FILMa_UpdateClient';
    } else {
      // 成功時
      setFormInputValue({});
      setIsFormValid(true);
      window.location.replace(location.origin + '/client/list'); // 一覧へリダイレクト
    }

    // エラーコードがあれば足す
    if (response.code) {
      message = message + '[' + response.code + ']';
    }

    toggleSnack(true, status, message);
  };

  return {
    formInputValue,
    isLoading,
    addLoadingForm,
    isFormValid,
    handleChangeUpdateForm,
    handleClickUpdateForm,
  };
};
