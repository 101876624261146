import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
// MUI
import { DataGrid } from '@mui/x-data-grid';
// コンポーネント
import { QuickSearchToolbar } from 'components/uiParts/QuickSearchToolbar';
// 定数ファイル
import { LOCALTEXT } from 'utils/constants';

const dataGridStyle = {
  '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
    background: '#fff',
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    overflow: 'visible',
    minWidth: 'unset',
    maxHeight: 'unset',
    justifyContent: 'center',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    maxHeight: 'none',
    overflow: 'visible !important',
    whiteSpace: 'normal !important',
    wordWrap: 'break-word',
    lineHeight: 1.4,
    fontSize: '13px',
    fontWeight: 'bold !important',
  },
  // 列ヘッダに背景色を指定
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: '#fe9879',
    color: '#fff',
  },
  '& .MuiDataGrid-overlay': {
    zIndex: 1,
  },
  '& .MuiDataGrid-cell--editable': {
    position: 'relative',
  },
  '& .MuiDataGrid-cell--editable:after': {
    content: '""',
    border: '1px solid #ccc',
    top: '3px',
    bottom: '3px',
    left: '3px',
    right: '3px',
    position: 'absolute',
    borderRadius: '5px',
    zIndex: '-1',
  },
};

export const CustomDataGrid = (props) => {
  const {
    rows,
    columns,
    searchText,
    searchSecondText,
    searchThirdText,
    requestSearch,
    requestSecondSearch,
    requestThirdSearch,
    title,
    checkboxSelection,
    onSelectionModelChange,
    isLoading,
    autoHeight,
    changeCell,
    hideSearchToolbar,
    customDataGridStyle,
    columnVisibilityList,
    rowSelectable,
    defaultColumnVisibilityList,
    localStorageKeyName,
    cellEditableFunc,
    cellEditStart,
    cellFocusOut,
    defaultFilterColumn,
    defaultFilterOperator,
  } = props;

  const [BaseCustomDataGrid, setBaseCustomDataGrid] = useState(styled(DataGrid)(dataGridStyle));

  // スタイルを変更する場合の処理
  useEffect(() => {
    if (!customDataGridStyle) return;

    if (Object.keys(customDataGridStyle).length === 0) {
      setBaseCustomDataGrid(styled(DataGrid)(dataGridStyle)); // スタイル初期化
    } else {
      setBaseCustomDataGrid(styled(BaseCustomDataGrid)(customDataGridStyle)); // スタイル変更を反映
    }
  }, [customDataGridStyle]);

  // localStorageの設定
  const path = location.pathname;
  const regExp = new RegExp('/user/', 'g');
  const pathName = path.replace(regExp, '');

  const APP_KEY = pathName + (localStorageKeyName ? localStorageKeyName : '');
  const appState = localStorage.getItem(APP_KEY);

  // カラムの設定
  const columnState = appState
    ? JSON.parse(appState) // localStorageの値を設定
    : defaultColumnVisibilityList
    ? defaultColumnVisibilityList
    : {}; // localStorageに値がない場合、ここに記載した内容が初期値となる
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(columnState);

  // カラムが変更されたときに値を更新
  const onColumnVisibilityModelChange = (newModel) => {
    localStorage.setItem(APP_KEY, JSON.stringify(newModel));
    setColumnVisibilityModel(newModel);
  };

  // ソートをローカルストレージ;
  const [isSort, setIsSort] = useState(false);
  const appSort = localStorage.getItem(APP_KEY + 'sort');
  const sortState = appSort ? JSON.parse(appSort) : [];
  const [nowSort, setNowSort] = useState(sortState);
  const onSortChange = (onSort) => {
    if (isSort) {
      localStorage.setItem(APP_KEY + 'sort', JSON.stringify(onSort));
      setNowSort(onSort);
    } else {
      setIsSort(true);
    }
  };

  // ソートが最初にリセットされないようにするため
  useEffect(() => {
    if (isLoading) {
      setIsSort(true);
    }
  }, [isLoading]);

  // ページ数
  const appPage = localStorage.getItem(APP_KEY + 'page');
  const pageState = appPage ? Number(appPage) : 0;
  const [nowPage, setNowPage] = useState(pageState);
  // ページ数が変更された時に値を更新
  const onPageNumberChange = (onPage) => {
    localStorage.setItem(APP_KEY + 'page', onPage);
    setNowPage(onPage);
  };

  // 行数;
  const appPageSize = localStorage.getItem(APP_KEY + 'pageSize');
  const pageSizeState = appPageSize ? Number(appPageSize) : 100;
  const [nowPageSize, setNowPageSize] = useState(pageSizeState);
  // ページ数が変更された時に値を更新
  const onPageSizeChange = (onPageSize) => {
    localStorage.setItem(APP_KEY + 'pageSize', onPageSize);
    setNowPageSize(onPageSize);
  };

  const defaultFilterColumnData = defaultFilterColumn ? defaultFilterColumn : 'id';
  const defaultFilterOperatorData = defaultFilterOperator ? defaultFilterOperator : '=';
  const [filterModel, setFilterModel] = useState({
    items: [
      {
        columnField: defaultFilterColumnData,
        operatorValue: defaultFilterOperatorData,
        value: '',
      },
    ],
  });

  const onFilterChange = (onSort) => {
    if (isSort) {
      setFilterModel(onSort);
    } else {
      setIsSort(true);
    }
  };

  return (
    <>
      <BaseCustomDataGrid
        rows={rows}
        columns={columns}
        components={hideSearchToolbar ? {} : { Toolbar: QuickSearchToolbar }}
        componentsProps={{
          toolbar: {
            searchText: searchText,
            searchSecondText: searchSecondText,
            searchThirdText: searchThirdText,
            requestSearch: (event) => requestSearch(event.target.value),
            requestSecondSearch: (event) => requestSecondSearch(event.target.value),
            requestThirdSearch: (event) => requestThirdSearch(event.target.value),
            clearSearch: () => requestSearch(''),
            clearSecondSearch: () => requestSecondSearch(''),
            clearThirdSearch: () => requestThirdSearch(''),
            title: title,
          },
          pagination: {
            labelRowsPerPage: 'ページあたりの行数',
          },
        }}
        checkboxSelection={checkboxSelection === undefined ? false : true}
        // checkboxSelection={false} // まとめて削除を使わないのでfalseとする
        onSelectionModelChange={onSelectionModelChange}
        disableSelectionOnClick
        labelRowsPerPage='Rows per'
        rowsPerPageOptions={[100, 50, 25]}
        localeText={LOCALTEXT}
        showColumnRightBorder
        showCellRightBorder
        density='compact'
        disableColumnMenu
        loading={isLoading}
        onCellEditCommit={changeCell}
        onCellEditStart={cellEditStart}
        onCellFocusOut={cellFocusOut}
        autoHeight={autoHeight}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => onColumnVisibilityModelChange(newModel)} //カラムの表示の更新処理
        isRowSelectable={rowSelectable}
        isCellEditable={cellEditableFunc}
        sortModel={nowSort}
        onSortModelChange={(onSort) => onSortChange(onSort)}
        page={nowPage}
        onPageChange={(onPage) => onPageNumberChange(onPage)}
        pageSize={nowPageSize}
        onPageSizeChange={(onPageSize) => onPageSizeChange(onPageSize)}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => onFilterChange(newFilterModel)}
      />
    </>
  );
};

CustomDataGrid.propTypes = {
  rows: PropTypes.array.isRequired, // テーブルで表示するレコード
  columns: PropTypes.array.isRequired, // テーブルのカラム
  searchText: PropTypes.string, // 検索したいキーワード
  searchSecondText: PropTypes.string, // 検索したい2つ目のキーワード
  searchThirdText: PropTypes.string, // 検索したい3つ目のキーワード
  requestSearch: PropTypes.func, // キーワード検索で使用するメソッド
  requestSecondSearch: PropTypes.func, // 2つ目のキーワード検索で使用するメソッド
  requestThirdSearch: PropTypes.func, // 3つ目のキーワード検索で使用するメソッド
  title: PropTypes.string, // テーブルのタイトル
  checkboxSelection: PropTypes.bool, // チェックボックスを使うか
  onSelectionModelChange: PropTypes.func, // チェックされている要素を取得
  isLoading: PropTypes.bool.isRequired, // ローディング表示用
  autoHeight: PropTypes.bool, // 自動で高さ設定をする場合
  changeCell: PropTypes.func, // セルを変更した場合に使うメソッド
  hideSearchToolbar: PropTypes.bool, // 検索欄を非表示する場合に使う
  customDataGridStyle: PropTypes.object, // スタイルを追加したい場合に使う
  columnVisibilityList: PropTypes.object, // カラムを非表示にしたい場合に使う
  rowSelectable: PropTypes.func, // チェックボックスのdisabledをコントロールしたい場合に使う
  defaultColumnVisibilityList: PropTypes.object, // デフォルトのカラムの非表示に使う
  localStorageKeyName: PropTypes.string, // ローカルストレージのカラム設定を判別用
  cellEditableFunc: PropTypes.func, // 編集できるレコードの条件
  cellEditStart: PropTypes.func, // 編集開始したタイミングのイベント
  cellFocusOut: PropTypes.func, // 編集したセルから外れたタイミングのイベント
  defaultFilterColumn: PropTypes.string, // デフォルトでフィルタの値を決める際に使う
  defaultFilterOperator: PropTypes.string,
};
