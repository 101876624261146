import React, { useState, useEffect } from 'react';
// カスタムフック
import { useSnackBar } from 'hooks/useSnackBar';
import { useCommonFunction } from 'hooks/useCommonFunction';

export const useCreateProductForm = (columns) => {
  const [addLoadingForm, setAddLoadingForm] = useState(false);
  const [isCreateFormValid, setIsCreateFormValid] = useState(true);
  const [isSelected, setIsSelected] = useState(false);
  const [isPCSelected, setIsPCSelected] = useState(false);
  const [formInputValue, setFormInputValue] = useState({
    name: '',
    thickness: null,
    width: null,
    length: null,
    specific_gravity: 0.96,
    weight: null,
    cost_price_m: null,
    cost_price: null,
    selling_price_m: [null, null, null, null],
    processing_price: [0, null, null, null],
    film_form_types_master_id: '2',
    detail: '',
    usage_category: '許可',
  });
  const [openSPModal, setOpenSPModal] = useState(false);
  const [openPCModal, setOpenPCModal] = useState(false);
  const [checkBox, setCheckBox] = useState(false);

  // カスタムフック
  const { toggleSnack } = useSnackBar();
  const { onlyNumbersAndPoint, convertNum, convertDot } = useCommonFunction();

  /** 登録時のchangeハンドラー */
  const handleChangeCreateForm = (e, type) => {
    if (e.target.value === undefined) return;

    let value = e.target.value;
    let inputObject = { [e.target.name]: value };

    // typeに応じて型を指定する
    switch (type) {
      case 'number':
        let convertValue = convertDot(value);
        convertValue = convertNum(convertValue);
        inputObject[e.target.name] = onlyNumbersAndPoint(convertValue);
        break;
      case 'bool':
        inputObject[e.target.name] = toBoolean(value);
        break;
      default:
        break;
    }

    // 長さが選択されたら原価を計算する
    let addInputObject = {};
    if (e.target.name === 'length') {
      addInputObject.cost_price = Math.round(Number(e.target.value) * formInputValue.cost_price_m);
    }

    // 原価/mが選択されたら原価を計算する
    if (e.target.name === 'cost_price_m') {
      addInputObject.cost_price = Math.round(formInputValue.length * Number(e.target.value));
    }

    // 原価(cost_price)が計算されたらaddInputObjectを追加する
    if (addInputObject.cost_price) {
      setFormInputValue({ ...formInputValue, ...inputObject, ...addInputObject });
    } else {
      setFormInputValue({ ...formInputValue, ...inputObject });
    }
  };

  // TODO 簡易必須チェック　バリデーションを実装したら削除
  useEffect(() => {
    let status = true;
    // 特殊商品時は商品名だけ必須
    if (checkBox) {
      if (formInputValue.name.length > 0) {
        status = false;
      }
    } else {
      if (!Object.keys(formInputValue).length) return;
      if (formInputValue.name === '') return;
      if (formInputValue.thickness === null) return;
      if (formInputValue.width === null) return;
      if (formInputValue.length === null) return;
      if (formInputValue.selling_price_m[0] === null) return;

      if (
        formInputValue.name.length > 0 &&
        String(formInputValue.thickness).length > 0 &&
        String(formInputValue.width).length > 0 &&
        String(formInputValue.length).length > 0 &&
        formInputValue.selling_price_m[0] > 0
      ) {
        status = false;
      }
    }
    setIsCreateFormValid(status);
  }, [formInputValue, checkBox]);

  /**
   * 販売価格モーダルの登録ボタンを押したらformInputValueに入れる
   * @param {Object} array [1000, null, 1000, 1000]
   */
  const handleClickSellingPriceForm = (array) => {
    setFormInputValue({ ...formInputValue, ...{ selling_price_m: array } });
    setOpenSPModal(false);
    setIsSelected(true);
  };

  /**
   * 加工費モーダルの登録ボタンを押したらformInputValueに入れる
   * @param {Object} array [1000, null, 1000, 1000]
   */
  const handleClickProcessingCostForm = (array) => {
    setFormInputValue({ ...formInputValue, ...{ processing_price: array } });
    setOpenPCModal(false);
    setIsPCSelected(true);
  };

  /** 登録する */
  const handleClickCreateForm = async () => {
    setAddLoadingForm(true); // ローディング表示

    const url = `${process.env.REACT_APP_API_URL}/FILMa_AddProduct?code=${process.env.REACT_APP_API_CODE}`;

    const copyFormInputValue = { ...formInputValue }; // コピー

    // 特殊商品時に必須だった項目に0などを入れる。
    if (checkBox) {
      // 厚み
      if (copyFormInputValue.thickness === null) {
        copyFormInputValue.thickness = 0;
      }
      // 幅
      if (copyFormInputValue.width === null) {
        copyFormInputValue.width = 0;
      }
      // 長さ
      if (copyFormInputValue.length === null) {
        copyFormInputValue.length = 0;
      }
      // 比重
      if (copyFormInputValue.specific_gravity === null) {
        copyFormInputValue.specific_gravity = 0;
      }
      // 原価/m
      if (copyFormInputValue.cost_price_m === null) {
        copyFormInputValue.cost_price_m = 0;
      }
      // 原価
      if (copyFormInputValue.cost_price === null) {
        copyFormInputValue.cost_price = 0;
      }
      // 形状ID
      if (copyFormInputValue.film_form_types_master_id === null) {
        copyFormInputValue.film_form_types_master_id = '2';
      }
      // 使用区分
      if (copyFormInputValue.usage_category === null) {
        copyFormInputValue.usage_category = '許可';
      }
      // 販売価格標準
      if (copyFormInputValue.selling_price_m[0] === null) {
        copyFormInputValue.selling_price_m[0] = 0;
      }
    }

    // 登録する前に型を設定する
    for (let i = 0; i < columns.length; i++) {
      if (
        copyFormInputValue[columns[i].field] !== undefined &&
        columns[i].formType &&
        columns[i].formType === 'number'
      ) {
        copyFormInputValue[columns[i].field] = Number(copyFormInputValue[columns[i].field]);
      }
    }

    // 重さ：厚みx幅x長さx比重x0.01
    copyFormInputValue.weight =
      copyFormInputValue.thickness *
      copyFormInputValue.width *
      copyFormInputValue.length *
      copyFormInputValue.specific_gravity *
      0.01;

    // 商品詳細：商品名_厚み_幅_長さ テスト_T100_W100_L100
    copyFormInputValue.detail =
      copyFormInputValue.name +
      '_T' +
      copyFormInputValue.thickness +
      '_W' +
      copyFormInputValue.width +
      '_L' +
      copyFormInputValue.length;

    // // 原価：長さx 原価/m 小数第1位で四捨五入を行う
    // copyFormInputValue.cost_price = Math.round(
    //   copyFormInputValue.length * copyFormInputValue.cost_price_m
    // );

    let response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify([copyFormInputValue]),
    })
      .then((response) => {
        setAddLoadingForm(false); // ローディング非表示
        return response.json();
      })
      .catch((error) => {
        setAddLoadingForm(false); // ローディング非表示
        return false;
      });

    let status = 'success';
    let message = '登録が完了しました';
    if (response === false || response.code) {
      // 失敗時
      status = 'error';
      message = '登録に失敗しました' + ' FILMa_AddProduct';
    } else {
      // 成功時
      setCheckBox(false);
      setFormInputValue({});
      setIsCreateFormValid(true);
      window.location.replace(location.origin + '/product/list'); // 一覧へリダイレクト
    }

    // エラーコードがあれば足す
    if (response.code) {
      message = message + '[' + response.code + ']';
    }

    toggleSnack(true, status, message);
  };

  const handleCheckBoxChange = (event) => {
    setCheckBox(event);
  };

  return {
    formInputValue,
    addLoadingForm,
    isCreateFormValid,
    isSelected,
    isPCSelected,
    openSPModal,
    openPCModal,
    checkBox,
    handleClickSellingPriceForm,
    handleClickProcessingCostForm,
    handleClickCreateForm,
    handleChangeCreateForm,
    setOpenSPModal,
    setOpenPCModal,
    handleCheckBoxChange,
  };
};
