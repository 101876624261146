import React from 'react';
import { Link } from 'react-router-dom';
// カスタムフック
import { useSignOut } from 'hooks/useSignOut';

export const NotAuthPage = () => {
  const { handleLogout, instance } = useSignOut();

  return (
    <>
      <h1>閲覧権限がありません</h1>
      <Link to onClick={() => handleLogout(instance)}>
        <h3>ログアウト</h3>
      </Link>
    </>
  );
};
