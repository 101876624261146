import React, { useState } from 'react';
import PropTypes from 'prop-types';
// MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
// コンポーネント
import { DataTable } from 'components/uiParts/DataGrid/DataTable';
// 定数
import { CLIENT_TABLE_COLUMNS } from 'utils/constantsTable';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export const SelectClientModal = (props) => {
  const { open, setOpen, handleClickSelectClient, clientInfo } = props;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen} sx={{ margin: '10px 0' }} variant='contained'>
        {clientInfo.id ? '顧客を変更する' : '顧客を選択する'}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <>
            <DataTable
              title=''
              columns={CLIENT_TABLE_COLUMNS}
              api='ClientForUser'
              isDecisionBtn={true}
              handleClickDecision={handleClickSelectClient}
              columnVisibilityList={{
                title_of_honor: false,
                tel: false,
                mobile_tel: false,
                fax: false,
                mail_address: false,
                post_code: false,
                film_sales_price_categories_master_id: false,
                film_customer_categories_1_master_id: false,
                film_customer_categories_2_master_id: false,
                film_customer_categories_3_master_id: false,
              }}
            />
          </>
        </Box>
      </Modal>
    </div>
  );
};

SelectClientModal.propTypes = {
  open: PropTypes.bool.isRequired, // モーダル開く
  setOpen: PropTypes.func.isRequired, // モーダル開く
  handleClickSelectClient: PropTypes.func.isRequired, // 決定ボタンのクリックイベント
  clientInfo: PropTypes.object.isRequired, // 顧客情報
};
