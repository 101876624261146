import React, { useState, useCallback } from 'react';

export const useProductModal = () => {
  const [openProductModal, setOpenProductModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState({});

  /** 商品を選択する */
  const handleClickSelectProduct = useCallback((params) => {
    setSelectedProduct(params.row);
    setSelectedProductId(params.row.id);
    setOpenProductModal(false);
  }, []);

  return {
    openProductModal,
    setOpenProductModal,
    selectedProductId,
    selectedProduct,
    setSelectedProductId,
    handleClickSelectProduct,
  };
};
