import React, { useCallback } from 'react';
import { useMsal } from '@azure/msal-react';

export const useSignOut = () => {
  const { instance } = useMsal();

  const handleLogout = useCallback((instance) => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }, []);

  return {
    handleLogout,
    instance,
  };
};
