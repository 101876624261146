/**
 * 共通関数
 */
export const useCommonFunction = () => {
  // ひらがなをカタカナに変換する
  const convertStr = (str) => {
    return str.replace(/[ぁ-ん]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) + 0x60);
    });
  };

  // 変換前
  var beforeStr = new Array(
    'ｧ',
    'ｨ',
    'ｩ',
    'ｪ',
    'ｫ',
    'ｬ',
    'ｭ',
    'ｮ',
    'ｯ',
    'ｰ',
    'ｳﾞ',
    'ｶﾞ',
    'ｷﾞ',
    'ｸﾞ',
    'ｹﾞ',
    'ｺﾞ',
    'ｻﾞ',
    'ｼﾞ',
    'ｽﾞ',
    'ｾﾞ',
    'ｿﾞ',
    'ﾀﾞ',
    'ﾁﾞ',
    'ﾂﾞ',
    'ﾃﾞ',
    'ﾄﾞ',
    'ﾊﾞ',
    'ﾋﾞ',
    'ﾌﾞ',
    'ﾍﾞ',
    'ﾎﾞ',
    'ﾊﾟ',
    'ﾋﾟ',
    'ﾌﾟ',
    'ﾍﾟ',
    'ﾎﾟ',
    'ｱ',
    'ｲ',
    'ｳ',
    'ｴ',
    'ｵ',
    'ｶ',
    'ｷ',
    'ｸ',
    'ｹ',
    'ｺ',
    'ｻ',
    'ｼ',
    'ｽ',
    'ｾ',
    'ｿ',
    'ﾀ',
    'ﾁ',
    'ﾂ',
    'ﾃ',
    'ﾄ',
    'ﾅ',
    'ﾆ',
    'ﾇ',
    'ﾈ',
    'ﾉ',
    'ﾊ',
    'ﾋ',
    'ﾌ',
    'ﾍ',
    'ﾎ',
    'ﾏ',
    'ﾐ',
    'ﾑ',
    'ﾒ',
    'ﾓ',
    'ﾔ',
    'ﾕ',
    'ﾖ',
    'ﾗ',
    'ﾘ',
    'ﾙ',
    'ﾚ',
    'ﾛ',
    'ﾜ',
    'ｦ',
    'ﾝ'
  );

  // 変換後
  var afterStr = new Array(
    'ァ',
    'ィ',
    'ゥ',
    'ェ',
    'ォ',
    'ャ',
    'ュ',
    'ョ',
    'ッ',
    'ー',
    'ヴ',
    'ガ',
    'ギ',
    'グ',
    'ゲ',
    'ゴ',
    'ザ',
    'ジ',
    'ズ',
    'ゼ',
    'ゾ',
    'ダ',
    'ヂ',
    'ヅ',
    'デ',
    'ド',
    'バ',
    'ビ',
    'ブ',
    'ベ',
    'ボ',
    'パ',
    'ピ',
    'プ',
    'ペ',
    'ポ',
    'ア',
    'イ',
    'ウ',
    'エ',
    'オ',
    'カ',
    'キ',
    'ク',
    'ケ',
    'コ',
    'サ',
    'シ',
    'ス',
    'セ',
    'ソ',
    'タ',
    'チ',
    'ツ',
    'テ',
    'ト',
    'ナ',
    'ニ',
    'ヌ',
    'ネ',
    'ノ',
    'ハ',
    'ヒ',
    'フ',
    'ヘ',
    'ホ',
    'マ',
    'ミ',
    'ム',
    'メ',
    'モ',
    'ヤ',
    'ユ',
    'ヨ',
    'ラ',
    'リ',
    'ル',
    'レ',
    'ロ',
    'ワ',
    'ヲ',
    'ン'
  );

  /** 半角カタカナから全角カタカナに変換 */
  const convertStr2 = (str) => {
    var fullStr = str;
    for (var i = 0; i < beforeStr.length; i++) {
      fullStr = fullStr.replace(new RegExp(beforeStr[i], 'g'), afterStr[i]);
    }
    return fullStr;
  };

  /** 数字のみ取り出す */
  const onlyNumbers = (n) => {
    return n.replace(/[^0-9]/g, '');
  };

  /** 数字と小数点のみ取り出す */
  const onlyNumbersAndPoint = (n) => {
    return n.replace(/[^0-9\.]/g, '');
  };

  /** 全角数字を半角数字に変換する */
  const convertNum = (n) => {
    return n.replace(/[０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
  };

  /** "。"を"."に変換する */
  const convertDot = (n) => {
    return n.replace(/[。]/g, '.');
  };

  /** 数字とハイフンのみ取り出す */
  const onlyNumbersAndHyphen = (n) => {
    return n.replace(/[^0-9\-]/g, '');
  };

  // 日付から文字列に変換する関数
  const getStringFromDate = (date) => {
    var year_str = new Date(date).getFullYear();
    // 月だけ+1すること
    var month_str = 1 + new Date(date).getMonth();
    var day_str = new Date(date).getDate();

    // 0埋めする
    month_str = ('0' + month_str).slice(-2);
    day_str = ('0' + day_str).slice(-2);

    let format_str = 'YYYY-MM-DD';
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);

    return format_str;
  };

  // 今日の日付を取得する
  const useGetToday = () => {
    const date = new Date();
    const dateString = getStringFromDate(date);
    return dateString;
  };

  /**
   * 受注詳細用にフィルタリングする
   * 【sub_order_group_id：受注ID_1_1】 _で分割して[2]が1のデータだけをフィルタリングする
   * 注文金額は分割した[1]が同じデータを足し算する
   * @param {Array} rows
   * @returns {Array}
   */
  const filteringOrderList = (rows) => {
    const copyRows = JSON.parse(JSON.stringify(rows)); // コピー
    const sortRow = [];
    const sOGRows = {};

    for (let i = 0; i < copyRows.length; i++) {
      if (copyRows[i].film_orders_master_id !== undefined && copyRows[i].sub_order_group_id !== undefined) {
        let subOrderGIdAry = copyRows[i].sub_order_group_id.split('_');
        if (copyRows[i].film_orders_master_id == subOrderGIdAry[0]) {
          let idx = sortRow.findIndex((d) => d.sub_order_group_id.split('_')[1] === subOrderGIdAry[1]); // 該当データのindexを取得
          if (idx >= 0) {
            sortRow[idx] = Object.keys(sortRow[idx]).reduce((value, key) => {
              if (key === 'amount_money') {
                value[key] = sortRow[idx][key];
              } else if (key === 'StockIds') {
                // 在庫IDはマージして重複を削除する
                value[key] = [...sortRow[idx][key], ...copyRows[i][key]];
                value[key] = Array.from(new Set(value[key]));
              } else if (key === 'NotDeleteBtn') {
              } else {
                value[key] = copyRows[i][key];
              }
              if (key === 'StatusCode') {
                if (value['StatusCode'] >= 3) {
                  value['NotDeleteBtn'] = true;
                }
              }
              return value;
            }, {});
          } else {
            if (copyRows[i]['StatusCode'] >= 3) {
              copyRows[i]['NotDeleteBtn'] = true;
            }
            sortRow.push(copyRows[i]);
          }

          if (sOGRows[subOrderGIdAry[1]] === undefined) {
            sOGRows[subOrderGIdAry[1]] = [copyRows[i]];
          } else {
            sOGRows[subOrderGIdAry[1]].push(copyRows[i]);
          }
        }
      }
    }

    return [sortRow, sOGRows];
  };

  return {
    convertStr,
    convertStr2,
    onlyNumbers,
    onlyNumbersAndPoint,
    convertNum,
    convertDot,
    onlyNumbersAndHyphen,
    getStringFromDate,
    useGetToday,
    filteringOrderList,
  };
};
