import PropTypes from 'prop-types';

// MUI
import { Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';

export const Breadcrumb = (props) => {
  const { breadcrumbList } = props;

  return (
    <Breadcrumbs
      style={{ marginBottom: '15px' }}
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'
    >
      {breadcrumbList.map((item, key) => {
        return breadcrumbList.length - 1 != key ? (
          <Link underline='hover' key={key} color='inherit' href={item.href}>
            {item.name}
          </Link>
        ) : (
          <Typography key={key} color='text.primary'>
            {item.name}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};

Breadcrumb.propTypes = {
  breadcrumbList: PropTypes.array, // リンクのアイテム一覧
};
