import React from 'react';
import { useMsal } from '@azure/msal-react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import LogoutIcon from '@mui/icons-material/Logout';
// カスタムフック
import { useSignOut } from 'hooks/useSignOut';

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 * リダイレクトを使用したサインイン
 * @see https://docs.microsoft.com/ja-jp/azure/active-directory/develop/tutorial-v2-react#sign-in-using-redirects
 */
export const SignOutButton = () => {
  const { handleLogout, instance } = useSignOut();

  return (
    <ListItem
      button
      style={{ padding: '10px 5px', justifyContent: 'start' }}
      onClick={() => handleLogout(instance)}
    >
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      ログアウト
    </ListItem>
  );
};
