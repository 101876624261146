import React from 'react';
import { DataTable } from 'components/uiParts/DataGrid/DataTable';
import { SHIP_LIST_TABLE_COLUMNS } from 'utils/constantsTable';
import { Breadcrumb } from 'components/uiParts/Breadcrumb';
// カスタムフック
import { useUpdateOrderForm } from 'hooks/useOrderForm';

export const ShipListPage = () => {
  // カスタムフック
  const { handleClickPrintShipSheet, setSubOrderGRows } = useUpdateOrderForm();
  const breadcrumbList = [
    { name: 'ダッシュボード', href: '/' },
    { name: '出荷一覧', href: '/ship/list' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <DataTable
        title='出荷一覧'
        columns={SHIP_LIST_TABLE_COLUMNS}
        api='SubOrderForUser'
        isShipList
        checkboxSelection={true}
        isMIOutputBtn
        handleClickPrintShipSheet={handleClickPrintShipSheet}
        setSubOrderGRows={setSubOrderGRows}
      />
    </>
  );
};
