import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
// MUI
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// コンポーネント
import { SubTitle } from 'components/uiParts/SubTitle';
import { BorderTop } from 'components/uiParts/BorderTop';
import { CustomDatePicker } from 'components/uiParts/CustomDatePicker';
import { SelectProductModal } from 'components/uiParts/Modal/SelectProductModal';
import { SelectClientModal } from 'components/uiParts/Modal/SelectClientModal';
import { EditDataTable } from 'components/uiParts/DataGrid/EditDataTable';
import { Breadcrumb } from 'components/uiParts/Breadcrumb';
// 定数
import { PRODUCT_TABLE_COLUMNS_FOR_ORDER, PRODUCT_TABLE_MODAL_COLUMNS } from 'utils/constantsTable';
// カスタムフック
import { useCreateOrderForm } from 'hooks/useOrderForm';

export const CreateOrderForm = () => {
  const [customDataGridStyle, setCustomDataGridStyle] = useState({});
  const {
    openClientModal,
    openProductModal,
    selectedProductId,
    addLoadingForm,
    clientInfo,
    selectedOrderDate,
    selectedOrderMoney,
    emptyRows,
    editRows,
    selectedProductRow,
    setSelectedProductId,
    setOpenProductModal,
    setEditRows,
    setOpenClientModal,
    handleClickSelectClient,
    handleClickSelectProduct,
    handleClickCreateForm,
    setSelectedOrderDate,
    setSelectedOrderMoney,
    setSelectedDeliveryDate,
    setSelectedProductRow,
    selectedSubOrderDeliveryDate,
    setSelectedSubOrderDeliveryDate,
    isFormValidation,
    setIsFormValidation,
  } = useCreateOrderForm();

  // th横に必須マークをつける
  useEffect(() => {
    if (!PRODUCT_TABLE_COLUMNS_FOR_ORDER.length) return;
    let styleObject = {};
    for (let i = 0; i < PRODUCT_TABLE_COLUMNS_FOR_ORDER.length; i++) {
      if (
        PRODUCT_TABLE_COLUMNS_FOR_ORDER[i].editable &&
        PRODUCT_TABLE_COLUMNS_FOR_ORDER[i].reauired
      ) {
        const p =
          '& .MuiDataGrid-columnHeader[data-field="' +
          PRODUCT_TABLE_COLUMNS_FOR_ORDER[i].field +
          '"]:after';
        styleObject = {
          ...styleObject,
          ...{
            [p]: {
              color: 'red',
              content: '"※"',
            },
          },
        };
      } else {
        const p =
          '& .MuiDataGrid-columnHeader[data-field="' +
          PRODUCT_TABLE_COLUMNS_FOR_ORDER[i].field +
          '"]:after';
        styleObject = {
          ...styleObject,
          ...{
            [p]: {
              content: 'none',
            },
          },
        };
      }
    }

    setCustomDataGridStyle(styleObject); // スタイル変更を反映
  }, [PRODUCT_TABLE_COLUMNS_FOR_ORDER]);

  const breadcrumbList = [
    { name: 'ダッシュボード', href: '/' },
    { name: '受注一覧', href: '/order/list' },
    { name: '受注登録', href: '/order/create' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SubTitle title='受注登録' />
        <Button href={location.origin + '/order/list'} variant='contained' color='inherit'>
          戻る
        </Button>
      </div>
      <SelectClientModal
        open={openClientModal}
        setOpen={setOpenClientModal}
        handleClickSelectClient={handleClickSelectClient}
        clientInfo={clientInfo}
      />
      <Table sx={{ margin: '20px 0 0' }} size='small' aria-label='a dense table'>
        <TableBody>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ width: '80px', border: 0, paddingLeft: 0 }}>顧客ID</TableCell>
            <TableCell sx={{ border: 0 }}>{clientInfo.id ? clientInfo.id : ''}</TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ width: '80px', border: 0, paddingLeft: 0 }}>顧客名</TableCell>
            <TableCell sx={{ border: 0 }}>{clientInfo.name ? clientInfo.name : ''}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <BorderTop />
      <Table sx={{ margin: '20px 0 0' }} size='small' aria-label='a dense table'>
        <TableBody>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ width: '80px', border: 0, paddingLeft: 0 }}>受注日</TableCell>
            <TableCell sx={{ border: 0, paddingLeft: 0 }}>
              <div style={{ width: '200px' }}>
                <CustomDatePicker date={selectedOrderDate} setDate={setSelectedOrderDate} />
              </div>
            </TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ border: 0, paddingLeft: 0 }}>受注金額</TableCell>
            <TableCell sx={{ border: 0, padding: '16px 0', fontSize: '26px' }}>
              {'¥' + Number(selectedOrderMoney).toLocaleString()}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <BorderTop />
      <SubTitle title='注文商品' hideBorder={true} />
      <SelectProductModal
        open={openProductModal}
        setOpen={setOpenProductModal}
        handleClickSelectProduct={handleClickSelectProduct}
        isButton={clientInfo.id && selectedOrderDate ? false : true}
        setSelectedProductRow={setSelectedProductRow}
        columns={PRODUCT_TABLE_MODAL_COLUMNS}
      />
      <div style={{ position: 'relative' }}>
        <EditDataTable
          title=''
          vh='auto'
          emptyRows={emptyRows}
          editRows={editRows}
          setEditRows={setEditRows}
          columns={PRODUCT_TABLE_COLUMNS_FOR_ORDER}
          api='ProductForUser'
          isDeleteBtn={true}
          selectedProductId={selectedProductId}
          selectedOrderDate={selectedOrderDate}
          clientInfo={clientInfo}
          setSelectedOrderMoney={setSelectedOrderMoney}
          setSelectedDeliveryDate={setSelectedDeliveryDate}
          setSelectedProductId={setSelectedProductId}
          selectedProductRow={selectedProductRow}
          selectedSubOrderDeliveryDate={selectedSubOrderDeliveryDate}
          setSelectedSubOrderDeliveryDate={setSelectedSubOrderDeliveryDate}
          setIsFormValidation={setIsFormValidation}
          customDataGridStyle={customDataGridStyle}
        />
        {clientInfo.id === undefined && (
          <div
            style={{
              background: 'rgb(204, 204, 204, 0.2)',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          ></div>
        )}
      </div>
      <div style={{ height: '50px' }} />
      <BorderTop />
      <div style={{ textAlign: 'right' }}>
        <LoadingButton
          disabled={editRows.length <= 1 || !selectedOrderDate ? true : false || isFormValidation}
          onClick={() => handleClickCreateForm()}
          variant='contained'
          loading={addLoadingForm}
        >
          注文登録
        </LoadingButton>
      </div>
    </>
  );
};
