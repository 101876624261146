import React from 'react';
// コンポーネント
import { SubTitle } from 'components/uiParts/SubTitle';
import { CreateProductForm } from 'components/uiParts/Form/CreateProductForm';
import { Breadcrumb } from 'components/uiParts/Breadcrumb';
// 定数
import { PRODUCT_TABLE_COLUMNS } from 'utils/constantsTable';
// MUI
import Button from '@mui/material/Button';

export const ProductCreatePage = () => {
  const breadcrumbList = [
    { name: 'ダッシュボード', href: '/' },
    { name: '商品一覧', href: '/product/list' },
    { name: '商品登録' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SubTitle title='商品登録' />
        <Button href={location.origin + '/product/list'} variant='contained' color='inherit'>
          戻る
        </Button>
      </div>
      <CreateProductForm columns={PRODUCT_TABLE_COLUMNS} />
    </>
  );
};
