import React from 'react';
import PropTypes from 'prop-types';
// MUI
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
// コンポーネント
import { BorderTop } from 'components/uiParts/BorderTop';
import { Required } from 'components/uiParts/Required';
// カスタムフック
import { useUpdateForm } from 'hooks/useForm';

export const UpdateForm = (props) => {
  const { api, columns, redirectUrl } = props;

  const {
    formInputValue,
    isFormValid, // 更新フォームのボタンを押せるか
    addLoadingUpdateForm, // ローディング
    handleChangeUpdateForm, // 更新フォームのチェンジメソッド
    handleClickUpdateRows, // 更新を行うメソッド
  } = useUpdateForm(api, columns, redirectUrl, columns);

  return (
    <>
      <Table sx={{ margin: '20px 0 0', width: 'auto' }} size='small' aria-label='a dense table'>
        <TableBody>
          {columns.map(
            (column, index) =>
              column.update && (
                <TableRow key={index} sx={{ border: 0 }}>
                  <TableCell sx={{ width: '150px', border: 0, paddingLeft: 0 }}>
                    {column.headerName}
                    {column.reauired && <Required />}
                  </TableCell>
                  <TableCell sx={{ border: 0, paddingLeft: 0 }}>
                    <TextField
                      name={column.field}
                      onChange={(e) =>
                        handleChangeUpdateForm(e, column.formType === 'number' ? 'number' : 'text')
                      }
                      value={
                        formInputValue[0] && formInputValue[0][column.field]
                          ? formInputValue[0][column.field]
                          : ''
                      }
                      disabled={column.disabled}
                      sx={{ width: 400 }}
                      size='small'
                      multiline={column.inputRows > 1 ? true : false}
                      rows={column.inputRows > 1 ? column.inputRows : 1}
                    />
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
      <BorderTop />
      <div style={{ textAlign: 'right' }}>
        <LoadingButton
          disabled={isFormValid}
          onClick={handleClickUpdateRows}
          variant='contained'
          loading={addLoadingUpdateForm}
        >
          更新する
        </LoadingButton>
      </div>
    </>
  );
};

UpdateForm.propTypes = {
  api: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  // // tabs: PropTypes.object.isrequired,
  // colomuns: PropTypes.array.isrequired,
};
