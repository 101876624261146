import React from 'react';
import { Outlet } from 'react-router-dom';
import { DrawerMenu } from 'components/uiParts/Layout/DrawerMenu';

export const Layout = () => {
  return (
    <>
      <DrawerMenu title='ARASAフィルムシステム'>
        <Outlet />
      </DrawerMenu>
    </>
  );
};
