// Recoil
import { useSetRecoilState } from 'recoil';
import { snackBarState } from 'recoil/atom/SnackBarAtom';

/**
 * SnackBarを呼び出すカスタムフック
 */
export const useSnackBar = () => {
  const setSnackBarState = useSetRecoilState(snackBarState);

  const toggleSnack = (isOpen, type, message) => {
    setSnackBarState({
      isOpen: isOpen,
      type: type,
      message: message,
    });
  };

  return {
    toggleSnack,
  };
};
