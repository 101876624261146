import React, { useState } from 'react';
/**
 * データを登録or更新するカスタムフック
 */
export const useAddOrUpdateData = () => {
  const [responseObj, setResponseObj] = useState({});
  const [responseObj2, setResponseObj2] = useState({});
  const [isAddOrUpdateLoading, setIsAddOrUpdateLoading] = useState(false);

  /**
   * データの登録or更新を行う
   * @param {String} addOrUpdate
   * @param {String} api
   * @param {Array} params
   * @param {Number} count 2~ 続けて登録or更新を行う場合に使う
   */
  async function addOrUpdateData(addOrUpdate, api, params, count = 1) {
    setIsAddOrUpdateLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/FILMa_${
      addOrUpdate + api
    }?code=${process.env.REACT_APP_API_CODE}`;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return { error: error };
      });

    if (count === 1) {
      await setResponseObj(response);
    } else if (count === 2) {
      await setResponseObj2(response);
    }

    setIsAddOrUpdateLoading(false);

    window.location.replace(location.href);
  }

  return {
    isAddOrUpdateLoading, // ローディング
    responseObj, // 1回目のレスポンス
    responseObj2, // 2回目のレスポンス
    addOrUpdateData, // 登録or更新の関数
  };
};
