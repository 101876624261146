import React from 'react';
import { DataTable } from 'components/uiParts/DataGrid/DataTable';
import { Breadcrumb } from 'components/uiParts/Breadcrumb';
// 定数ファイル
import { CUSTOMER_CATEGORY_COLUMNS } from 'utils/constantsTable';

export const CustomerCategory3ListPage = () => {
  const breadcrumbList = [
    { name: 'ダッシュボード', href: '/' },
    { name: '得意先区分3一覧', href: '/customerCategory3/list' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <DataTable
        title='得意先区分3一覧'
        columns={CUSTOMER_CATEGORY_COLUMNS}
        table='CustomerCategory3'
        api='CustomerCategory3'
        isCreateBtn
        isDetailBtn
        isDeleteBtn
        createUrl='/customerCategory3/create'
        detailUrl='/customerCategory3/detail'
        defaultFilterColumn='name'
        defaultFilterOperator='contains'
      />
    </>
  );
};
