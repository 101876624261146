import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// MUI
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
// コンポーネント
import { BorderTop } from 'components/uiParts/BorderTop';
import { Required } from 'components/uiParts/Required';
import { DataTable } from 'components/uiParts/DataGrid/DataTable';
import { SubTitle } from 'components/uiParts/SubTitle';
import { STOCK_HISTORY_TABLE_COLUMNS } from 'utils/constantsTable';
import { CustomDatePicker } from 'components/uiParts/CustomDatePicker';
import { ChangeLengthModal } from 'components/uiParts/Modal/ChangeLengthModal';
import { DisposalConfModal } from 'components/uiParts/Modal/DisposalConfModal';
import { Loading } from 'components/uiParts/Loading';
// カスタムフック
import { useUpdateStockForm } from 'hooks/useStockForm';

export const UpdateStockForm = (props) => {
  const { api, columns, redirectUrl } = props;

  const [selectedOrderDate, setSelectedOrderDate] = useState('');

  const {
    id,
    isLoading,
    formInputValue,
    isFormValid, // 更新フォームのボタンを押せるか
    addLoadingUpdateForm, // ローディング
    errors,
    sHFormInputValue,
    isChangeLengthBtn,
    openChangeLengthModal,
    setOpenChangeLengthModal,
    handleChangeUpdateForm, // 更新フォームのチェンジメソッド
    handleClickUpdateRows, // 更新を行うメソッド
    changeLength,
    handleChangeLengthForm,
    isReacquireData,
    setSHRows,
    helperText,
    handleCloseChangeLengthModal,
    openDisposalConfModal,
    setOpenDisposalConfModal,
    handleClickDisposal,
    isAddOrUpdateLoading,
    handleChangeDateUpdateForm,
  } = useUpdateStockForm(api, columns, redirectUrl);

  useEffect(() => {
    if (!formInputValue.length) return;
    if (formInputValue[0].stocking_date === undefined) return;
    setSelectedOrderDate(formInputValue[0].stocking_date);
  }, [formInputValue]);

  // 仕入れ日を変更時にformInputValueに入れる
  useEffect(() => {
    if (selectedOrderDate === '') return;
    if (formInputValue[0].stocking_date === undefined) return;
    handleChangeDateUpdateForm(selectedOrderDate);
  }, [selectedOrderDate]);

  // 画面サイズ取得
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <>
      <Table
        sx={{ backgroundColor: '#EBEBEB', margin: '0', width: 'auto' }}
        size='small'
        aria-label='a dense table'
      >
        <TableBody>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ width: '130px', border: 0 }}>商品ID</TableCell>
            <TableCell sx={{ border: 0, padding: '16px' }}>
              {formInputValue.length > 0 && formInputValue[0].film_products_master_id !== undefined
                ? formInputValue[0].film_products_master_id
                : null}
            </TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ width: '150px', border: 0 }}>商品名</TableCell>
            <TableCell sx={{ width: '200px', border: 0, padding: '16px' }}>
              {formInputValue.length > 0 && formInputValue[0].ProductName !== undefined
                ? formInputValue[0].ProductName
                : null}
            </TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ border: 0 }}>厚み</TableCell>
            <TableCell sx={{ border: 0, padding: '16px' }}>
              {formInputValue.length > 0 && formInputValue[0].thickness !== undefined
                ? formInputValue[0].thickness
                : null}
            </TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ border: 0 }}>幅</TableCell>
            <TableCell sx={{ border: 0, padding: '16px' }}>
              {formInputValue.length > 0 && formInputValue[0].width !== undefined
                ? formInputValue[0].width
                : null}
            </TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ border: 0 }}>長さ</TableCell>
            <TableCell sx={{ border: 0, padding: '16px' }}>
              {formInputValue.length > 0 && formInputValue[0].length !== undefined
                ? formInputValue[0].length
                : ''}
            </TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ border: 0 }}>重量</TableCell>
            <TableCell sx={{ border: 0, padding: '16px' }}>
              {formInputValue.length > 0 && formInputValue[0].weight !== undefined
                ? formInputValue[0].weight
                : null}
            </TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ border: 0 }}>原価</TableCell>
            <TableCell sx={{ border: 0, padding: '16px' }}>
              {formInputValue.length > 0 && formInputValue[0].cost_price !== undefined
                ? formInputValue[0].cost_price
                : null}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <BorderTop />
      <Table sx={{ margin: '20px 0 0', width: 'auto' }} size='small' aria-label='a dense table'>
        {900 > windowDimensions.width ? (
          <>
            {columns.map(
              (column, index) =>
                column.update && (
                  <TableBody key={index}>
                    <TableRow key={index} sx={{ border: 0 }}>
                      <TableCell sx={{ width: '150px', border: 0, paddingLeft: 0 }}>
                        {column.headerName}
                        {column.reauired && <Required />}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ border: 0, paddingLeft: 0 }}>
                        {column.formType === 'date' && (
                          <CustomDatePicker
                            isDisabled={formInputValue[0] && formInputValue[0].status === '廃棄'}
                            date={selectedOrderDate}
                            setDate={setSelectedOrderDate}
                          />
                        )}
                        {column.formType === 'select' &&
                          column.valueOptions &&
                          column.valueOptions.length > 0 && (
                            <TextField
                              name={column.field}
                              onChange={(e) =>
                                handleChangeUpdateForm(
                                  e,
                                  column.formType === 'number' ? 'number' : 'text'
                                )
                              }
                              select
                              value={
                                formInputValue[0] && formInputValue[0][column.field]
                                  ? formInputValue[0][column.field]
                                  : column.valueOptions2[0]
                              }
                              size='small'
                              sx={{ width: '260px' }}
                              required
                              disabled={
                                column.disabled ||
                                (formInputValue[0] && formInputValue[0].status === '廃棄')
                              }
                            >
                              {column.valueOptions2.map((data, index) => (
                                <MenuItem key={index} value={data}>
                                  {data}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        {column.formType !== 'select' && column.formType !== 'date' && (
                          <>
                            {/* 残長物理はモーダルから入力する */}
                            {column.field === 'length_ph' ? (
                              <ChangeLengthModal
                                open={openChangeLengthModal}
                                setOpen={setOpenChangeLengthModal}
                                formInputValue={formInputValue}
                                sHFormInputValue={sHFormInputValue}
                                isChangeLengthBtn={isChangeLengthBtn}
                                addLoadingUpdateForm={addLoadingUpdateForm}
                                handleChangeUpdateForm={handleChangeUpdateForm}
                                changeLength={changeLength}
                                handleChangeLengthForm={handleChangeLengthForm}
                                errors={errors}
                                helperText={helperText}
                                handleClose={handleCloseChangeLengthModal}
                              />
                            ) : (
                              <TextField
                                name={column.field}
                                onChange={(e) =>
                                  handleChangeUpdateForm(
                                    e,
                                    column.formType === 'number' ? 'number' : 'text'
                                  )
                                }
                                value={
                                  formInputValue[0] && formInputValue[0][column.field]
                                    ? formInputValue[0][column.field]
                                    : ''
                                }
                                disabled={
                                  column.disabled ||
                                  (formInputValue[0] && formInputValue[0].status === '廃棄')
                                }
                                sx={{ width: '260px' }}
                                size='small'
                                multiline={column.inputRows > 1 ? true : false}
                                rows={column.inputRows > 1 ? column.inputRows : 1}
                                // type={column.formType === 'number' ? 'number' : 'text'}
                                inputProps={
                                  column.formType === 'number'
                                    ? { inputMode: 'numeric', pattern: '[0-9]*' }
                                    : {}
                                }
                                error={errors[column.field] ? true : false}
                                helperText={errors[column.field] ? helperText : false}
                              />
                            )}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
            )}
          </>
        ) : (
          <TableBody>
            {columns.map(
              (column, index) =>
                column.update && (
                  <TableRow key={index} sx={{ border: 0 }}>
                    <TableCell sx={{ width: '150px', border: 0, paddingLeft: 0 }}>
                      {column.headerName}
                      {column.reauired && <Required />}
                    </TableCell>
                    <TableCell sx={{ border: 0, paddingLeft: 0 }}>
                      {column.formType === 'date' && (
                        <CustomDatePicker
                          isDisabled={formInputValue[0] && formInputValue[0].status === '廃棄'}
                          date={selectedOrderDate}
                          setDate={setSelectedOrderDate}
                        />
                      )}
                      {column.formType === 'select' &&
                        column.valueOptions &&
                        column.valueOptions.length > 0 && (
                          <TextField
                            name={column.field}
                            onChange={(e) =>
                              handleChangeUpdateForm(
                                e,
                                column.formType === 'number' ? 'number' : 'text'
                              )
                            }
                            select
                            value={
                              formInputValue[0] && formInputValue[0][column.field]
                                ? formInputValue[0][column.field]
                                : column.valueOptions2[0]
                            }
                            size='small'
                            sx={{ width: '260px' }}
                            required
                            disabled={
                              column.disabled ||
                              (formInputValue[0] && formInputValue[0].status === '廃棄')
                            }
                          >
                            {column.valueOptions2.map((data, index) => (
                              <MenuItem key={index} value={data}>
                                {data}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      {column.formType !== 'select' && column.formType !== 'date' && (
                        <>
                          {/* 残長物理はモーダルから入力する */}
                          {column.field === 'length_ph' ? (
                            <ChangeLengthModal
                              open={openChangeLengthModal}
                              setOpen={setOpenChangeLengthModal}
                              formInputValue={formInputValue}
                              sHFormInputValue={sHFormInputValue}
                              isChangeLengthBtn={isChangeLengthBtn}
                              addLoadingUpdateForm={addLoadingUpdateForm}
                              handleChangeUpdateForm={handleChangeUpdateForm}
                              changeLength={changeLength}
                              handleChangeLengthForm={handleChangeLengthForm}
                              errors={errors}
                              helperText={helperText}
                              handleClose={handleCloseChangeLengthModal}
                            />
                          ) : (
                            <TextField
                              name={column.field}
                              onChange={(e) =>
                                handleChangeUpdateForm(
                                  e,
                                  column.formType === 'number' ? 'number' : 'text'
                                )
                              }
                              value={
                                formInputValue[0] && formInputValue[0][column.field]
                                  ? formInputValue[0][column.field]
                                  : ''
                              }
                              disabled={
                                column.disabled ||
                                (formInputValue[0] && formInputValue[0].status === '廃棄')
                              }
                              sx={{ width: '260px' }}
                              size='small'
                              multiline={column.inputRows > 1 ? true : false}
                              rows={column.inputRows > 1 ? column.inputRows : 1}
                              // type={column.formType === 'number' ? 'number' : 'text'}
                              inputProps={
                                column.formType === 'number'
                                  ? { inputMode: 'numeric', pattern: '[0-9]*' }
                                  : {}
                              }
                              error={errors[column.field] ? true : false}
                              helperText={errors[column.field] ? helperText : false}
                            />
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        )}
      </Table>
      <Loading isLoading={isLoading} />
      <div style={{ height: '30px' }}></div>
      <SubTitle title='・変更履歴' hideBorder={true} />
      {id && (
        <DataTable
          title=''
          columns={STOCK_HISTORY_TABLE_COLUMNS}
          params={[{ film_stocks_master_id: id }]}
          table='StockHistory'
          api='StockHistoryForUser'
          isReacquireData={isReacquireData}
          getRows={setSHRows}
        />
      )}
      <BorderTop />
      <div style={{ textAlign: 'right' }}>
        <LoadingButton
          disabled={isFormValid || (formInputValue[0] && formInputValue[0].status === '廃棄')}
          onClick={handleClickUpdateRows}
          variant='contained'
          loading={addLoadingUpdateForm}
        >
          更新する
        </LoadingButton>
      </div>
      <BorderTop />
      <div style={{ textAlign: 'right' }}>
        <LoadingButton
          disabled={formInputValue[0] && formInputValue[0].status === '廃棄'}
          onClick={handleClickDisposal}
          variant='contained'
          loading={addLoadingUpdateForm}
          color='success'
        >
          廃棄する
        </LoadingButton>
      </div>
      <DisposalConfModal
        open={openDisposalConfModal}
        setOpen={setOpenDisposalConfModal}
        handleClickUpdateRows={handleClickUpdateRows}
        id={formInputValue[0] && formInputValue[0].id ? formInputValue[0].id : null}
        addLoadingUpdateForm={addLoadingUpdateForm}
      />
    </>
  );
};

UpdateStockForm.propTypes = {
  api: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  redirectUrl: PropTypes.string.isRequired,
};
