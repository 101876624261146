import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from 'utils/css/Print.module.css';
import QRCode from 'qrcode.react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// eslint-disable-next-line react/display-name
export const ReceiptGoodsPDF = React.forwardRef((props, ref) => {
  const { title, pdfData, themeColor, formInputValue, settingDatas, clientDatas } = props;

  const [forLoop, setForLoop] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
  const cellStyle = { border: '1px solid ' + themeColor, padding: '2px 7px', height: '25px' };
  const titleStyle = {
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '20px',
    color: themeColor,
  };
  const thStyle = {
    fontSize: '14px',
    color: '#fff',
    background: themeColor,
    border: '1px solid ' + themeColor,
    padding: '2px 7px',
  };

  return (
    <>
      {pdfData.length > 0 && (
        <section className={styles.page}>
          <div ref={ref}>
            {pdfData[1] == undefined && (
              <>
                {pdfData[0][0] !== undefined && (
                  <div style={{ padding: '30px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ fontSize: '14px', fontWeight: 'bold', color: themeColor }}>
                        お客様コード{' '}
                        <spna style={{ fontWeight: 'normal', color: 'black' }}>
                          {pdfData[0][0].film_clients_master_id !== undefined &&
                            ('0000' + pdfData[0][0].film_clients_master_id).slice(-4)}
                          <br />
                          {clientDatas[0] !== undefined && clientDatas[0].address !== '' ? (
                            <>
                              {clientDatas[0].address}
                              <br />
                            </>
                          ) : (
                            <></>
                          )}
                          {clientDatas[0] !== undefined && clientDatas[0].after_address !== '' ? (
                            <>
                              {clientDatas[0].after_address}
                              <br />
                            </>
                          ) : (
                            <></>
                          )}
                          {clientDatas[0] !== undefined && clientDatas[0].name !== '' ? (
                            <>{clientDatas[0].name}</>
                          ) : (
                            <></>
                          )}
                          {clientDatas[0] !== undefined && clientDatas[0].title_of_honor !== '' ? (
                            <>
                              {' '}
                              {clientDatas[0].title_of_honor}
                              <br />
                            </>
                          ) : (
                            <></>
                          )}
                          {clientDatas[0] !== undefined && clientDatas[0].tel !== '' ? (
                            <>
                              {'TEL '}
                              {clientDatas[0].tel}
                              <br />
                            </>
                          ) : (
                            <></>
                          )}
                        </spna>
                      </div>
                      <div style={titleStyle}>{title}</div>
                      <div style={{ fontSize: '14px', fontWeight: 'bold', color: themeColor }}>
                        No{' '}
                        {pdfData[0][0].film_orders_master_id !== undefined &&
                          ('000000' + pdfData[0][0].film_orders_master_id).slice(-6)}
                        <br />
                        {formInputValue.Date.split('-')[0] +
                          '年' +
                          formInputValue.Date.split('-')[1] +
                          '月' +
                          formInputValue.Date.split('-')[2] +
                          '日'}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '15px 0 0',
                      }}
                    >
                      <div
                        style={{
                          width: '50%',
                          fontSize: '14px',
                          display: 'flex',
                          alignItems: 'flex-end',
                        }}
                      >
                        {settingDatas.length >= 0 &&
                          settingDatas[0].delivery_wording !== undefined &&
                          settingDatas[0].delivery_wording}
                      </div>
                      <div style={{ width: '35%', fontSize: '14px' }}>
                        {settingDatas.length >= 0 &&
                          settingDatas[0].name !== undefined &&
                          settingDatas[0].name}
                        <br />
                        {settingDatas.length >= 0 &&
                          settingDatas[0].address !== undefined &&
                          settingDatas[0].address}
                        <br />
                        TEL{' '}
                        {settingDatas.length >= 0 &&
                          settingDatas[0].tel1 !== undefined &&
                          settingDatas[0].tel1}{' '}
                        FAX{' '}
                        {settingDatas.length >= 0 &&
                          settingDatas[0].tel2 !== undefined &&
                          settingDatas[0].tel2}
                      </div>
                    </div>

                    <Table sx={{ margin: '10px 0 0', minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ ...thStyle, ...{ width: '40%' } }} align='center'>
                            品名・規格
                          </TableCell>
                          <TableCell sx={{ ...thStyle, ...{ width: '8%' } }} align='center'>
                            数量
                          </TableCell>
                          <TableCell sx={{ ...thStyle, ...{ width: '8%' } }} align='center'>
                            単位
                          </TableCell>
                          <TableCell sx={{ ...thStyle, ...{ width: '12%' } }} align='center'>
                            受領印
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {forLoop.map((data, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell sx={{ ...cellStyle }} align='left'>
                                {pdfData[0][data] !== undefined &&
                                  pdfData[0][data].processing_product_name !== undefined &&
                                  pdfData[0][data].processing_product_name}
                                <span style={{ fontSize: '12px' }}>
                                  {pdfData[0][data] !== undefined && (
                                    <>
                                      {pdfData[0][data].order_process !== undefined &&
                                        ' ' + pdfData[0][data].order_process + ' '}
                                      {pdfData[0][data].order_thickness !== undefined &&
                                        pdfData[0][data].order_thickness}
                                      {pdfData[0][data].order_width !== undefined &&
                                        ' × ' + pdfData[0][data].order_width}
                                      {pdfData[0][data].order_length !== undefined &&
                                        ' × ' + pdfData[0][data].order_length}
                                    </>
                                  )}
                                </span>
                              </TableCell>
                              <TableCell sx={{ ...cellStyle }} align='center'>
                                {pdfData[0][data] !== undefined &&
                                  pdfData[0][data].order_number !== undefined &&
                                  pdfData[0][data].order_number}
                              </TableCell>
                              <TableCell sx={{ ...cellStyle }} align='center'>
                                {pdfData[0][data] !== undefined &&
                                  pdfData[0][data].unit !== undefined &&
                                  pdfData[0][data].unit}
                              </TableCell>
                              {index === 0 && (
                                <TableCell
                                  rowSpan='10'
                                  sx={{ ...cellStyle, width: '40%' }}
                                  align='center'
                                ></TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                )}
              </>
            )}

            {pdfData[1] !== undefined && (
              <>
                {pdfData.map((datas, indexs) => {
                  return (
                    <>
                      {pdfData[0][0] !== undefined && (
                        <div style={{ padding: '30px' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div
                              style={{ fontSize: '14px', fontWeight: 'bold', color: themeColor }}
                            >
                              お客様コード{' '}
                              <spna style={{ fontWeight: 'normal', color: 'black' }}>
                                {pdfData[0][0].film_clients_master_id !== undefined &&
                                  ('0000' + pdfData[0][0].film_clients_master_id).slice(-4)}
                                <br />
                                {clientDatas[0] !== undefined && clientDatas[0].address !== '' ? (
                                  <>
                                    {clientDatas[0].address}
                                    <br />
                                  </>
                                ) : (
                                  <></>
                                )}
                                {clientDatas[0] !== undefined &&
                                clientDatas[0].after_address !== '' ? (
                                  <>
                                    {clientDatas[0].after_address}
                                    <br />
                                  </>
                                ) : (
                                  <></>
                                )}
                                {clientDatas[0] !== undefined && clientDatas[0].name !== '' ? (
                                  <>{clientDatas[0].name}</>
                                ) : (
                                  <></>
                                )}
                                {clientDatas[0] !== undefined &&
                                clientDatas[0].title_of_honor !== '' ? (
                                  <>
                                    {' '}
                                    {clientDatas[0].title_of_honor}
                                    <br />
                                  </>
                                ) : (
                                  <></>
                                )}
                                {clientDatas[0] !== undefined && clientDatas[0].tel !== '' ? (
                                  <>
                                    {'TEL '}
                                    {clientDatas[0].tel}
                                    <br />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </spna>
                            </div>
                            <div style={titleStyle}>{title}</div>
                            <div
                              style={{ fontSize: '14px', fontWeight: 'bold', color: themeColor }}
                            >
                              No{' '}
                              {pdfData[0][0].film_orders_master_id !== undefined &&
                                ('000000' + pdfData[0][0].film_orders_master_id).slice(-6) + '-' + (indexs + 1)}
                              <br />
                              {formInputValue.Date.split('-')[0] +
                                '年' +
                                formInputValue.Date.split('-')[1] +
                                '月' +
                                formInputValue.Date.split('-')[2] +
                                '日'}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              margin: '15px 0 0',
                            }}
                          >
                            <div
                              style={{
                                width: '50%',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'flex-end',
                              }}
                            >
                              {settingDatas.length >= 0 &&
                                settingDatas[0].delivery_wording !== undefined &&
                                settingDatas[0].delivery_wording}
                            </div>
                            <div style={{ width: '35%', fontSize: '14px' }}>
                              {settingDatas.length >= 0 &&
                                settingDatas[0].name !== undefined &&
                                settingDatas[0].name}
                              <br />
                              {settingDatas.length >= 0 &&
                                settingDatas[0].address !== undefined &&
                                settingDatas[0].address}
                              <br />
                              TEL{' '}
                              {settingDatas.length >= 0 &&
                                settingDatas[0].tel1 !== undefined &&
                                settingDatas[0].tel1}{' '}
                              FAX{' '}
                              {settingDatas.length >= 0 &&
                                settingDatas[0].tel2 !== undefined &&
                                settingDatas[0].tel2}
                            </div>
                          </div>

                          <Table sx={{ margin: '10px 0 0', minWidth: 650 }}>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ ...thStyle, ...{ width: '40%' } }} align='center'>
                                  品名・規格
                                </TableCell>
                                <TableCell sx={{ ...thStyle, ...{ width: '8%' } }} align='center'>
                                  数量
                                </TableCell>
                                <TableCell sx={{ ...thStyle, ...{ width: '8%' } }} align='center'>
                                  単位
                                </TableCell>
                                <TableCell sx={{ ...thStyle, ...{ width: '12%' } }} align='center'>
                                  受領印
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {forLoop.map((data, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell sx={{ ...cellStyle }} align='left'>
                                      {datas[data] !== undefined &&
                                        datas[data].processing_product_name !== undefined &&
                                        datas[data].processing_product_name}
                                      <span style={{ fontSize: '12px' }}>
                                        {datas[data] !== undefined && (
                                          <>
                                            {datas[data].order_process !== undefined &&
                                              ' ' + datas[data].order_process + ' '}
                                            {datas[data].order_thickness !== undefined &&
                                              datas[data].order_thickness}
                                            {datas[data].order_width !== undefined &&
                                              ' × ' + datas[data].order_width}
                                            {datas[data].order_length !== undefined &&
                                              ' × ' + datas[data].order_length}
                                          </>
                                        )}
                                      </span>
                                    </TableCell>
                                    <TableCell sx={{ ...cellStyle }} align='center'>
                                      {datas[data] !== undefined &&
                                        datas[data].order_number !== undefined &&
                                        datas[data].order_number}
                                    </TableCell>
                                    <TableCell sx={{ ...cellStyle }} align='center'>
                                      {datas[data] !== undefined &&
                                        datas[data].unit !== undefined &&
                                        datas[data].unit}
                                    </TableCell>
                                    {index === 0 && (
                                      <TableCell
                                        rowSpan='10'
                                        sx={{ ...cellStyle, width: '40%' }}
                                        align='center'
                                      ></TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </div>
                      )}
                      <div className={styles.page}></div>
                    </>
                  );
                })}
              </>
            )}
          </div>
        </section>
      )}
    </>
  );
});

ReceiptGoodsPDF.propTypes = {
  pdfData: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  themeColor: PropTypes.string.isRequired,
  formInputValue: PropTypes.object.isRequired,
  settingDatas: PropTypes.array.isRequired,
  clientDatas: PropTypes.array.isRequired,
};
