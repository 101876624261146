import React from 'react';
import PropTypes from 'prop-types';
// MUI
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
// コンポーネント
import { BorderTop } from 'components/uiParts/BorderTop';
import { SelectSellingPriceModal } from 'components/uiParts/Modal/SelectSellingPriceModal';
import { SelectProcessingCostModal } from 'components/uiParts/Modal/SelectProcessingCostModal';
import { Required } from 'components/uiParts/Required';
// カスタムフック
import { useCreateProductForm } from 'hooks/useCreateProductForm';
import { useGetFormType } from 'hooks/useGetFormType';

export const CreateProductForm = (props) => {
  const { columns } = props;

  // カスタムフック
  const {
    formInputValue,
    addLoadingForm,
    isCreateFormValid,
    isSelected,
    isPCSelected,
    openSPModal,
    openPCModal,
    checkBox,
    handleClickSellingPriceForm,
    handleClickProcessingCostForm,
    handleClickCreateForm,
    handleChangeCreateForm,
    setOpenSPModal,
    setOpenPCModal,
    handleCheckBoxChange,
  } = useCreateProductForm(columns);

  const { formTypeList } = useGetFormType();
  return (
    <>
      <Table sx={{ margin: '20px 0 0', width: 'auto' }} size='small' aria-label='a dense table'>
        <TableBody>
          <TableRow sx={{ border: 0 }}>
            <TableCell
              sx={{
                width: '130px',
                border: 0,
                paddingLeft: 0,
                position: 'relative',
                paddingRight: '50px',
              }}
            >
              特殊商品
            </TableCell>
            <TableCell sx={{ border: 0, paddingLeft: 0 }}>
              <Checkbox
                checked={checkBox}
                onChange={(event) => handleCheckBoxChange(event.target.checked)}
              />
            </TableCell>
          </TableRow>
          {columns.map(
            (column, index) =>
              column.create && (
                <TableRow key={index} sx={{ border: 0 }}>
                  <TableCell
                    sx={{
                      width: '130px',
                      border: 0,
                      paddingLeft: 0,
                      position: 'relative',
                      paddingRight: '50px',
                    }}
                  >
                    {column.headerName}
                    {checkBox
                      ? column.headerName === '商品名' &&
                        column.reauired && (
                          <span style={{ position: 'absolute', right: '10px' }}>
                            <Required />
                          </span>
                        )
                      : column.reauired && (
                          <span style={{ position: 'absolute', right: '10px' }}>
                            <Required />
                          </span>
                        )}
                  </TableCell>
                  {column.formType === 'select' ? (
                    <>
                      {column.field === 'film_form_types_master_id' ? ( // 形状はAPIでセレクトボックスを作成する
                        <TableCell sx={{ border: 0, paddingLeft: 0 }}>
                          {formTypeList.length > 0 && (
                            <TextField
                              name={column.field}
                              onChange={(e) =>
                                handleChangeCreateForm(
                                  e,
                                  column.formType === 'number' ? 'number' : 'text'
                                )
                              }
                              select
                              defaultValue='2' // デフォルトはチューブ
                              size='small'
                              sx={{ margin: '10px 0 0', width: '100%' }}
                            >
                              {formTypeList.map((data, index) => (
                                <MenuItem key={index} value={data.id}>
                                  {data.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        </TableCell>
                      ) : (
                        <TableCell sx={{ border: 0, paddingLeft: 0 }}>
                          {column.valueOptions && column.valueOptions.length > 0 && (
                            <TextField
                              name={column.field}
                              onChange={(e) =>
                                handleChangeCreateForm(
                                  e,
                                  column.formType === 'number' ? 'number' : 'text'
                                )
                              }
                              select
                              defaultValue={column.defaultValueOption} // デフォルトは許可
                              size='small'
                              sx={{ margin: '10px 0 0', width: '100%' }}
                            >
                              {column.valueOptions.map((data, index) => (
                                <MenuItem key={index} value={data}>
                                  {data}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        </TableCell>
                      )}
                    </>
                  ) : (
                    <TableCell sx={{ border: 0, paddingLeft: 0 }}>
                      <TextField
                        name={column.field}
                        onChange={(e) =>
                          handleChangeCreateForm(
                            e,
                            column.formType === 'number' ? 'number' : 'text'
                          )
                        }
                        sx={{ width: '260px' }}
                        size='small'
                        value={formInputValue[column.field] ? formInputValue[column.field] : ''}
                      />
                    </TableCell>
                  )}
                </TableRow>
              )
          )}
          <TableRow sx={{ border: 0 }}>
            <TableCell
              sx={{
                width: '130px',
                border: 0,
                paddingLeft: 0,
                position: 'relative',
                paddingRight: '50px',
              }}
            >
              販売価格
              {!checkBox && (
                <span style={{ position: 'absolute', right: '10px' }}>
                  <Required />
                </span>
              )}
            </TableCell>
            <TableCell sx={{ border: 0, padding: '16px 0' }}>
              <SelectSellingPriceModal
                open={openSPModal}
                setOpen={setOpenSPModal}
                create
                titleLeft='グループ名'
                titleRight='販売価格/m'
                datas={[null, null, null, null]}
                handleClickForm={handleClickSellingPriceForm}
                isSelected={isSelected}
                checkBox={checkBox}
              />
            </TableCell>
          </TableRow>
          <TableRow sx={{ border: 0 }}>
            <TableCell sx={{ border: 0, paddingLeft: 0 }}>加工費</TableCell>
            <TableCell sx={{ border: 0, padding: '16px 0' }}>
              <SelectProcessingCostModal
                open={openPCModal}
                setOpen={setOpenPCModal}
                create
                titleLeft='得意先'
                titleRight='加工費/m'
                datas={formInputValue.processing_price}
                handleClickForm={handleClickProcessingCostForm}
                isPCSelected={isPCSelected}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <BorderTop />
      <div style={{ textAlign: 'right' }}>
        <LoadingButton
          disabled={isCreateFormValid}
          onClick={handleClickCreateForm}
          variant='contained'
          loading={addLoadingForm}
        >
          商品を登録する
        </LoadingButton>
      </div>
    </>
  );
};

CreateProductForm.propTypes = {
  columns: PropTypes.array.isRequired, // テーブルのカラム
};
