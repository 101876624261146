import React, { useState } from 'react';
import { DataTable } from 'components/uiParts/DataGrid/DataTable';
import { CLIENT_TABLE_COLUMNS } from 'utils/constantsTable';
import { Breadcrumb } from 'components/uiParts/Breadcrumb';
// カスタムフック
import { useUsageCategory } from 'hooks/useUsageCategory';

export const ClientListPage = () => {
  // カスタムフック
  const { params, handleClickChangeUCStatus } = useUsageCategory();

  const breadcrumbList = [
    { name: 'ダッシュボード', href: '/' },
    { name: '顧客一覧', href: '/client/list' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <DataTable
        title='顧客一覧'
        columns={CLIENT_TABLE_COLUMNS}
        table='Client'
        api='ClientForUser'
        isCreateBtn
        isDetailBtn
        isDeleteBtn
        createUrl='/client/create'
        detailUrl='/client/detail'
        params={params}
        handleClickChangeUCStatus={handleClickChangeUCStatus}
        columnVisibilityList={{
          film_sales_price_categories_master_id: false,
          film_processing_price_categories_master_id: false,
          film_customer_categories_1_master_id: false,
          film_customer_categories_2_master_id: false,
          film_customer_categories_3_master_id: false,
        }}
      />
    </>
  );
};
