import React from 'react';
// コンポーネント
import { SubTitle } from 'components/uiParts/SubTitle';
import { CreateClientForm } from 'components/uiParts/Form/CreateClientForm';
import { Breadcrumb } from 'components/uiParts/Breadcrumb';
// 定数ファイル
import { CLIENT_FORM_COLUMNS } from 'utils/constantsTable';
// MUI
import Button from '@mui/material/Button';

export const ClientCreatePage = () => {
  const breadcrumbList = [
    { name: 'ダッシュボード', href: '/' },
    { name: '顧客一覧', href: '/client/list' },
    { name: '顧客登録' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SubTitle title='顧客登録' />
        <Button href={location.origin + '/client/list'} variant='contained' color='inherit'>
          戻る
        </Button>
      </div>
      <CreateClientForm columns={CLIENT_FORM_COLUMNS} />
    </>
  );
};

// 顧客一覧
// 顧客登録
// まとめて登録は無し
// 顧客詳細
// 変更を反映したら一覧に戻る
// ハウス登録は無し
