import React from 'react';
import { DataTable } from 'components/uiParts/DataGrid/DataTable';
import { PRODUCT_TABLE_COLUMNS } from 'utils/constantsTable';
import { Breadcrumb } from 'components/uiParts/Breadcrumb';
// カスタムフック
import { useUsageCategory } from 'hooks/useUsageCategory';

export const ProductListPage = () => {
  // カスタムフック
  const { params, handleClickChangeUCStatus } = useUsageCategory();

  const breadcrumbList = [
    { name: 'ダッシュボード', href: '/' },
    { name: '商品一覧', href: '/product/list' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <DataTable
        title='商品一覧'
        columns={PRODUCT_TABLE_COLUMNS}
        table='Product'
        api='ProductForUser'
        isCreateBtn
        isDetailBtn
        isDeleteBtn
        createUrl='/product/create'
        detailUrl='/product/detail'
        params={params}
        handleClickChangeUCStatus={handleClickChangeUCStatus}
        defaultColumnVisibilityList={{ film_form_types_master_id: false }}
      />
    </>
  );
};
