import React, { useState, useEffect, useCallback } from 'react';

// カスタムフック
import { useFetchDataWithInit } from 'hooks/useFetchData';
import { useSnackBar } from 'hooks/useSnackBar';

/**
 * 加工区分を取得するカスタムフック
 */
export const useGetProcessingPriceCategory = () => {
  const [pPCategory, setPPCategory] = useState([]);

  const url = `${process.env.REACT_APP_API_URL}/FILMa_GetProcessingPriceCategory?code=${process.env.REACT_APP_API_CODE}`;

  // カスタムフック
  const { data, error, isLoading, reacquireData } = useFetchDataWithInit(url, []);
  const { toggleSnack } = useSnackBar();

  useEffect(() => {
    if (!data) return;
    let status = 'success';
    let message = '加工区分の取得に失敗しました。';
    if (!data.code) {
      // 成功時
      setPPCategory(data.result);
    } else {
      // 失敗時
      status = 'error';
      toggleSnack(true, status, message);
    }
  }, [data]);

  // エラーの場合
  useEffect(() => {
    if (!error) return;
    toggleSnack(true, 'error', '加工区分の取得に失敗しました。');
  }, [error]);

  return {
    pPCategory,
  };
};
