import React, { useRef } from 'react';
import PropTypes from 'prop-types';
// MUI
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
// コンポーネント
import { EditDataTable } from 'components/uiParts/DataGrid/EditDataTable';
import { SubTitle } from 'components/uiParts/SubTitle';
import { BorderTop } from 'components/uiParts/BorderTop';
// 定数
import { SUB_ORDER_MODAL_TABLE_COLUMNS_FOR_ORDER } from 'utils/constantsTable';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export const SubOrderListModal = (props) => {
  const {
    openSubOrderListModal,
    setOpenSubOrderListModal,
    id,
    openStockModal,
    setOpenStockModal,
    handleClickProcessingSheet,
    deleteIds,
    setDeleteIds,
    emptyRows,
    editRows,
    editModalRows,
    setEditModalRows,
    isPageReload,
    clientInfo,
    setClientInfo,
    selectedOrderDate,
    setSelectedOrderDate,
    setSelectedOrderMoney,
    setSelectedDeliveryDate,
    handleClickStartProcessingBtn,
    handleClickOpenSelectStock,
    handleClickOpenSelectStocks,
    selectedSubOrderData,
    handleClickUpdateOrderForm,
    addLoadingForm,
    setIsViewStockSelectList,
    setSelectedSubOrderRows,
    resetSelectedRowIds,
    handleClickProcessingBulk,
  } = props;

  const editStyle = useRef({
    '& div[data-field="order_length"]:has(.uneditable)': {
      pointerEvents: 'none',
    },
    '& div[data-field="order_process"]:has(.uneditable)': {
      pointerEvents: 'none',
    },
    '& div[data-field="order_house_name"]:has(.uneditable)': {
      pointerEvents: 'none',
    },
    '& div[data-field="sub_order_remark"]:has(.uneditable)': {
      pointerEvents: 'none',
    },
    '& div[data-field="outsourcing"]:has(.uneditable)': {
      pointerEvents: 'none',
    },
    '& div[data-field="order_length"]:has(.uneditable):after': {
      content: 'none',
    },
    '& div[data-field="order_process"]:has(.uneditable):after': {
      content: 'none',
    },
    '& div[data-field="order_house_name"]:has(.uneditable):after': {
      content: 'none',
    },
    '& div[data-field="sub_order_remark"]:has(.uneditable):after': {
      content: 'none',
    },
    '& div[data-field="outsourcing"]:has(.uneditable):after': {
      content: 'none',
    },
  });

  return (
    <div>
      <Modal
        open={openSubOrderListModal}
        onClose={() => setOpenSubOrderListModal(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <IconButton
            sx={{ position: 'absolute', right: '5px', top: '5px' }}
            color='primary'
            onClick={() => setOpenSubOrderListModal(false)}
          >
            <CloseIcon />
          </IconButton>
          <>
            <div>
              <SubTitle title='注文商品' hideBorder={true} />
              <div style={{ position: 'relative' }}>
                <div style={{ overflow: 'auto' }}>
                  <EditDataTable
                    update
                    title=''
                    vh='40vh'
                    emptyRows={emptyRows}
                    editRows={editRows}
                    editModalRows={editModalRows}
                    setEditModalRows={setEditModalRows}
                    columns={SUB_ORDER_MODAL_TABLE_COLUMNS_FOR_ORDER}
                    api='SubOrderForUser'
                    params={[{ film_orders_master_id: id }]}
                    clientInfo={clientInfo}
                    checkboxSelection={true}
                    setClientInfo={setClientInfo}
                    selectedOrderDate={selectedOrderDate}
                    setSelectedOrderMoney={setSelectedOrderMoney}
                    setSelectedDeliveryDate={setSelectedDeliveryDate}
                    setSelectedOrderDate={setSelectedOrderDate}
                    isPOutputBtn
                    isStockBtn
                    isSubPSelectOutputBtn
                    handleClickProcessingSheet={handleClickProcessingSheet}
                    handleClickOpenSelectStock={handleClickOpenSelectStock}
                    handleClickOpenSelectStocks={handleClickOpenSelectStocks}
                    openStockModal={openStockModal}
                    setOpenStockModal={setOpenStockModal}
                    deleteIds={deleteIds}
                    setDeleteIds={setDeleteIds}
                    isPageReload={isPageReload}
                    handleClickStartProcessingBtn={handleClickStartProcessingBtn}
                    isSortSubOrderGIdForModal
                    selectedSubOrderData={selectedSubOrderData}
                    isSubOrderModal={true}
                    isSelectStocksBtn={true}
                    setIsViewStockSelectList={setIsViewStockSelectList}
                    setSelectedRows={setSelectedSubOrderRows}
                    rowSelectable={(params) => params.row.StatusCode < 2} // 在庫割り当てが完了している注文のチェックボックスは無効にする
                    resetSelectedRowIds={resetSelectedRowIds}
                    customDataGridStyle={editStyle.current}
                    handleClickProcessingBulk={handleClickProcessingBulk}
                  />
                </div>
              </div>
              <div style={{ height: '10px' }} />
              <BorderTop />
              <div style={{ textAlign: 'right' }}>
                <LoadingButton
                  disabled={editModalRows[0] || deleteIds.length > 0 ? false : true}
                  onClick={() => handleClickUpdateOrderForm(true)}
                  variant='contained'
                  loading={addLoadingForm}
                >
                  注文更新
                </LoadingButton>
              </div>
            </div>
          </>
        </Box>
      </Modal>
    </div>
  );
};

SubOrderListModal.propTypes = {
  id: PropTypes.number.isRequired,
  openStockModal: PropTypes.bool.isRequired,
  setOpenStockModal: PropTypes.func.isRequired,
  handleClickProcessingSheet: PropTypes.func.isRequired,
  deleteIds: PropTypes.array.isRequired,
  setDeleteIds: PropTypes.func.isRequired,
  emptyRows: PropTypes.array.isRequired,
  editRows: PropTypes.array.isRequired,
  editModalRows: PropTypes.array,
  setEditModalRows: PropTypes.func.isRequired,
  isPageReload: PropTypes.bool.isRequired,
  clientInfo: PropTypes.object.isRequired,
  setClientInfo: PropTypes.func.isRequired,
  selectedOrderDate: PropTypes.string.isRequired,
  setSelectedOrderDate: PropTypes.func.isRequired,
  setSelectedOrderMoney: PropTypes.func.isRequired,
  setSelectedDeliveryDate: PropTypes.func.isRequired,
  handleClickStartProcessingBtn: PropTypes.func.isRequired,
  handleClickOpenSelectStock: PropTypes.func.isRequired,
  handleClickOpenSelectStocks: PropTypes.func.isRequired,
  openSubOrderListModal: PropTypes.bool.isRequired,
  addLoadingForm: PropTypes.bool.isRequired,
  setOpenSubOrderListModal: PropTypes.func.isRequired,
  handleClickUpdateOrderForm: PropTypes.func.isRequired,
  selectedSubOrderData: PropTypes.object.isRequired,
  setIsViewStockSelectList: PropTypes.func.isRequired,
  setSelectedSubOrderRows: PropTypes.func.isRequired,
  resetSelectedRowIds: PropTypes.bool.isRequired,
  handleClickProcessingBulk: PropTypes.func,
};
