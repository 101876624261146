import React from 'react';
import { DataTable } from 'components/uiParts/DataGrid/DataTable';
import { SUB_ORDER_TABLE_COLUMNS } from 'utils/constantsTable';
import { Breadcrumb } from 'components/uiParts/Breadcrumb';
// カスタムフック
import { useShipped } from 'hooks/useShipped';

export const SubOrderListPage = () => {
  // カスタムフック
  const { params, handleClickShipped } = useShipped();
  const breadcrumbList = [
    { name: 'ダッシュボード', href: '/' },
    { name: '注文一覧', href: '/subOrder/list' },
  ];

  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <DataTable
        title='注文一覧'
        columns={SUB_ORDER_TABLE_COLUMNS}
        table='SubOrder'
        api='SubOrderForUser'
        params={params}
        handleClickShipped={handleClickShipped}
      />
    </>
  );
};
