import React, { useState, useEffect } from 'react';

export const useDrawerMenu = () => {
  const [open, setOpen] = useState(isSmartPhone());

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function isSmartPhone() {
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      return false;
    } else {
      return true;
    }
  }

  return {
    open,
    handleDrawerOpen,
    handleDrawerClose,
  };
};
