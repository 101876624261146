import React from 'react';
import PropTypes from 'prop-types';
// MUI
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Loading } from 'components/uiParts/Loading';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#EBEBEB',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const SelectStocksConfModal = (props) => {
  const { open, setOpen, handleClickUpdateRows, selectedStockId, addLoadingForm } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <IconButton
            sx={{ position: 'absolute', right: '5px', top: '5px' }}
            color='primary'
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <div style={{ padding: '10px 0' }}>
            <Box sx={{ width: '100%' }}>
              在庫ID:{selectedStockId}を選択中の注文に一括登録しますか？
              <div style={{ margin: '10px 0 0', textAlign: 'right' }}>
                <LoadingButton
                  onClick={() => handleClickUpdateRows(true)}
                  variant='contained'
                  loading={addLoadingForm}
                >
                  登録
                </LoadingButton>
              </div>
            </Box>
            <Loading isLoading={addLoadingForm} />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

SelectStocksConfModal.propTypes = {
  open: PropTypes.bool.isRequired, // モーダル開く
  addLoadingForm: PropTypes.bool.isRequired, // ローディング
  setOpen: PropTypes.func.isRequired, // モーダル開く
  handleClickUpdateRows: PropTypes.func.isRequired, // 登録する
  selectedStockId: PropTypes.number, // 選択した在庫ID
};
