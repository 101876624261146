import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Loading } from 'components/uiParts/Loading';

export const DeleteForm = (props) => {
  const {
    table,
    openDeleteForm,
    handleCloseDeleteForm,
    deleteRows,
    deleteTables,
    addLoadingForm,
    isFetchLoading,
    deleteIds,
    deleteTableList,
    isDeleteTables,
    loadinDelete,
  } = props;

  return (
    <Dialog
      open={openDeleteForm}
      onClose={handleCloseDeleteForm}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>以下を削除しますか？</DialogTitle>
      <DialogContent style={{ minWidth: '500px' }}>
        ID：
        {deleteIds &&
          deleteIds.map((id, index) => (index === deleteIds.length - 1 ? id : id + ', '))}
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='inherit' onClick={handleCloseDeleteForm}>
          キャンセル
        </Button>
        {isDeleteTables ? (
          <LoadingButton
            loading={loadinDelete}
            variant='contained'
            onClick={() => deleteTables()}
            autoFocus
          >
            削除
          </LoadingButton>
        ) : (
          <LoadingButton variant='contained' onClick={() => deleteRows(table, deleteIds)} autoFocus>
            削除
          </LoadingButton>
        )}
      </DialogActions>
      <Loading isLoading={addLoadingForm || isFetchLoading} />
    </Dialog>
  );
};

DeleteForm.propTypes = {
  table: PropTypes.string,
  openDeleteForm: PropTypes.bool,
  handleCloseDeleteForm: PropTypes.func,
  deleteRows: PropTypes.func,
  deleteTables: PropTypes.func,
  addLoadingForm: PropTypes.bool,
  isFetchLoading: PropTypes.bool,
  deleteIds: PropTypes.array,
  deleteTableList: PropTypes.array,
  isDeleteTables: PropTypes.bool,
  loadinDelete: PropTypes.bool,
};
