import React, { useState, useEffect } from 'react';

/**
 * 出荷伝票を出力するカスタムフック
 */
export const useShipSlipOutput = (selectedRowIds, newRows) => {
  const [openShipSlipOutModal, setOpenShipSlipOutModal] = useState(false);
  const [pdfDataList, setPdfDataList] = useState([]);

  /**
   * 出荷伝票をクリック
   */
  const handleClickShipSlipOutput = () => {
    let sPdfData = [];
    // pdfDataList[0]が配列の場合は更新しなくてOK
    if (!Array.isArray(pdfDataList[0])) {
      sPdfData = arrayChunk(pdfDataList, 10);
      setPdfDataList(sPdfData);
    }
    setOpenShipSlipOutModal(true);
  };

  const arrayChunk = ([...array], size = 1) => {
    return array.reduce(
      (acc, value, index) => (index % size ? acc : [...acc, array.slice(index, index + size)]),
      []
    );
  };

  // 出荷伝票出力用のPDFデータを作成
  useEffect(() => {
    const list = [];
    for (let i = 0; i < selectedRowIds.length; i++) {
      const copyNewValueIdx = newRows.findIndex((d) => d.id === selectedRowIds[i]); // 該当データのindexを取得
      if (copyNewValueIdx >= 0) {
        list.push(newRows[copyNewValueIdx]);
      }
    }
    setPdfDataList(list);
  }, [selectedRowIds, newRows]);

  return {
    openShipSlipOutModal,
    setOpenShipSlipOutModal,
    pdfDataList,
    setPdfDataList,
    handleClickShipSlipOutput,
  };
};
