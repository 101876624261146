import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';

/**
 * 桁数を返す関数
 * @param {Number} num
 * @returns 桁数を返す
 */
const numDigits = (num) => {
  /*一旦数値を文字列に変換し、長さを求める*/
  return num.toString().length;
};

/**
 * ログイン認証後のユーザー情報を返す
 */
export const useCurrentUser = () => {
  const url = new URL(window.location.href);

  const { accounts } = useMsal();

  // GroupIdを取得
  let groupId = accounts[0] && accounts[0].idTokenClaims.jobTitle;

  // // 農場管理者画面へアクセスできるか
  // let isAdminEnvironment = false;

  // // 開発者画面へアクセスできるか
  // let isDevEnvironment = false;

  // 作業者用アカウントかどうか(QRの画面など見る専用)
  let isWorkerEnvironment = false;
  let isQrEnvironment = false;

  if ((groupId === undefined) | (groupId === '2')) {
    isWorkerEnvironment = true;
  }

  if (url.search.split('&')[1] !== undefined && !url.search.split('&')[1].indexOf('film_sub_orders_master_id')) {
    isQrEnvironment = true;
  }

  // groupIdが90000なら開発者アカウント
  // if (groupId !== undefined && groupId === '90000') {
  //   isDevEnvironment = true; // アクセス許可
  // } else if (
  //   // groupIdの桁数が5桁で先頭の数字が1なら農場管理者画面も閲覧可
  //   groupId !== undefined &&
  //   accounts[0] &&
  //   numDigits(groupId) === 5 &&
  //   groupId.slice(0, 1) === '1'
  // ) {
  //   isAdminEnvironment = true; // アクセス許可
  //   groupId = groupId - 10000; // 10000を引く
  // }

  // 文字列に変換
  groupId = String(groupId);

  return { groupId, isWorkerEnvironment, isQrEnvironment };
};
